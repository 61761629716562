import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'app/constants';
import * as Styled from 'shared/ui/course-card/ProfileCourseCard/styles';
import mobileBg from 'assets/images/courses-bg--mobile.png';
import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';

interface IProps {
  isLoading?: boolean;
}

export default function ProfileCoursesPlaceholder({ isLoading }: IProps) {
  const navigate = useNavigate();

  function handleCoursesNav() {
    navigate(ROUTES.MAIN);
  }

  return (
    <Styled.Container
      className={clsx(`profile-course bright--hover`)}
      width={'344px'}
      onClick={handleCoursesNav}
      style={{ backgroundColor: 'rgba(98, 126, 234, 0.10)' }}
      $isLoading={isLoading}
    >
      {isLoading ? (
        <MightyLoaderMini />
      ) : (
        <>
          <Styled.ImageContainer>
            <img src={mobileBg} alt={'placeholder'} />
          </Styled.ImageContainer>
          <Styled.Body className="placeholder">
            <Styled.CourseName className="placeholder" fontSize={'22px'} lineHeight={'114%'}>
              No Courses Started
            </Styled.CourseName>
            <Styled.ChooseFromLibrary>
              Choose from Library <Arrow />
            </Styled.ChooseFromLibrary>
          </Styled.Body>
        </>
      )}
    </Styled.Container>
    // <div className={clsx(styles.container, styles[theme])} role="button" onClick={handleCoursesNav}>
    //   <LazyImage className={styles.image} src={CoursesBG} alt="Courses" />
    //   <div className={styles.body}>
    //     <p className={styles.header}>No courses yet :(</p>
    //     <p className={styles.text}>You haven't started any course yet, click to get started!</p>
    //     <div className={styles.link}>
    //       <p className={styles.choose}>Choose from library</p>
    //       <BsArrowRight className={styles.arrow} />
    //     </div>
    //   </div>
    // </div>
  );
}
