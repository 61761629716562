import { useState, memo, useEffect } from 'react';
import { IShopItem } from 'interface';
import { shallowEqual, useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import { useGetShopItem } from '../hooks';
import { IItemAction } from '../components/ItemActions/data';
import { ActivateCode, TransferItem, ActivateItem } from '../features';
import { THEMES } from 'app/constants';
import clsx from 'clsx';

import { ReactComponent as Background } from 'assets/backgrounds/mobile-modal-bg.svg';

import { Info } from './info';

import styles from './styles.module.scss';
import { getItemEffectType } from './data';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';

interface IProps {
  handleBuy: (goodsId: number, id: number, amount: number) => Promise<void> | any;
  handleCloseSidePanel: () => void;
  isLoading: boolean;
  itemId: number;
  type: 'item' | 'goods';
  itemProvided: IShopItem | null;
}

const ShopItemInfo = ({ handleBuy, isLoading, itemId, type, itemProvided, handleCloseSidePanel }: IProps) => {
  const theme = useSelector(getCurrentTheme, shallowEqual);
  const [selectedAction, setSelectedAction] = useState<IItemAction>();
  // TODO не всякий итем есть в шопе, но при это есть у юзера
  const { item: currentItem, isLoading: isItemLoading } = useGetShopItem(itemId, type);

  const handleClose = () => setSelectedAction(undefined);

  useEffect(() => {
    if (selectedAction?.type === 'chest') {
      handleClose();
      handleCloseSidePanel();
    }
  }, [selectedAction]);

  if (isItemLoading && !itemProvided) {
    return (
      <div className={`${styles.container} ${styles.loading}`}>
        {/* <LogoLoader className={styles.centered} isLabel /> */}
        <MightyLoaderMini className={styles.loader} />
      </div>
    );
  }

  if (itemProvided && !currentItem) {
    const itemEffectType = getItemEffectType(itemProvided.item);
    const color = `var(--grade-${theme}-color-${itemProvided.item.grade})`;

    return (
      <div
        style={{ borderColor: theme === THEMES.LIGHT ? `rgba(${color}, 0.25)` : undefined }}
        className={clsx(styles.container, theme, styles[itemProvided.item.grade])}
      >
        {selectedAction?.name === 'activateCode' ? (
          <ActivateCode item={itemProvided} handleClose={handleClose} />
        ) : selectedAction?.name === 'transferItem' ? (
          <TransferItem item={itemProvided.item} handleClose={handleClose} />
        ) : selectedAction?.name === 'activateItem' ? (
          <ActivateItem item={itemProvided.item} handleClose={handleClose} type={itemEffectType} />
        ) : (
          <Info
            item={itemProvided}
            isLoading={isLoading || isItemLoading}
            handleBuy={handleBuy}
            setSelectedAction={setSelectedAction}
            selectedAction={selectedAction}
          />
        )}
        <Background className={styles.background} style={{ color }} />
      </div>
    );
  }

  if (!currentItem) {
    return null;
  }

  const itemEffectType = getItemEffectType(currentItem.item);
  const color = `var(--grade-${theme}-color-${currentItem.item.grade})`;

  return (
    <div
      style={{ borderColor: theme === THEMES.LIGHT ? color : undefined }}
      className={clsx(styles.container, styles[theme], styles[currentItem.item.grade])}
    >
      {selectedAction?.name === 'activateCode' ? (
        <ActivateCode item={currentItem} handleClose={handleClose} />
      ) : selectedAction?.name === 'transferItem' ? (
        <TransferItem item={currentItem.item} handleClose={handleClose} />
      ) : selectedAction?.name === 'activateItem' ? (
        <ActivateItem item={currentItem.item} handleClose={handleClose} type={itemEffectType} />
      ) : (
        <Info item={currentItem} isLoading={isLoading} handleBuy={handleBuy} setSelectedAction={setSelectedAction} selectedAction={selectedAction} />
      )}
      <Background className={styles.background} style={{ color }} />
    </div>
  );
};

export default memo(ShopItemInfo);
