import { THEMES } from 'app/constants';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const defaultCard = `display: flex;
                    flex-direction: column;`;

const gridLarge = `
                   display: grid; 
                   align-items: center;
                   grid-template-columns: 200px auto;
                   grid-template-areas: "image      name-box"
                                        "image      description"
                                        "image      bottom";
                                        max-width: 722px;
                                        min-width: 722px;`;

const bgs = {
  [THEMES.LIGHT]: '#11111133',
  [THEMES.DARK]: '#FFFFFF10',
};

export const Container = styled(motion.div)<{ $large?: boolean; color?: string; $forcedMobile?: boolean; $theme: THEMES }>`
  gap: 16px;
  ${(p) => (p.$large ? gridLarge : defaultCard)}
  padding: 22px;
  border-radius: 10px;
  background: ${(p) => p.color || bgs[p.$theme]};
  cursor: pointer;
  transition: all 0.3s;
  ${(p) => p.$forcedMobile && 'align-items:center;'}
  &:hover {
    /* opacity: 0.9; */
    ${(p) => !p.$large && 'transform: scale(1.01);'}
    filter: brightness(1.05);
  }
  overflow: hidden;
  @media screen and (max-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 22px;
    align-items: center;
    max-width: 100%;
    min-width: auto;
    gap: 12px;
  }
`;

export const TopBox = styled.div`
  display: flex;
  gap: 17px;
  align-items: center;
  width: 100%;
  grid-area: topbox;
`;

export const ImageBox = styled.div<{ color?: string; $large?: boolean }>`
  ${(p) => (p.$large ? 'width: 200px; height: 200px; min-width: 200px;' : 'width: 76px; min-width: 76px; height: 76px;')}
  background: ${(p) => p.color || '#6487EF1A'};
  border-radius: 50%;
  grid-area: image;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  & img {
    max-height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 992px) {
    width: 102px;
    height: 102px;
  }
`;

export const NameBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  grid-area: name-box;
  max-width: 100%;
  @media screen and (max-width: 992px) {
    align-items: center;
  }
`;

export const Name = styled.p<{ $textColor: string; $large?: boolean; forcedMobile?: boolean }>`
  color: ${(p) => p.$textColor};
  font-size: 24px;
  line-height: 110%;
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  gap: 5px;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  ${(p) => p.forcedMobile && 'justify-content: center;'}
  /* flex-wrap: wrap; */
  & > span {
    color: inherit;
    white-space: nowrap;
    overflow: hidden;
    line-height: 110%;
    text-overflow: ellipsis;
    max-width: ${(p) => (p.forcedMobile ? '100%' : '180px')};
  }
  @media screen and (max-width: 992px) {
    justify-content: center;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    & > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      text-align: center;
    }
    margin-bottom: 44px;
  }
`;
export const Description = styled.p<{ $textColor: string; $large?: boolean }>`
  font-size: 16px;
  font-weight: 400;
  line-height: 21.44px;
  grid-area: description;
  color: ${(p) => p.$textColor};
`;

export const Tag = styled.div<{ $theme: THEMES }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  padding: 0px 10px;
  font-size: 16px;
  font-weight: 500px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 34px 21px 16px rgba(48, 49, 61, 0.01), 9px 5px 10px rgba(48, 49, 61, 0.01);
  border-radius: 4px;
  max-width: 150px;
  & > span {
    text-transform: uppercase;
    white-space: nowrap;
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 100%;
  }
  @media screen and (max-width: 992px) {
    & > span {
      max-width: 60px;
    }
  }
`;
export const TagList = styled.div<{ forcedMobile?: boolean; tagsColor: string }>`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  ${(p) => p.forcedMobile && 'justify-content: center;'}
  @media screen and (max-width: 992px) {
    justify-content: center;
    width: 100%;
  }
  & > ${Tag} > span {
    color: ${(p) => p.tagsColor};
  }
`;
export const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  grid-area: bottom;
  margin-top: auto;
`;

export const FollowButton = styled.button<{ active?: boolean }>`
  ${(p) =>
    p.active
      ? 'background: linear-gradient(90deg, #6487EF 0%, #53259B 125.56%), linear-gradient(281.94deg, rgba(255, 255, 255, 0.21) -0.2%, rgba(255, 255, 255, 0.03) 98.72%);'
      : 'background: linear-gradient(281.94deg, rgba(255, 255, 255, 0.21) -0.2%, rgba(255, 255, 255, 0.03) 98.72%);'}
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 20px;
  color: white;
  height: 52px;
  border-radius: 300px;
  padding: 0px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Followers = styled.div<{ $color: string }>`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  font-weight: 400;
  color: ${(p) => p.$color};
  & path {
    stroke: ${(p) => p.$color};
  }
`;

export const Rewards = styled.div`
  display: flex;
  align-items: center;
  height: 51px;
`;

export const RewardIcon = styled.img`
  width: 51px;
  height: 51px;
  min-height: 51px;
  min-width: 51px;
  margin-right: -25px;
  object-fit: contain;
`;
