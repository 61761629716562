import { useEffect } from 'react';
import { dispatch } from 'hooks';
import { getIsInit, setPageIsLoaded } from 'store';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'app/constants';
import { useSelector } from 'react-redux';

interface IUseGlobalPageAnimation {
  dependency?: boolean[];
  isUserPage?: boolean;
}

export const useGlobalPageAnimation = ({ dependency, isUserPage }: IUseGlobalPageAnimation) => {
  const { pathname } = useLocation();
  const isInit = useSelector(getIsInit);

  useEffect(() => {
    if (isInit) {
      const reCourseEdit = new RegExp(`^${ROUTES.COURSES}/edit/(course|lessons|chapter)/.*`);

      if (reCourseEdit.test(pathname)) {
        dispatch(setPageIsLoaded(true));
      }

      if (pathname === '/' && isUserPage && dependency && dependency.every((val) => !val)) {
        dispatch(setPageIsLoaded(true));
      }

      if (pathname !== '/' && dependency && dependency.every((val) => !val)) {
        dispatch(setPageIsLoaded(true));
      }
    }
  }, [pathname, dependency, isInit]);
};
