import { LogIn } from './logIn';
import { ILogInForm } from 'interface';
import { useLoginMutation, useLazyGetUserDataQuery } from 'store';
import { toast } from 'react-toastify';
import Answer from 'components/library/messages/Answer';
import { useGlobalPageAnimation } from 'shared/utils/GlobalPageAnimation/useGlobalPageAnimation';
import { LOCAL_STORAGE_KEYS, RESIGNUP_MESSAGE, ROUTES } from 'app/constants';
import { useLocation, useNavigate } from 'react-router-dom';

export const LogInPage = () => {
  const [login, { isLoading, error }] = useLoginMutation();
  const [getUser, { isFetching }] = useLazyGetUserDataQuery();
  const { state } = useLocation();
  const navigate = useNavigate();

  const onSubmitHandler = async (data: ILogInForm) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.SIGNUP_TEMP, JSON.stringify(data));

    login(data)
      .unwrap()
      .then((res) => {
        if (res.user && !res.user.isActivated) {
          navigate(ROUTES.ACTIVATION_CODE);
        } else {
          getUser().then(() => {
            localStorage.removeItem(LOCAL_STORAGE_KEYS.SIGNUP_TEMP);
            if (state?.prev) {
              navigate(state?.prev, { replace: true });
            }
          });
        }
      })
      .catch((ex) => {
        console.error(ex);
        if (ex.data.message === RESIGNUP_MESSAGE) {
          navigate(ROUTES.ACTIVATION_CODE);
        }
      });
  };

  const openWalletModal = () => {
    toast.error(<Answer label="Wallet Connect not available in closed beta - please use email to sign-up" type="incorrect" />);
  };

  useGlobalPageAnimation({ dependency: [false] });

  return (
    <LogIn
      //TODO change error type
      error={(error as any)?.data?.message}
      isLoading={isLoading || isFetching}
      onSubmitHandler={onSubmitHandler}
      openWalletModal={openWalletModal}
    />
  );
};
