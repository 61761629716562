import { useDisplay } from 'hooks';
import { useSelector } from 'react-redux';
import { getCurrentTheme, getUserData } from 'store';

import * as S from './styles';
import { Modal } from 'shared/ui';
import { IOnetimerComponent } from 'shared/ui/onetime-notifications/types';
import ChestImageAnimated from '../screen/ChestAnimated';

import modalStyles from './modal.module.scss';

import chestImg from '../assets/chest-image-small.png';
import tokenSvg from '../assets/usdc.svg';
import { useMemo } from 'react';
import { USDC_BY_REFS } from '../screen/progress/constants';
import BasicButton from 'shared/ui/basic-button/button';
import { useNavigate } from 'react-router-dom';
import { BackButton } from 'components/library/BackButton';

type RefsProps = {
  handleClose: () => void;
};

const ReferralsModalBox = ({ handleClose }: RefsProps) => {
  const {
    referrals: { referralBonus },
  } = useSelector(getUserData);
  const { isMobile } = useDisplay();
  const theme = useSelector(getCurrentTheme);
  const navigate = useNavigate();

  const usdcAmount = useMemo(() => USDC_BY_REFS.reduce((acc, el) => (acc += referralBonus >= el.refs ? el.usdc : 0), 0), [referralBonus]);
  const handleNavigate = () => {
    navigate('/referrals');
    handleClose();
  };

  return (
    <S.Container>
      <ChestImageAnimated size={isMobile ? 265 : 400} containerClassName="chest" smallerGlow />
      <S.Title $theme={theme}>
        You invited {referralBonus} <br /> new friends!
      </S.Title>
      <S.RewsBox $theme={theme}>
        <img src={chestImg} /> <span>{referralBonus} chests</span> <span>+</span> <img src={tokenSvg} alt="token" /> <span>{usdcAmount} USDC</span>
      </S.RewsBox>
      <BasicButton label={'View progress'} fullWidth={false} variant="cool" style={{ height: 66 }} onClick={handleNavigate} />
    </S.Container>
  );
};

interface Props extends IOnetimerComponent {
  isOpen?: boolean;
}

const ReferralsModal = ({ onClose, isOpen = true }: Props) => {
  const { isMobile } = useDisplay();
  const theme = useSelector(getCurrentTheme);
  return (
    <Modal
      closeButtonLocation="outside"
      closeButtonVariant={isMobile ? 'none' : 'filled'}
      className={`${modalStyles.modal} ${modalStyles[theme]}`}
      containerClassName={modalStyles.container}
      containerChildren={isMobile ? <BackButton text="" className={modalStyles.back} /> : <></>}
      open={isOpen}
      onClose={onClose}
    >
      <ReferralsModalBox handleClose={onClose} />
    </Modal>
  );
};

export default ReferralsModal;
