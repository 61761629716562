import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import clsx from 'clsx';
import { THEMES } from 'app/constants';
import { IUserItem } from 'interface';

import InventoryItem from 'components/library/cards/inventory/InventoryItem';

import styles from './styles.module.css';

interface IProps {
  inventory: IUserItem[];
  className?: string;
}

export default function Inventory({ inventory, className = '' }: IProps) {
  const theme = useSelector(getCurrentTheme);
  const [moreItems, setMoreItems] = useState(false);
  const [items, setItems] = useState<IUserItem[]>([]);
  const isSeeAll = items.length > 7;

  useEffect(() => {
    const userItems = [...inventory].filter((i) => i.quantity > 0).sort((a, b) => a.id - b.id);
    setItems(moreItems ? userItems : userItems.slice(0, 8));
  }, [inventory, moreItems]);

  return (
    <div className={clsx('mobile-padding', styles.container, { [className]: !!className })}>
      {isSeeAll && (
        <div className={'see-all-label'} onClick={() => setMoreItems(!moreItems)}>
          {moreItems ? 'Hide' : 'See All'}
        </div>
      )}
      <div className={clsx(styles.items, { [styles.open]: moreItems })}>
        {items.map(({ quantity, createdAt, item: { id, type } }) => {
          if (type === 'digital' || type === 'non-fungible') {
            return [...Array(quantity).keys()].map((key) => (
              <InventoryItem isBordered={theme === THEMES.LIGHT} isMovableTooltip itemId={id} amount={1} key={createdAt.toString() + key} />
            ));
          }

          return <InventoryItem isBordered={theme === THEMES.LIGHT} isMovableTooltip itemId={id} amount={quantity} key={createdAt.toString() + id} />;
        })}
        {isSeeAll && (
          <div className={`'see-all-label' ${styles.seeAllLabel}`} onClick={() => setMoreItems(!moreItems)}>
            {moreItems ? 'Hide' : 'See all'}
          </div>
        )}
      </div>
    </div>
  );
}
