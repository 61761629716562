import styled, { css } from 'styled-components';

export const Container = styled.div(
  () => css`
    --row-gap: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--row-gap) 9px;
    padding-left: var(--padding-mobile);
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }
  `
);
