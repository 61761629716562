import { THEMES } from 'app/constants';
import styled from 'styled-components';

const dotsColors = {
  [THEMES.DARK]: {
    active: '#FFFFFF',
    default: 'rgba(255,255,255,0.1)',
  },
  [THEMES.LIGHT]: {
    active: '#1C1D26',
    default: '#1C1D2640',
  },
};

export const Dot = styled.div<{ active?: boolean; $theme: THEMES; transformY?: number }>`
  width: 8px;
  height: 8px;
  background: ${(p) => (p.active ? dotsColors[p.$theme].active : dotsColors[p.$theme].default)};
  border-radius: 50%;
  transition: 0.3s;
  ${(p) => p.transformY && `transform: translateY(${p.transformY}px);`}
`;
