import * as Styled from './styles';
import { SearchResultCard } from 'shared/ui/course-card';
import { ICourse } from 'interface/courses';

type Props = {
  className?: string;
  limit?: number;
  courses: ICourse[];
  onClick(_id: string): () => void;
};

const Popular = ({ className, limit = 4, courses, onClick }: Props) => {
  return (
    <Styled.Container className={className}>
      {courses.slice(0, limit).map((course) => (
        <SearchResultCard key={course._id} image={course.imageCover} name={course.name} onClick={onClick(course._id)} />
      ))}
    </Styled.Container>
  );
};

export default Popular;
