import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserData } from 'store';
import { InventoryList } from './InventoryList/InventoryList';
import styles from './styles.module.css';
import { InventoryInfoModal } from './InventoryInfoModal/InventoryInfoModal';
import { CoinsQuantity } from './CoinsQuantity/CoinsQuantity';
import AvatarPic from 'components/library/utils/AvatarPic/avatar';
import clsx from 'clsx';

interface IInventoryProps {
  variant?: 'default' | 'mobile';
}

const options = [
  { label: 'All', value: 'all' },
  { label: 'Mythical', value: 'mythical' },
  { label: 'Legendary', value: 'legendary' },
  { label: 'Epic', value: 'epic' },
  { label: 'Rare', value: 'rare' },
  { label: 'Uncommon', value: 'uncommon' },
  { label: 'Common', value: 'common' },
];

const Inventory = ({ variant = 'default' }: IInventoryProps) => {
  const { expTokenBalance, avatar, nickname, customization } = useSelector(getUserData);

  const [value, setValue] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);

  const onChangeHandler = (value: string) => {
    setValue(value);
  };

  const openModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className={clsx('profile-main', styles[variant])}>
        {variant === 'default' && (
          <div className={styles.profileRow}>
            <div className={styles.info}>
              <AvatarPic className={clsx(styles.avatar, 'menu-avatar')} avatar={avatar} customization={customization} nickname={nickname} />
              <div className={styles.profileCol}>
                <div className="profile-name screen-header">Inventory</div>
                <div className="profile-follow"></div>
              </div>
            </div>
          </div>
        )}
        <InventoryList chooseGrade={{ onChangeHandler, options, value }} variant={variant} />
        {variant === 'default' && <CoinsQuantity expToken={expTokenBalance} />}
      </div>
      {isOpen && <InventoryInfoModal isOpen={isOpen} onCloseModalHandler={openModal} />}
    </>
  );
};

export default Inventory;
