import React, { useEffect, useReducer, useState } from 'react';
import { useDisplay, useGetCourseGroups, useGetIsAuth, useGetUserData } from 'hooks';
import useCoursesBgChanger from './structures/utils/useCoursesBgChanger';
import { ICourseFilteringGroup } from './hooks';
import clsx from 'clsx';
import { EGroupTag } from 'app/constants';

import SearchCourses from '../../../library/search/search';
import GroupCards from './group-cards/group-cards';
import { CourseBanner } from './courseBanner/CourseBanner';
import MobileSearchbar from './mobile-searchbar';
import { MultipurposeBanner } from 'shared/ui';
import LeftOffLesson from './left-off-lesson/left-off-lesson';
import { DailyRewards } from '../../profile/profile/dailyRewards';
import { SlowSlideX } from 'shared/ui/motion';
import { SlideLessonLeftOff } from '../../../shared/slides';
import { CoursesGroup } from './group';
import UniversalSlider from 'components/library/universal-slider';

import styles from './styles.module.scss';
import { CoursesContext } from './context';
import { useGetDefaultCourseGroups, useSaveSelectedCourseGroups } from 'hooks/useGetDefaultCourseGroups';
import ReferralsBanner from 'components/pages/referrals/banner/ReferralsBanner';
import { ICourse } from 'interface/courses';

const desktopSlides = [
  // { component: Premium, name: 'Premium', props: { isBannerOnly: true } },
  { component: ReferralsBanner, name: 'Referrals', props: { isForcingHorizontal: true } },
  {
    component: LeftOffLesson,
    name: 'LeftOffLesson',
    props: { isBannerOnly: true },
  },
  { component: DailyRewards, name: 'DailyRewards', props: { isBannerOnly: true } },
];

const slides = (() => {
  const slides = [<DailyRewards />, <SlideLessonLeftOff />, <ReferralsBanner />];
  // if (!isPremium) {
  //   slides.push(<SlidePremium />);
  // }
  return slides;
})();

interface State {
  loading: boolean;
  displayedCourses: ICourse[];
  groups: ICourseFilteringGroup[];
  selectedCourse: ICourse | Partial<ICourse>;
  isStarted: boolean;
  isCourseOpen: boolean;
}

const defaultState: State = {
  loading: false,
  displayedCourses: [],
  groups: [],
  selectedCourse: {
    _id: '0',
  },
  isStarted: false,
  isCourseOpen: false,
};

const CoursesPage = () => {
  const { isMobile } = useDisplay();
  const { resultCourses = [], favouriteCourses = [] } = useGetUserData(true);
  const isAuth = useGetIsAuth();
  const { favouritesKey, tags, groupsNativeNames } = useGetCourseGroups();
  useCoursesBgChanger();
  const [courses, setCourses] = useState<ICourse[]>([]);

  const defaultGroups = useGetDefaultCourseGroups();

  const [state, updateState] = useReducer((prev: State, next: Partial<State>) => ({ ...prev, ...next }), {
    ...defaultState,
    groups: defaultGroups,
  });

  const { displayedCourses, groups, selectedCourse, isCourseOpen } = state;

  useSaveSelectedCourseGroups(groups);

  useEffect(() => {
    updateState({ displayedCourses: courses });
  }, [courses]);

  const searchForCourses = (e: React.ChangeEvent<HTMLInputElement> | null) => {
    if (!e) {
      updateState({ displayedCourses: courses, groups: defaultGroups });
      return;
    }
    const string = e.target.value.toLowerCase();
    const newCourses = courses.filter(
      ({ name, description, estimatedTime, difficulty }) =>
        name.toLowerCase().includes(string) ||
        description
          .map((i) => i.title)
          .join('')
          .toLowerCase()
          .includes(string) ||
        estimatedTime.toString().includes(string) ||
        difficulty.toLowerCase().includes(string)
    );
    updateState({ displayedCourses: newCourses });
  };

  const handleGroups = (newGroupNative: string) => {
    if (groups.map((r) => r.native).includes(newGroupNative)) {
      updateState({ groups: groups.filter((group) => group.native !== newGroupNative) });
    } else {
      const newGroupFullName = tags[newGroupNative];
      updateState({ groups: [...groups, newGroupFullName] });
    }
  };

  const handleSetSelectedCourse = (courseId?: string) => () => {
    if (courseId) {
      if (selectedCourse?._id === courseId && isCourseOpen) {
        updateState({ isCourseOpen: false, isStarted: false });
        return;
      }
      const isStarted = !!resultCourses && resultCourses.some((rc) => rc.courseId === courseId);
      const course = courses.find((course) => course._id === courseId);

      if (course) {
        updateState({ selectedCourse: course, isCourseOpen: true, isStarted });
      }
      return;
    }
    updateState({ isCourseOpen: false });
  };

  const handleSetCourses = (newCourses: typeof courses) => {
    setCourses((prev) => {
      const uniqueCourses = newCourses.filter((newCourse) => !prev.some((course) => course._id === newCourse._id));
      return [...prev, ...uniqueCourses];
    });
  };

  return (
    <div className={clsx(styles.container, { mobile: isMobile })}>
      {isMobile ? (
        <>
          <CoursesContext.Provider
            value={{
              courses,
              displayedCourses,
              activeGroups: groups,
              handleGroups,
              selectCourse: handleSetSelectedCourse,
              searchForCourses,
            }}
          >
            <MobileSearchbar className={styles.mobileSearchbar} />
          </CoursesContext.Provider>
          {isAuth && (
            <UniversalSlider
              containerClassName={styles.banner}
              containerDivProps={{ style: { paddingTop: '10px' } }}
              slides={slides}
              slideHeight={200}
              dots
              spaced
              dotsOffsetY={-9}
              variableWitdh
              slideSizeOfSlider
              slidesToShow={1}
              slidesToScroll={1}
              listOverflow="visible"
            />
          )}
        </>
      ) : (
        <SlowSlideX>
          <h2 className={styles.header}>Courses</h2>
          {isAuth && <MultipurposeBanner className={styles.banner} slides={desktopSlides} isBannerOnly withAnimation={false} />}
          <div className={styles.groupCardsWrapper}>
            <h3 className={styles.groupCardsHeader}>categories</h3>
            <GroupCards className={styles.groupCards} activeGroups={groups} handleGroups={handleGroups} />
          </div>
          <SearchCourses className={styles.search} search={searchForCourses} />
        </SlowSlideX>
      )}

      {groupsNativeNames
        .map((i) => tags[i])
        .map((group) => (
          <CoursesGroup
            handleToggle={handleGroups}
            isEditMode={false}
            selectCourse={handleSetSelectedCourse}
            handleLoadCourses={handleSetCourses}
            key={group.native}
            group={group}
            isOpen={groups.map((r) => r.native).includes(group.native)}
            courses={displayedCourses.filter((course) =>
              group.native === favouritesKey ? favouriteCourses.includes(course._id) : course.groups.includes(group.native as EGroupTag)
            )}
          />
        ))}

      {isMobile || !state.isStarted ? (
        <CourseBanner
          key={selectedCourse?._id ?? 'CourseBannerAtCourses'}
          open={isCourseOpen}
          course={selectedCourse as ICourse}
          setSelectedCourse={handleSetSelectedCourse()}
        />
      ) : (
        <MultipurposeBanner
          isForcedHidden={!isCourseOpen}
          key={selectedCourse?._id ?? 'mpbanner'}
          isPanelOnly
          onClose={handleSetSelectedCourse()}
          slides={[
            {
              component: LeftOffLesson,
              name: `LeftOffLesson_profileCourse_${selectedCourse?._id}`,
              props: { horizontalClassName: 'leftOffBanner', givenCourseId: selectedCourse?._id, isPanelOnly: true },
            },
          ]}
        />
      )}
    </div>
  );
};

export default CoursesPage;
