import styled from 'styled-components';

export const NavWrapper = styled.div`
    position: relative;
    top: 0;

    height: 72px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 28px;

    padding: 0 32px;

    margin-bottom: 64px;

    z-index: 100;

    grid-area: 1 / 1 / 2 / 3;

    @media screen and (max-width: 992px) {
      display: none;
    }
  `,
  SearchbarWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    max-width: 858px;
    height: 100%;

    position: relative;

    z-index: 9;

    & > svg {
      fill: var(--transbg-color);
      stroke: ${({ theme }) => theme.theme.border};
      position: relative;
      top: -2px;
      min-height: 100%;
    }
  `,
  Nav = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;

    border-radius: 0;

    height: 72px;
    width: 100%;

    border-bottom: ${({ theme }) => 'solid 1px ' + theme.theme.border};
    border-radius: 0;
    @media (max-width: 992px) {
      justify-content: space-between;
    }
  `,
  NavIconWrapper = styled.div`
    height: 43px;
    min-width: 43px;
    width: fit-content;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 10px;
    margin: 0 10px;

    border-radius: 25px;

    position: relative;

    background: ${({ theme }) => theme.theme.inputBg};

    & > svg {
      max-height: 100%;
      height: 100%;
      max-width: 25px;
      width: 100%;
    }

    &.active {
      filter: brightness(1.8);
    }
  `;
