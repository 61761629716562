import { ForwardedRef, forwardRef, RefObject, useMemo, useRef, useState } from 'react';
import { useGroupImage } from '../../group-cards/useGroupImage';
import { ICourseFilteringGroup } from '../../hooks';
import { useAverageColor, useObserver } from 'hooks';
import * as S from './styles';
import clsx from 'clsx';
import { lightenColor } from 'components/pages/spaces/ui/utils';
import { RGBAToHexA } from 'components/pages/spaces/ui/SpaceQuest/hooks';

type Props = {
  className?: string;
  size?: number;
  animate?: boolean;
  group: ICourseFilteringGroup;
  delay?: number;
};

export const GroupImage = forwardRef(({ className, size = 41, animate = true, group, delay }: Props, ref: ForwardedRef<HTMLImageElement>) => {
  const image = useGroupImage(group.native);
  const imageRef = useRef<HTMLImageElement>(null);

  const currentRef = ref ?? imageRef;

  const avgColor = useAverageColor(currentRef as RefObject<HTMLImageElement>);
  const [isAnimated, setAnimated] = useState(false);

  const animationHandler = (isVisible: boolean) => {
    if (isVisible && animate) {
      setTimeout(() => setAnimated(true), 150);
    }
  };

  const { observerRef } = useObserver({ handler: animationHandler, threshold: 1 });

  const color = useMemo(() => lightenColor(RGBAToHexA(avgColor, true), -10), [avgColor]);

  return (
    <S.Container ref={observerRef} $size={size} className={clsx('group-img', { className })}>
      <S.Background $color={color} />

      <S.Image
        animate={!animate ? {} : isAnimated ? { rotate: 0, scale: 1 } : { rotate: -12, scale: 0.9 }}
        transition={{ delay, duration: 0.3 }}
        ref={currentRef}
        src={image}
      />
    </S.Container>
  );
});
