import { useState } from 'react';
import clsx from 'clsx';
import { useGetSettings } from 'hooks';

import { Help } from './components/Help/Help';
import { useClientDetection } from '../../utils/useClientDetection';
import { Logo } from 'shared/utils';

import { ReactComponent as Background } from './assets/background.svg';
import { ReactComponent as IconGradient } from './assets/mighty-icon--gradient.svg';
import { ReactComponent as Share } from './assets/icons/share.svg';
import { ReactComponent as Plus } from './assets/icons/plus.svg';
import { ReactComponent as Dots } from './assets/icons/dots.svg';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';

import * as Styled from './styled';

type Props = {
  setCooldown: (arg: string) => void;
};

export const DownloadApp = ({ setCooldown }: Props) => {
  const { displayedName } = useGetSettings();
  const [show, setShow] = useState(false);
  const { os, browser } = useClientDetection();
  const isIOS = os === 'iOS';
  const isDetected = isIOS ? browser === 'Safari' : browser === 'Brave' || browser === 'Chrome';
  const handleSetCooldownAndClose = () => {
    if (!!setCooldown) {
      setCooldown(JSON.stringify(Date.now() + 1000 * 60 * 60 * 24 * 14)); // 2 weeks
    }
  };

  return (
    <>
      <Styled.Container>
        <Styled.ExitButton onClick={handleSetCooldownAndClose}>
          <Cross />
        </Styled.ExitButton>
        <Styled.Wrap>
          <Styled.Content $isDetected={isDetected}>
            <Styled.MightyIcon>
              <IconGradient />
              <Logo containerClassName={clsx('logo-container')} />
            </Styled.MightyIcon>
            <h2 className={'download-app__title'}>
              {isDetected ? (
                <>
                  Add {displayedName} <br />
                  To Home Screen
                </>
              ) : (
                'Wrong browser detected'
              )}
            </h2>
            <p className={'download-app__text'}>
              {isDetected ? (
                <>
                  Tap {isIOS ? <Share className="share" /> : <Dots />} then select <br /> {isIOS && <Plus className="plus" />}{' '}
                  {isIOS ? '“Add to Home Screen”' : '“Install App”'} <br /> to install app on your device
                </>
              ) : (
                <>
                  Please open mighty.study in <span>{isIOS ? 'Safari' : 'Chrome'}</span> to install this app.
                </>
              )}
            </p>
            {isDetected && <Styled.HelpText onClick={() => setShow(true)}>Help</Styled.HelpText>}
          </Styled.Content>
          <Background className={'download-app__background'} />
        </Styled.Wrap>
      </Styled.Container>
      <Help os={os} show={show} onClose={() => setShow(false)} />
    </>
  );
};
