import { FC, useCallback, useEffect, useRef, useState } from 'react';
import styles from './style.module.scss';
import { GoogleIcon } from 'assets/icons/icons';
import { useLazyGetUserDataQuery, useLoginMFAMutation } from 'store';
import { EAuthMethods } from 'interface';
import { TemplateButton } from 'pages/auth/authButtons/authButtons';
import { Environment } from 'app/constants';
import { useGetMiniAppInitData } from 'shared/utils';

type TGoogleResponse = {
  clientId: string;
  client_id: string;
  credential: string;
  select_by: string;
};

const GoogleLoginButton: FC = () => {
  const { initData } = useGetMiniAppInitData();

  if (initData) {
    return null;
  }

  return <GoogleLoginButtonWrapped />;
};

const GoogleLoginButtonWrapped: FC = () => {
  const [getUser, { isFetching }] = useLazyGetUserDataQuery();
  const [login, { isLoading: isLoadingGoogle }] = useLoginMFAMutation();
  const isLoading = isLoadingGoogle || isFetching;

  const [isSdkReady, setSdkReady] = useState(false);

  const buttonRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleLogin = useCallback(
    async (response: TGoogleResponse) => {
      const token = response.credential;
      const referralCode = localStorage.getItem('referralCode');
      const payload = { type: EAuthMethods.Google, data: { token }, referralCode: referralCode || undefined };

      try {
        await login(payload).unwrap();
        await getUser().unwrap();
      } catch (error) {
        console.error('Login failed:', error);
        // Handle error (e.g., show error message to user)
      }
    },
    [login, getUser]
  );

  const handleCredentialResponse = useCallback(
    (response: TGoogleResponse) => {
      handleLogin(response);
    },
    [handleLogin]
  );

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.google && buttonRef.current) {
        window.google.accounts.id.initialize({
          client_id: Environment.GoogleClientId,
          callback: handleLogin,
        });

        window.google.accounts.id.renderButton(buttonRef.current, {
          type: 'standard',
          theme: 'outline',
          size: 'large',
          text: 'signin_with',
          shape: 'rectangular',
          logo_alignment: 'left',
          width: wrapperRef.current?.offsetWidth,
        });
        setSdkReady(true);
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [handleLogin]);

  useEffect(() => {
    if (isSdkReady && window.google) {
      window.google.accounts.id.initialize({
        client_id: Environment.GoogleClientId,
        callback: handleCredentialResponse,
        prompt: 'select_account',
      });

      window.google.accounts.id.renderButton(buttonRef.current, { theme: 'outline', size: 'xxl', width: 1000, 'max-width': 1000, height: 56 });
    }
  }, [isSdkReady, handleCredentialResponse]);

  return (
    <div className={styles.googleButtonWrapper} ref={wrapperRef}>
      {!isLoading && <div className={styles.googleButton} ref={buttonRef}></div>}
      <TemplateButton title={'Login with Google'} icon={<GoogleIcon />} variant={'google'} isLoading={isLoading} />
    </div>
  );
};

export default GoogleLoginButton;
