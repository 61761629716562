import { MutableRefObject, useMemo } from 'react';
import { NumWidthByRefs, REWARDS_STEPS } from './constants';
import { useDisplay } from 'hooks';

type Props = {
  refs: number;
  blockRef: MutableRefObject<HTMLDivElement | null>;
};

export const useUserPosition = ({ blockRef, refs }: Props) => {
  const { isMobile } = useDisplay();

  const marginBig = 120;
  const marginSmall = isMobile ? 30 : 60;
  const itemWidth = 107;

  const blockWidth = useMemo(() => {
    return blockRef && blockRef.current ? blockRef.current.getBoundingClientRect().width : 0;
  }, [refs, blockRef.current]);

  const { progress, blockOffset, defaultTrackPos } = useMemo(() => {
    if (!refs) {
      return { progress: itemWidth / 2, blockOffset: 0 };
    }

    const prevSteps = REWARDS_STEPS.filter((el) => el <= refs);
    const prevRefs = prevSteps[prevSteps.length - 1] || 1;
    const nextRefs = REWARDS_STEPS.find((el) => el > refs) || 1000;

    const prevNumWidth = NumWidthByRefs[`_${prevRefs}`];
    const nextNumWidth = NumWidthByRefs[`_${nextRefs}`];

    const distanceToNext = (nextRefs || prevRefs) - prevRefs;
    const distanceFromLast = refs - prevRefs;

    const addition = (distanceFromLast / distanceToNext) * (marginBig + itemWidth);

    const smallStep = itemWidth + marginSmall;

    const basicLength = itemWidth / 2 + smallStep * (prevSteps.length - 1);

    let progress = itemWidth / 2 + addition;

    if (refs > 9) {
      progress = basicLength + addition;
    }

    if (refs >= 1000) {
      progress = basicLength;
    }

    let blockOffset = 0;

    const prevNumEnd = progress - addition + prevNumWidth / 2;
    const nextNumStart = progress - addition - nextNumWidth / 2 + itemWidth + marginBig;

    if (!REWARDS_STEPS.includes(refs)) {
      if (progress + blockWidth / 2 > nextNumStart) {
        blockOffset = nextNumStart - blockWidth / 2 - progress;
      }
      if (progress - blockWidth / 2 < prevNumEnd) {
        blockOffset = prevNumEnd + blockWidth / 2 - progress;
      }
    }

    let defaultTrackPos = 0;
    defaultTrackPos = Math.max(progress - itemWidth - marginSmall, 0);
    if (refs >= 500) {
      defaultTrackPos = prevNumEnd - marginBig - itemWidth;
    }

    return { progress, blockOffset, defaultTrackPos };
  }, [refs, isMobile, blockWidth]);

  return { progress, blockOffset, marginBig, marginSmall, defaultTrackPos };
};

export const getRefTooltipText = (refs: number) => {
  if (refs === 0) {
    return 'Invite one referral to pick up a Nature Chest';
  }
  const nextRef = REWARDS_STEPS.find((el) => el > refs) || refs;
  const diff = nextRef - refs;

  const only = diff <= 10 ? 'Only ' : '';
  const multiple = diff > 1 ? 's' : '';

  return only + `${diff} referral${multiple} left until the USDC bonus! Keep up the good work!`;
};
