import { FC, useMemo } from 'react';

import * as Styles from './styles';

type TAvatarProps = {
  size?: number;
  online?: boolean;
  src?: string;
  userName: string;
};

export const Avatar: FC<TAvatarProps> = ({ size = 46, online, src, userName }) => {
  const letters = useMemo(() => {
    if (src) {
      return null;
    }

    let chunks = userName.split(' ');
    if (chunks.length > 2) {
      chunks = chunks.slice(0, 2);
    }

    return chunks.map((item, index) => <span key={index}>{item[0].toUpperCase()}</span>);
  }, [userName, src]);

  const style = useMemo(() => {
    if (!src) {
      return {};
    }

    return {
      background: `url(${src})`,
      backgroundSize: 'cover',
    };
  }, [src]);

  return (
    <Styles.SContainer style={style} size={size}>
      {letters}
    </Styles.SContainer>
  );
};
