import { useEffect } from 'react';
import { useDisplay } from 'hooks';
import mobileBg from 'assets/images/courses-bg--mobile.png';

const useCoursesBgChanger = () => {
  const { isMobile } = useDisplay();

  useEffect(() => {
    const app = document.getElementById('App');

    if (isMobile && app) {
      if (app) {
        app.style.background = 'none';
        app.style.backgroundImage = `linear-gradient(to bottom, rgba(var(--body-color--rgb), 0.0001) 160px, rgba(var(--body-color--rgb), 1) 380px), url(${mobileBg})`;
        app.style.backgroundPosition = `0 0`;
        app.style.backgroundSize = `135% auto`;
        app.style.backgroundRepeat = `no-repeat`;
      }
    }

    return () => {
      if (isMobile && app) {
        app.style.background = '';
      }
    };
  }, [isMobile]);
};

export default useCoursesBgChanger;
