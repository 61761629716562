import { ICourse } from 'interface/courses';

import { SearchResultCardSmall } from 'shared/ui/course-card';

import * as Styled from './styles';
import clsx from 'clsx';
import { useCoursesContext } from '../../../context';

type Props = {
  results: ICourse[];
  className?: string;
};

const ResultsList = ({ results, className = '' }: Props) => {
  const { selectCourse } = useCoursesContext();

  return (
    <Styled.Container className={clsx(className, 'mobile-padding')}>
      {results.map((course) => (
        <Styled.Item>
          <SearchResultCardSmall image={course.imageCover} name={course.name} onClick={selectCourse(course._id)} />
        </Styled.Item>
      ))}
    </Styled.Container>
  );
};

export default ResultsList;
