import * as S from './style';
import { ReactComponent as LinkIcon } from '../../assets/icons/link-icon.svg';
import UserLink from './user-link';
import { LinkModal, UnlinkModal } from './linkModal';
import { useState } from 'react';
import { LINK_AUTH_METHODS } from './types';
import { EAuthMethods } from 'interface';
import { useGetCurrentTheme } from 'hooks';

const UserLinks = () => {
  const theme = useGetCurrentTheme();
  const [selectedLinkMethod, setSelectedLinkMethod] = useState<EAuthMethods | null>(null);
  const [selectedToUnlink, setSelectedToUnlink] = useState<EAuthMethods | null>(null);

  const handleClose = () => {
    setSelectedLinkMethod(null);
    setSelectedToUnlink(null);
  };

  return (
    <S.Container>
      <S.Title $theme={theme}>
        <LinkIcon /> Your Links
      </S.Title>
      <S.List>
        {LINK_AUTH_METHODS.map((el) => (
          <UserLink
            key={el.method}
            selectForLinking={() => setSelectedLinkMethod(el.method)}
            selectForUnlinking={() => setSelectedToUnlink(el.method)}
            {...el}
          />
        ))}
      </S.List>
      <LinkModal selectedMethod={selectedLinkMethod} clearMethod={handleClose} />
      {selectedToUnlink && <UnlinkModal selectedMethod={selectedToUnlink} clearMethod={handleClose} />}
    </S.Container>
  );
};

export default UserLinks;
