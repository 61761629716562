import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ROUTES } from 'app/constants';
import { dispatch } from 'hooks';
import { getCurrentTheme, getIsShowSuccessAuthModal, setIsShowSuccessAuthModal } from 'store';

import { SuccessAuthModal } from 'features/auth';
import { Tab } from 'features/auth/ui';
import { Modal } from 'shared/ui';
import { useGlobalPageAnimation } from '../../shared/utils/GlobalPageAnimation/useGlobalPageAnimation';
import { Logo } from 'shared/utils';

import styles from './styles.module.css';
import IconButton from 'components/library/iconButton';
import { BiChevronLeft } from 'react-icons/bi';

export const AuthPage = () => {
  const { t } = useTranslation('auth');
  const { pathname } = useLocation();
  const isShowSuccessAuthModal = useSelector(getIsShowSuccessAuthModal);
  const isSignUp = pathname === ROUTES.SIGN_UP;
  const theme = useSelector(getCurrentTheme);
  const navigate = useNavigate();
  const onCloseModalHandler = () => {
    dispatch(setIsShowSuccessAuthModal(false));
  };

  useGlobalPageAnimation({ dependency: [false] });

  if (isShowSuccessAuthModal) {
    return <SuccessAuthModal open onClose={onCloseModalHandler} title={'Registration Success'} subTitle={'Lets jump right in!'} />;
  }

  return (
    <Modal
      className={clsx(styles.modal)}
      containerClassName={clsx(styles.modalBg, styles[theme])}
      open
      onClose={onCloseModalHandler}
      isClosable={false}
    >
      <div className={clsx(styles.container, 'mobile-padding')}>
        <IconButton size={40} className={styles.backButton} icon={<BiChevronLeft size={25} />} onClick={() => navigate(ROUTES.AUTH)} />
        <div className={styles.variantContainer}>
          <Tab index={1} title={t('tabs.login')} path={ROUTES.LOGIN} />
          <Tab index={2} title={t('tabs.signUp')} path={ROUTES.SIGN_UP} />
        </div>
        {isSignUp ? (
          <>
            <div className={styles.title}>
              <span>{t('join')}</span>
              <Logo containerClassName={styles.logo} width={35} />
              <span>{t('waitlist')}</span>
            </div>
            {/* <span className={styles.greetings}>{t('signUp.betaGreeting')}</span> */}
          </>
        ) : (
          <div className={styles.title}>
            <span>{t('loginTo')}</span>
            <Logo containerClassName={styles.logo} width={35} />
            <span>{t('companyName')}</span>
          </div>
        )}
        <Outlet />
      </div>
    </Modal>
  );
};
