import styled, { css } from 'styled-components';

export const Modal = styled.div(
    () => css`
      & * {
        user-select: none;
      }
      &.modalContainer {
        &.light {
          background: none;
        }
      }
      &.modal {
        height: fit-content;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
        padding: 0 !important;
        overflow: visible;
        top: 20px;
        border-radius: 12px;
        z-index: 3000;
      }
    `
  ),
  MobileModal = styled.div(
    () => css`
      .close-btn {
        position: fixed;
        top: 10px;
        right: 10px;
        z-index: 1002;
        padding: 10px;
      }
      .mobileModalContainer {
        height: 550px;
        padding-bottom: 40px;
        background: linear-gradient(180deg, rgb(253 79 79 / 18%) 5.23%, rgba(40, 42, 57, 0.8) 90.31%);
        display: flex;
        justify-content: center;
        &.light {
          background: linear-gradient(rgb(253 79 79 / 12%) 5.23%, rgb(255 255 255 / 80%) 90.31%);
        }
      }
    `
  ),
  BG = styled.div(
    () => css`
      position: absolute;
      /* top: 165px; */
      left: 0;
      width: 100%;
      height: 450px;
      backdrop-filter: blur(12px);
      z-index: 1;
      border-radius: 12px;
      background: linear-gradient(180deg, rgb(253 79 79 / 18%) 5.23%, rgba(40, 42, 57, 0.8) 75.31%);
      border: 2px solid rgba(255, 255, 255, 0.05);

      &.light {
        background: linear-gradient(rgb(255 236 236) 5.23%, rgb(255, 255, 255) 70.31%);
        border: 1px solid rgba(241, 79, 99, 0.2);
      }
    `
  ),
  Container = styled.div(
    () => css`
      padding-bottom: 43px;
      padding-top: 108px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 560px;
      height: 450px;
      position: relative;
      z-index: 2;

      & p,
      & h1 {
        color: var(--font-color-strong);
      }

      &.mobile {
        width: 100%;
        max-width: 600px;
        height: 700px;
        padding-bottom: 0;
        /* top: -120px; */
        padding: 110px 15px 130px;

        ${Header} {
          font-size: 28px;
          margin-bottom: 12px;
          padding: 0;
        }
        ${Text} {
          font-size: 16px;
          padding: 0;
        }
        ${Images} {
          top: 0;
        }
      }

      &.button {
        height: 58px !important;
      }
    `
  ),
  BenefitsContainer = styled.div(
    () => css`
      max-width: 100%;
      overflow-x: auto;
      margin-bottom: 20px;
      min-height: fit-content;

      .benefits {
        padding-left: 0;
        padding-right: 0;
      }

      &.desktop {
        max-width: 100%;
        .carousel {
          height: 200px;
          .caro-button.light {
            color: rgba(100, 135, 239, 1);
            opacity: 0.7;
          }
        }
      }
    `
  ),
  Images = styled.div(
    () => css`
      position: absolute;
      top: 0;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 240px;
      height: 240px;
    `
  ),
  Header = styled.h1(
    () => css`
      font-size: 42px;
      font-weight: 500;
      line-height: 120%;
      letter-spacing: 0.5px;
      margin-bottom: 12px;
      padding: 0 43px;
    `
  ),
  Text = styled.p(
    () => css`
      font-size: 18px;
      line-height: 143%;
      letter-spacing: 0.5px;
      margin-bottom: 26px;
      text-align: center;
      span {
        color: rgba(var(--premium--rgb), 1);
      }
      padding: 0 43px;
    `
  ),
  Image = styled.svg(
    () => css`
      min-width: 200%;
    `
  ),
  Button = styled.button(
    () => css`
      width: fit-content;
      font-size: 20px;
      height: fit-content;

      .button-icon {
        width: 37px;
      }
    `
  ),
  ItemsList = styled.div`
    display: flex;
    align-items: center;
    gap: 13px;
    margin-bottom: 26px;
  `,
  ItemBox = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: radial-gradient(50% 50% at 50% 50%, rgba(41, 171, 136, 0.25) 0%, rgba(41, 171, 136, 0) 100%), rgba(41, 171, 136, 0.12);
    display: flex;
    justify-content: center;
    align-items: center;
    &.light {
      background: radial-gradient(92.19% 208.32% at 85.62% 22.96%, rgba(32, 255, 205, 0.4) 0%, rgba(255, 255, 255, 0) 100%),
        linear-gradient(76.31deg, rgba(255, 255, 255, 0.7) -1.86%, rgba(255, 255, 255, 0.13) 100.22%);
      border: 2px solid #29ab88;
    }
  `,
  ItemImage = styled.img`
    width: 57px;
    height: 57px;
    object-fit: contain;
  `;
