//
// import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
// import { ReactComponent as WalletIcon } from 'assets/components/wallet.svg';
import { SignUpForm } from 'features/auth';
import {
  AnimateWrapper,
  // Terms
} from 'features/auth/ui';
import { ISignUpForm } from 'interface';
// import { Button } from 'shared/ui';

interface IProps {
  isLoading: boolean;
  error?: string;
  onSubmitHandler: (data: ISignUpForm) => void;
  openWalletModal: () => void;
}

export const SignUp = ({ onSubmitHandler, isLoading, error, openWalletModal }: IProps) => {
  // const { t } = useTranslation('auth');
  return (
    <AnimateWrapper>
      <div className={styles.container}>
        <SignUpForm error={error} onSubmitHandler={onSubmitHandler} isLoading={isLoading} />
        {/* <div className={styles.subTitle}>{t('or')}</div> */}
        {/* <Button className={styles.walletButton} icon={WalletIcon} variant={'bordered'} onClick={openWalletModal}>
          {t('walletAuth')}
        </Button> */}
      </div>
    </AnimateWrapper>
  );
};
