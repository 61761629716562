import { FC, PropsWithChildren } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BackButton } from 'components/library/BackButton';

import style from './styles.module.scss';
import { useSelector } from 'react-redux';
import { getPartnerId } from 'store';
import { ROUTES } from 'app/constants';

export const AuthPageViewDesktop: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const { key, state } = useLocation();
  const partnerId = useSelector(getPartnerId);
  const handleBackButtonClick = () => {
    if (partnerId) {
      navigate(`/space/${partnerId}?partnerID=${partnerId}`);
      return;
    }
    if (state && state.toMain) {
      navigate(ROUTES.MAIN);
      return;
    }
    if (key !== 'default') {
      navigate(-1);
      return;
    }
    navigate('/');
  };

  return (
    <div className={style.wrapper}>
      <BackButton className={style.authBackButton} onClick={handleBackButtonClick} text={'Back'} />

      <div className={style.wrap}>{children}</div>
    </div>
  );
};
