import { FC, useMemo } from 'react';
import { Variants, motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store/app';
import { getFormatDate } from 'utils/date';
import { IProfileDailyReward } from 'interface';
import { EAnimationStates, IDailyReward } from '../../../types';

import { DailyCheckLottie } from 'components/library/cards/calendar/lottie/DailyCheckLottie';
import { ReactComponent as Undercolor } from './assets/undercolor.svg';

import * as Styled from './styled';
import clsx from 'clsx';

interface IProps {
  rewards: IDailyReward[];
  userDailis: IProfileDailyReward[];
  isRewardable: boolean;
  variant?: 'banner' | 'panel';
  onlyToday?: boolean;
  animationState?: EAnimationStates;
  handleProcessStep(): void;
}

const variants: Variants = {
  initial: {
    width: '120px',
    left: '-45px',
    bottom: '-35px',
  },
  animate: {
    width: '368px',
    left: '-132px',
    bottom: '-225px',
  },
};

const checkVariants: Variants = {
  hidden: {
    opacity: 1,
  },
  visible: {
    opacity: 1,
  },
};

const bgVariants: Variants = {
  initial: { scale: 0, opacity: 0 },
  animate: { scale: [0.5, 0.8, 1, 0.8, 0.5], opacity: 1 },
};

export const CurrentRewards: FC<IProps> = ({
  rewards,
  userDailis,
  isRewardable,
  variant = 'banner',
  onlyToday = false,
  animationState = EAnimationStates.INIT,
  handleProcessStep,
}) => {
  const theme = useSelector(getCurrentTheme);
  const startIndex = useMemo(() => (isRewardable ? userDailis.length : userDailis.length - 1 >= 0 ? userDailis.length - 1 : userDailis.length), []);

  const getGradeColor = (grade: string) => getComputedStyle(document.body).getPropertyValue(`--grade-${theme}-color-${grade}`);

  const currentReward = rewards[startIndex];
  const previousReward = rewards[startIndex - 1];

  const start = startIndex === 0 ? startIndex : startIndex - 1;
  const end = startIndex ? start + 3 : start + 2;

  const isPreLoading = animationState === EAnimationStates.PRE_LOADING;
  const isLoading = animationState === EAnimationStates.LOADING;
  const isSuccess = animationState === EAnimationStates.SUCCESS;
  const isItemAnimated = isPreLoading || isLoading || isSuccess;

  function handleItemStep() {
    if (isPreLoading) {
      handleProcessStep();
    }
  }

  function handleCheckStep() {
    if (isSuccess) {
      setTimeout(() => {
        handleProcessStep();
      }, 3000);
    }
  }

  if (onlyToday) {
    return (
      <Styled.Card
        className={clsx(theme, currentReward.grade)}
        $current={true}
        $variant={variant}
        $colorBg={getGradeColor(currentReward.grade)}
        $previous={false}
      >
        {!isRewardable && <DailyCheckLottie variant={'gold'} className={'daily-check'} />}
        <Styled.TextWrap>
          <Styled.Today>Today</Styled.Today>
          <Styled.Date>{getFormatDate(new Date())}</Styled.Date>
          <Styled.Quantity>x{currentReward.quantity}</Styled.Quantity>
        </Styled.TextWrap>
        <Styled.Image src={currentReward.image} alt={currentReward.name} />
      </Styled.Card>
    );
  }

  return (
    <>
      <Styled.Wrapper>
        {!Boolean(startIndex) && variant === 'panel' && (
          <Styled.Card className={clsx(theme)} $current={false} $previous={false} $colorBg={''} $variant={variant} />
        )}

        {rewards.slice(start, end).map((reward) => {
          const isCurrentReward = currentReward._id === reward._id;
          const isPreviousReward = previousReward?._id === reward._id;
          const isCurrentCollected = isCurrentReward && !isRewardable;

          const image = reward.image;
          const date = new Date();
          date.setDate(isCurrentReward ? date.getDate() : isPreviousReward ? date.getDate() - 1 : date.getDate() + 1);

          return (
            <Styled.Card
              className={clsx(theme, reward.grade)}
              key={reward._id}
              $current={isCurrentReward}
              $previous={previousReward && previousReward._id === reward._id}
              $colorBg={getGradeColor(reward.grade)}
              $variant={variant}
            >
              {isCurrentCollected && (
                <motion.div
                  variants={checkVariants}
                  initial={'initial'}
                  animate={isCurrentCollected ? 'visible' : 'hidden'}
                  transition={{ duration: 1, delay: 0.4 }}
                >
                  <DailyCheckLottie variant={'gold'} className={'daily-check'} />
                </motion.div>
              )}

              <Styled.TextWrap>
                {isCurrentReward && <Styled.Today>Today</Styled.Today>}
                <Styled.Date>{getFormatDate(date)}</Styled.Date>
                <Styled.Quantity>x{reward.quantity}</Styled.Quantity>
              </Styled.TextWrap>

              {isCurrentReward ? (
                <>
                  <Styled.Image
                    key={reward._id + 'image'}
                    as={motion.img}
                    variants={variants}
                    initial={'initial'}
                    animate={isItemAnimated ? 'animate' : 'initial'}
                    transition={{ duration: 1, delay: isItemAnimated ? 0.4 : 0.6 }}
                    src={image}
                    alt={reward.name}
                    style={{ willChange: 'width left bottom', zIndex: 10 }}
                    onAnimationComplete={handleItemStep}
                  />

                  {
                    <Styled.CheckContainer
                      onAnimationComplete={handleCheckStep}
                      as={motion.div}
                      key={reward._id + 'check'}
                      variants={checkVariants}
                      initial={'initial'}
                      animate={animationState === EAnimationStates.SUCCESS ? 'visible' : 'hidden'}
                      transition={isSuccess ? { duration: 0, delay: 0 } : { duration: 1, delay: 0.4 }}
                      style={{ willChange: 'display' }}
                    >
                      {isSuccess && <DailyCheckLottie variant={'gold'} className={'success-check'} />}
                    </Styled.CheckContainer>
                  }

                  <Styled.Undercolor
                    key={reward._id + 'bg'}
                    as={motion.div}
                    variants={bgVariants}
                    initial={'initial'}
                    animate={isItemAnimated ? 'animate' : 'initial'}
                    transition={{
                      duration: isItemAnimated ? 3 : 1,
                      repeat: isItemAnimated ? Infinity : 1,
                      ease: 'linear',
                    }}
                  >
                    <Undercolor color={`var(--grade-${theme}-color-${reward.grade})`} />
                  </Styled.Undercolor>

                  <Styled.Name
                    as={motion.div}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: isItemAnimated ? 1 : 0 }}
                    transition={{ duration: 1, delay: isItemAnimated ? 0.4 : 0.8 }}
                    $color={`var(--grade-${theme}-color-${reward.grade})`}
                  >
                    <p className={'name'}>{reward.name}</p>
                    <p className={'grade'}>{reward.grade}</p>
                  </Styled.Name>
                </>
              ) : (
                <Styled.Image src={reward.image} alt={reward.name} />
              )}
            </Styled.Card>
          );
        })}
      </Styled.Wrapper>
    </>
  );
};
