import { useDebounce, useDisplay, useObserver } from 'hooks';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { ICourseFilteringGroup, useGetCourses } from '../hooks';
import { ICourse } from 'interface/courses';
import { InView } from 'shared/ui/motion';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { CourseCard } from 'shared/ui/course-card';
import * as S from './styles';
import { Toggle } from './toggle';
import { CourseCardSkeleton } from 'shared/ui/course-card/CourseCard';

type Props = {
  isOpen?: boolean;
  group: ICourseFilteringGroup;
  courses: ICourse[];
  isEditMode?: boolean;
  handleLoadCourses: (courses: ICourse[]) => void;
  selectCourse: (courseId?: string) => () => void;
  handleToggle: (group: string) => void;
};

interface IState {
  isOpen: boolean;
  limit: number;
  page: number;
  totalPages: number;
}
const defaultState: IState = {
  isOpen: false,
  limit: 12,
  page: 1,
  totalPages: 1,
};

export const CoursesGroup = ({
  group,
  handleLoadCourses,
  courses: coursesGiven,
  selectCourse,
  isEditMode = false,
  isOpen = false,
  handleToggle,
}: Props) => {
  const { isMobile } = useDisplay();
  const debounce = useDebounce();
  const { fetchCourses, isLoading } = useGetCourses(isEditMode);
  const [courses, setCourses] = useState<typeof coursesGiven>([]);
  const [{ limit, page, totalPages }, updateState] = useReducer((prev: IState, next: Partial<IState>) => ({ ...prev, ...next }), defaultState);

  const isLoadable = useMemo(() => page <= totalPages && isOpen, [page, totalPages, isOpen]);

  const maxSkeletonsAmount = isMobile ? 1 : 4;
  const SKELETONS_AMOUNT = useMemo(() => {
    return coursesGiven.length < 1 ? maxSkeletonsAmount : maxSkeletonsAmount - ((coursesGiven.length + 4) % maxSkeletonsAmount);
  }, [coursesGiven.length, maxSkeletonsAmount]);

  useEffect(() => {
    setCourses(coursesGiven);
  }, [coursesGiven.map((course) => course._id).join(',')]);

  const handleFetch = useCallback(async () => {
    if (isLoading) return;

    if (isOpen && isLoadable) {
      fetchCourses({ page, limit, groups: group.native })
        .then((data) => {
          if (!data) return;
          const { items, totalPages } = data;
          if (items.length > 0) {
            handleLoadCourses(items);
          } else {
            updateState({ page: totalPages > 0 ? totalPages : 1, totalPages });
            return;
          }
          updateState({ totalPages, page: page + 1 });
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [group.native, isOpen, isLoadable, isLoading, page]);

  const handlerLoader = useCallback(
    debounce((isVisible: boolean) => {
      if (isVisible && !isLoading) handleFetch();
    }, 200),
    [handleFetch, isLoading]
  );

  const { observerRef: loaderRef } = useObserver({ handler: handlerLoader });

  const courseCardProps = isEditMode
    ? {
        customLabel: 'Edit course',
        isEditMode: true,
      }
    : {};

  return (
    <InView>
      <S.Container className={clsx({ mobile: isMobile, empty: (courses.length < 1 && !isLoadable && !isLoading) || !isOpen })}>
        <S.Toggle
          className={clsx({ mobile: isMobile, empty: (courses.length < 1 && !isLoadable && !isLoading) || !isOpen })}
          as={Toggle}
          group={group}
          isOpen={isOpen}
          onClick={() => handleToggle(group.native)}
          isLoading={isLoading}
        />
        <AnimatePresence>
          <S.Courses
            animate={
              isOpen
                ? {
                    height: 'auto',
                    scale: 1,
                    opacity: 1,
                  }
                : { height: 0, scale: 0.98, opacity: 0 }
            }
            className={clsx({ mobile: isMobile })}
          >
            <AnimatePresence exitBeforeEnter>
              {courses.length > 0 && isOpen && (
                <>
                  {courses.map((course, index) => (
                    <motion.div
                      key={course._id}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0, transition: { delay: 0 } }}
                      transition={{ delay: 0.08 * (index % 4) + 0.05 * (index / 4) }}
                    >
                      <CourseCard course={course} selectCourse={selectCourse(course._id)} {...courseCardProps} />
                    </motion.div>
                  ))}
                </>
              )}
            </AnimatePresence>
            <AnimatePresence>
              {isLoadable && (
                <>
                  {[...Array(SKELETONS_AMOUNT).keys()].map((i, index) => {
                    return (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.1 * (4 + index) }}
                        key={`skeletron-${index}`}
                      >
                        <CourseCardSkeleton isMobile={isMobile} key={'CourseCardSkeleton' + i} ref={index === 0 ? loaderRef : null} />
                      </motion.div>
                    );
                  })}
                </>
              )}
            </AnimatePresence>
            {!isLoadable && !isLoading && !courses.length && !coursesGiven.length && isOpen && (
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.3 } }} style={{ padding: '15px 0px 10px 10px' }}>
                No courses
              </motion.div>
            )}
          </S.Courses>
        </AnimatePresence>
      </S.Container>
    </InView>
  );
};
