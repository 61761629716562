import { useState } from 'react';
import { useCoursesContext } from '../context';
import { getAppSettings } from 'store';
import { useSelector } from 'react-redux';

import Groups from './Groups';
import SearchScreen from './SearchScreen';

import * as Styled from './styles';
import { useGetCourseGroups } from 'hooks';

type Props = {
  className?: string;
};

const MobileSearchbar = ({ className = '' }: Props) => {
  const { displayedName } = useSelector(getAppSettings);
  const { handleGroups, activeGroups, searchForCourses } = useCoursesContext();
  const [isOpen, setIsOpen] = useState(false);
  const { groupsNativeNames: groupsNames } = useGetCourseGroups();

  function handleOpen() {
    setIsOpen(true);
  }
  function handleClose() {
    setIsOpen(false);
    searchForCourses(null);
  }

  return (
    <>
      <Styled.Container
        className={className}
        as={Groups}
        isCourses={true}
        activeGroups={activeGroups.map((i) => i.label)}
        handleGroups={handleGroups}
        isSearchIcon={true}
        handleOpen={handleOpen}
        groups={groupsNames}
      />
      <Styled.Header className="mobile-padding">
        <h1>Welcome to {displayedName} Courses</h1>
        <p>Learn and earn EXP</p>
      </Styled.Header>
      <SearchScreen isOpen={isOpen} handleClose={handleClose} />
    </>
  );
};

export default MobileSearchbar;
