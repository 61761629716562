import throttle from 'redux-throttle';

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { authApi } from './auth';
import { profileApi } from './profile';
import { shopApi } from './shop';
import { friendsApi } from './friends';
import { coursesApi } from './course';
import { coursesConstructorAPI, coursesConstructorReducer } from './coursesConstructor';
import { battleApi } from './battle';
import { searchApi } from './search';
import { itemApi } from './item';
import { meditationApi } from './meditation/meditation.api';
import { commentsApi } from './comments/comments.api';
import { statisticsApi } from './statistics';
import { chestsApi } from './chests/chests.api';
import { cryptoApi } from './crypto';
import { compileApi } from './compile/compile.api';
import { tasksCheckApi } from './tasks-check/tasks-check.api';

import appSlice from './app/app.slice';
import authSlice from './auth/auth.slice';
import profileSlice from './profile/profile.slice';
import mobilePanelsSlice from './mobilePanels/mobile-nav.slice';
import modalsSlice from './modals/modals.slice';
import shopSlice from './shop/shop.slice';
import meditationSlice from './meditation/meditation.slice';
import booksSlice from './books/books.slice';
import commentsSlice from './comments/comments.slice';
import textToSpeechSlice from './textToSpeech/textToSpeech.slice';
import feedSlice from './spaces/feed.slice';
import websocketSlice from './websocket/websocket.slice';
import { leaderboardApi } from './leaderboard';
import { appApi } from './app/app.api';
import { answersApi } from './answers/answers.api';
import { spacesApi } from './spaces';
import { buildVersion } from 'version';
import { clearOldPersistedKeys } from 'utils/clearOldStatePersist';
import { courseParserAPI } from './courseParser';
import partnerSlice from './partner/partner.slice';
import { persistRootKeyPrefix } from 'app/constants';

//TODO потом решить проблему с типизацией
// Появляется проблема с типизацией в компонентах где используются сжатые данные
// const compressor = createCompressor();

enum PersistKeys {
  Profile = 'profile',
  Auth = 'auth',
  App = 'app',
  Shop = 'shop',
}

const currentPersistKey = `${persistRootKeyPrefix}-${buildVersion}`;

const createVersionedPersistConfig = (version: string) => ({
  key: `${persistRootKeyPrefix}-${version}`,
  storage,
  version: 1,
  whitelist: [PersistKeys.Auth, PersistKeys.App, PersistKeys.Profile, PersistKeys.Shop],
  migrate: (state: any) => {
    // You can add migration logic here if needed
    return Promise.resolve(state);
  },
});

const defaultThrottleOption = {
  leading: false, // Не вызываем функцию немедленно
  trailing: true, // Вызываем функцию в конце интервала ожидания
  wait: 200, // Интервал времени в миллисекундах для троттлинга вызовов
  maxWait: 500, // Максимальный интервал времени между вызовами функции
};

const rootReducer = combineReducers({
  [appApi.reducerPath]: appApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [friendsApi.reducerPath]: friendsApi.reducer,
  [shopApi.reducerPath]: shopApi.reducer,
  [coursesApi.reducerPath]: coursesApi.reducer,
  [battleApi.reducerPath]: battleApi.reducer,
  [searchApi.reducerPath]: searchApi.reducer,
  [itemApi.reducerPath]: itemApi.reducer,
  [coursesConstructorAPI.reducerPath]: coursesConstructorAPI.reducer,
  [spacesApi.reducerPath]: spacesApi.reducer,
  [meditationApi.reducerPath]: meditationApi.reducer,
  [commentsApi.reducerPath]: commentsApi.reducer,
  [answersApi.reducerPath]: answersApi.reducer,
  [statisticsApi.reducerPath]: statisticsApi.reducer,
  [leaderboardApi.reducerPath]: leaderboardApi.reducer,
  [chestsApi.reducerPath]: chestsApi.reducer,
  [cryptoApi.reducerPath]: cryptoApi.reducer,
  [compileApi.reducerPath]: compileApi.reducer,
  [tasksCheckApi.reducerPath]: tasksCheckApi.reducer,
  [courseParserAPI.reducerPath]: courseParserAPI.reducer,

  app: appSlice,
  auth: authSlice,
  profile: profileSlice,
  shop: shopSlice,
  mobile: mobilePanelsSlice,
  modals: modalsSlice,
  coursesConstructor: coursesConstructorReducer,
  meditation: meditationSlice,
  books: booksSlice,
  comments: commentsSlice,
  textToSpeech: textToSpeechSlice,
  feed: feedSlice,
  websocket: websocketSlice,
  partner: partnerSlice,
});

const persistConfig = createVersionedPersistConfig(buildVersion);
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      appApi.middleware,
      authApi.middleware,
      profileApi.middleware,
      friendsApi.middleware,
      shopApi.middleware,
      coursesApi.middleware,
      battleApi.middleware,
      searchApi.middleware,
      itemApi.middleware,
      coursesConstructorAPI.middleware,
      spacesApi.middleware,
      meditationApi.middleware,
      commentsApi.middleware,
      answersApi.middleware,
      statisticsApi.middleware,
      leaderboardApi.middleware,
      chestsApi.middleware,
      cryptoApi.middleware,
      compileApi.middleware,
      tasksCheckApi.middleware,
      courseParserAPI.middleware,
      throttle(defaultThrottleOption.wait, defaultThrottleOption)
    ),
});

export const persistor = persistStore(store, null, () => {
  clearOldPersistedKeys(currentPersistKey);
});

export type RootStateType = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
