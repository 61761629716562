import { useWeb3Modal, useWeb3ModalTheme } from '@web3modal/wagmi/react';
import { useAccount, useSignMessage, useDisconnect } from 'wagmi';
import { useAttachUser, useUnattachUser } from 'hooks';
import { useCallback, useState } from 'react';
import { useLazyGetSignatureMessageQuery, useLazyGetUserDataQuery, useLoginMFAMutation } from 'store';
import { EAuthMethods } from 'interface';
import { toast } from 'react-toastify';
import Answer from 'components/library/messages/Answer';

type TAuthData = {
  address: string;
  message: string;
  signature: string;
  referralCode?: string;
};

const useAuthDataGetter = () => {
  const { disconnect } = useDisconnect();
  const { signMessageAsync } = useSignMessage();
  const [generateMessage] = useLazyGetSignatureMessageQuery();
  const { address, isConnected } = useAccount();

  const getAuthData = async () => {
    if (!address || !isConnected) {
      throw new Error('Connect your wallet first');
    }

    try {
      const { message } = await generateMessage({ address, type: EAuthMethods.EVM }).unwrap();
      const signature = await signMessageAsync({ message });
      const referralCode = localStorage.getItem('referralCode') || undefined;

      return { message, signature, referralCode, address };
    } catch (error: any) {
      disconnect();
      if (error.code !== 4001) {
        throw error;
      }
    }
  };

  return getAuthData;
};

export const useEVMAuth = () => {
  const [login, { isLoading: isLogingIn }] = useLoginMFAMutation();
  const [getUser, { isFetching: isUserLoading }] = useLazyGetUserDataQuery();
  const { handleAttach: attach, isLoading: isAttaching } = useAttachUser({ method: EAuthMethods.EVM });
  const { handleUnattach, isLoading: isUnattaching } = useUnattachUser({ method: EAuthMethods.EVM });
  const getAuthData = useAuthDataGetter();
  const { setThemeVariables } = useWeb3ModalTheme();

  const [authData, setAuthData] = useState<null | TAuthData>(null);

  const { open: openModal, close: closeModal } = useWeb3Modal();
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();

  const handleDisconnect = useCallback(() => {
    if (isConnected) {
      disconnect();
      setAuthData(null);
    }
  }, [disconnect, isConnected]);

  const handleAuthentification = useCallback(async () => {
    try {
      const newAuthData = await getAuthData();
      if (newAuthData) {
        setAuthData(newAuthData);
      }
    } catch (error: any) {
      console.error('Error during authentication:', error);
      toast.error(<Answer label={'Oops!'} subtext={error?.data?.message ?? 'An error occured while authenticating'} type="incorrect" />);
      handleDisconnect();
    }
  }, [getAuthData, handleDisconnect]);

  const handleLogin = useCallback(async () => {
    if (!authData || isLogingIn || isUserLoading) return;
    const { address, message, signature, referralCode } = authData;

    const attemptLogin = async (retryCount = 0): Promise<void> => {
      try {
        await login({ type: EAuthMethods.EVM, data: { address, message, signature, referralCode } })
          .unwrap()
          .then(() => {
            getUser();
          });
      } catch (ex: any) {
        console.error(ex);
        if (ex.data.code === 'EVM_NONCE_IS_EXPIRED' && retryCount < 3) {
          toast.error(<Answer label={'Oops!'} subtext="Nonce is expired, let's try again" type="incorrect" />);
          // Retry login with new auth data
          const newAuthData = await getAuthData();
          if (!newAuthData) {
            handleDisconnect();
            return;
          }
          setAuthData(newAuthData);
          return attemptLogin(retryCount + 1);
        }
        handleDisconnect();
        if (ex.data.code === 'EVM_NONCE_IS_EXPIRED') {
          toast.error(<Answer label={'Oops!'} subtext="Failed to login after multiple attempts. Please try again." type="incorrect" />);
        } else {
          toast.error(<Answer label={'Oops!'} subtext={ex?.data?.message} type="incorrect" />);
        }
      }
    };

    await attemptLogin();
  }, [authData, login, getUser, isLogingIn, isUserLoading, handleDisconnect, getAuthData, setAuthData]);

  const handleAttach = useCallback(async () => {
    if (!authData || !isConnected || isAttaching) return;
    attach(authData).catch((ex) => {
      handleDisconnect();
      toast.error(<Answer label={'Oops!'} subtext={ex?.data?.message} type="incorrect" />);
    });
  }, [attach, authData, isConnected, isAttaching, handleDisconnect]);

  const handleOpenModal = useCallback(() => {
    setThemeVariables({ '--w3m-z-index': 10000 });
    openModal();
  }, [openModal]);

  return {
    address,
    isConnected,
    authData,
    isLogingIn,
    isUserLoading,
    isAttaching,
    isUnattaching,
    openModal: handleOpenModal,
    closeModal,
    handleLogin,
    handleAttach,
    handleUnattach,
    handleAuthentification,
    handleDisconnect,
  };
};
