import { PageBackground } from 'components/shared/PageBackground/PageBackground';
import { MouseEventHandler, useState } from 'react';
import * as S from './styles';
import { BackButton } from 'components/library/BackButton';
import { useGetCourseGroups, useGetUserData } from 'hooks';
import GroupCard from 'components/pages/study/courses/group-cards/group-card/group-card';
import { EGroupTag } from 'app/constants';

import { BasicButtonV1 } from 'shared/ui/button';
import { useUpdateInterests } from './useUpdateInterests';

interface IGreetingProps {
  onClose: MouseEventHandler<HTMLButtonElement>;
  onSubmit: Function;
  isBackButton?: boolean;
}

export const InterestsPicker = ({ onClose, onSubmit, isBackButton = true }: IGreetingProps) => {
  const { interests } = useGetUserData();
  const { handler: updateUserInterest, isLoading } = useUpdateInterests();
  const { tags, favouritesKey, EGroupTag } = useGetCourseGroups();
  const [chosen, setChosen] = useState<string[]>(interests ?? []);

  const handleGroupClick = (group: string) => {
    const newArr = chosen.includes(group) ? chosen.filter((g) => g !== group) : [...chosen, group];
    setChosen(newArr);
  };

  const handleContinue = async () =>
    await updateUserInterest(chosen as EGroupTag[]).then((success) => {
      if (success) {
        onSubmit();
      }
    });

  return (
    <S.Container
      key={'interests'}
      initial={{ scale: 0, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ x: -1000, opacity: 0, transition: { delay: 0 } }}
      transition={{ delay: 0.5 }}
    >
      <S.Header>What do you want to learn?</S.Header>
      <S.Groups>
        {Object.values(tags)
          .filter((g) => ![favouritesKey, EGroupTag.Originals].includes(g.native))
          .map((group) => (
            <S.GroupCard
              as={GroupCard}
              key={group.native}
              group={group}
              className={'group-card'}
              isActive={chosen.includes(group.native as EGroupTag)}
              onClick={() => handleGroupClick(group.native)}
            />
          ))}
      </S.Groups>
      {isBackButton && <S.BackButton as={BackButton} onClick={onClose} />}
      <S.ContinueBtn disabled={chosen.length < 1 || isLoading} as={BasicButtonV1} onClick={handleContinue}>
        Continue
      </S.ContinueBtn>
      {/* <S.Figure as={LazyImage} src={figure} alt="decorative figure" /> */}
    </S.Container>
  );
};
