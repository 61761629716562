import { useState } from 'react';
import { getCurrentAvatarShape, setCurrentAvatarShape, useChangeAvatarShapeMutation, useUpdateUserDripMutation, setIsFriendFinder } from 'store';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { dispatch, useGetCurrentTheme, useGetUserData } from 'hooks';
import uploadImage from 'utils/uploadImage';
import { AVATAR_SHAPES } from 'app/constants';

import { ReactComponent as Camera } from 'components/pages/profile/icons/camera.svg';
import { ReactComponent as RoundF } from 'assets/icons/type--round--filled.svg';
import { ReactComponent as Round } from 'assets/icons/type--round--line.svg';
import { ReactComponent as SquareF } from 'assets/icons/type--square--filled.svg';
import { ReactComponent as Square } from 'assets/icons/type--square--line.svg';
import { ReactComponent as Add } from 'components/library/images/icons/add-user.svg';

import LogoLoader from 'shared/ui/loader/MightyLoader';
import Nickname from 'shared/ui/nickname/Nickname';
// import { PremiumIcon } from 'shared/ui';
import AvatarPic from 'components/library/utils/AvatarPic/avatar';

import styles from './styles.module.scss';
import '../styles/screens.css';
import '../styles/profile.css';

const icons = {
  [AVATAR_SHAPES.ROUND]: {
    [AVATAR_SHAPES.ROUND]: <RoundF />,
    [AVATAR_SHAPES.SQUARE]: <Round />,
  },
  [AVATAR_SHAPES.SQUARE]: {
    [AVATAR_SHAPES.ROUND]: <Square />,
    [AVATAR_SHAPES.SQUARE]: <SquareF />,
  },
};

export default function Profile() {
  const userProfile = useGetUserData();
  const theme = useGetCurrentTheme();
  const shape = useSelector(getCurrentAvatarShape);
  const [isLoading, setIsLoading] = useState(false);
  const [updateDrip, { isLoading: isDripping }] = useUpdateUserDripMutation();
  const [changeShape] = useChangeAvatarShapeMutation();

  const handleShape = (value: AVATAR_SHAPES) => async () => {
    dispatch(setCurrentAvatarShape(value));
    await changeShape({ shape: value });
  };

  function openFinder() {
    dispatch(setIsFriendFinder(true));
  }

  async function handleImageUpload(e: React.ChangeEvent<HTMLInputElement>) {
    setIsLoading(true);
    const avatar = await uploadImage(e);
    updateDrip({ avatar });
    setIsLoading(false);
  }

  return (
    <div className="profile-main">
      <div className="profile-row">
        <AvatarPic
          className={clsx(styles.avatar, `menu-avatar`)}
          customization={userProfile.customization}
          avatar={userProfile?.avatar}
          nickname={userProfile?.nickname}
        />
        <div className="profile-col">
          <div className="profile-name screen-header" style={{ marginBottom: '17px' }}>
            <Nickname nickname={userProfile?.nickname} customization={userProfile?.customization} color={userProfile?.customization.name} />
            {/* {isPremium && <PremiumIcon />} */}
          </div>
          <div className="profile-follow">1 friend 2 followers 3 following</div>
          <button className={clsx(styles.add, `gray-button ${theme}`)} onClick={openFinder}>
            <Add />
            Add friends
          </button>
        </div>
      </div>
      <div className={styles.changerContainer}>
        <div className={styles.changerTitle}>Profile</div>
        <div className={styles.changerWrap}>
          <div>
            {isLoading || isDripping ? (
              <LogoLoader width={'100%'} />
            ) : (
              <div className={clsx(styles.changer, `avatar--${shape}`)}>
                <AvatarPic
                  isFramed={false}
                  className={clsx(styles.image)}
                  avatar={userProfile.avatar}
                  nickname={userProfile.nickname}
                  customization={userProfile?.customization}
                />
                <label className={styles.uploader}>
                  <input type="file" onChange={handleImageUpload} />
                  <Camera className={styles.camera} />
                </label>
              </div>
            )}
            <div className={styles.toggles}>
              <button onClick={handleShape(AVATAR_SHAPES.SQUARE)}>{icons[AVATAR_SHAPES.SQUARE][shape]}</button>
              <button onClick={handleShape(AVATAR_SHAPES.ROUND)}>{icons[AVATAR_SHAPES.ROUND][shape]}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
