import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import { useDailyRewardsContext } from '../../../context';
import clsx from 'clsx';

import { Logo } from 'shared/utils/logo/logo';

import logoGold from 'assets/logos/logo--gold.png';

import * as Styled from './styled';

interface IProps {
  variant?: 'banner' | 'panel';
  showGreat?: boolean;
  className?: string;
  isSmall?: boolean;
}

export const Streak: FC<IProps> = ({ variant = 'banner', showGreat = true, className = '', isSmall = false }) => {
  const theme = useSelector(getCurrentTheme);
  const { streak, isRewardable } = useDailyRewardsContext();

  const currentLogo = isRewardable ? <Logo size="xsmall" width={22} /> : <img src={logoGold} alt={'logo'} />;

  return (
    <Styled.Container $variant={variant} $isRewardable={isRewardable} $isSmall={isSmall} className={clsx({ [className]: !!className })}>
      <Styled.Streak theme={theme}>
        {currentLogo}
        <p>{streak} days streak</p>
      </Styled.Streak>
      {showGreat && !isRewardable && <Styled.Great>Great job!</Styled.Great>}
    </Styled.Container>
  );
};
