import { useTranslation } from 'react-i18next';
import { useDebounce, useGetCurrentTheme } from 'hooks';
import clsx from 'clsx';

import { ReactComponent as Search } from 'assets/icons/search.svg';

import styles from './styles.module.scss';

interface IProps {
  search: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const SearchCourses = ({ search, className = '' }: IProps) => {
  const debounce = useDebounce();
  const theme = useGetCurrentTheme();
  const { t } = useTranslation();

  return (
    <div className={clsx('mobile-padding', { [className]: !!className })}>
      <label className={clsx(styles.label, `glass-div basic ${theme}`)}>
        <Search className={styles.icon} />
        <input className={styles.input} type="text" placeholder={t('coursespage.searchbar.placeholder')} onChange={debounce(search, 500)} />
      </label>
    </div>
  );
};

export default SearchCourses;
