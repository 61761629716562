import styled, { css } from 'styled-components';
import bg from 'components/pages/layouts/fullscreen/assets/caves-bg.png';
import { motion } from 'framer-motion';

export const Wrapper = styled.div(
    () => css`
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1000;
      background-image: url(${bg});
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;

      .bg {
        display: none;
      }
    `
  ),
  Container = styled(motion.div)(
    () => css`
      /* backdrop-filter: blur(6px); */
      width: 640px;
      max-width: 95vw;
      height: 520px;
      flex-shrink: 0;
      border-radius: 12px;
      /* border: 2px solid rgba(255, 255, 255, 0.1); */
      /* background: linear-gradient(76deg, rgba(50, 53, 71, 0.47) 5.23%, rgba(40, 42, 57, 0.8) 107.31%); */
      /* backdrop-filter: blur(21.5px); */
      padding: 49px 39px;
      margin-top: min(200px, 15vh);
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 2;

      button {
        width: fit-content;
        border-radius: 31px;
        padding: 18px 40px;
      }

      &.mobile {
        height: fit-content;
        width: 100%;
        max-width: 100%;
        padding: 29px 19px;
        background: none;
        border: none;
      }
    `
  ),
  Header = styled.h1(
    () => css`
      color: #fff;
      font-size: 4.8rem;
      font-weight: 500;
      line-height: 119%;
      margin: 0;

      @media screen and (max-width: 768px) {
        font-size: 3rem;
      }
    `
  ),
  MiniUser = styled.div(
    () => css`
      z-index: 3000;
    `
  ),
  Panels = styled.div(
    () => css`
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      width: 100%;

      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
      }
    `
  ),
  Panel = styled.div(
    () => css`
      width: 272px;
      height: 112px;
      border-radius: 6px;
      background: linear-gradient(282deg, rgba(101, 124, 202, 0.95) -0.2%, rgba(77, 99, 152, 0.95) 98.72%);
      background-blend-mode: overlay;
      display: flex;
      align-items: center;
      color: #fff;
      padding-right: 10px;

      p {
        color: #fff;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 100%;
        margin-bottom: 6px;
      }
      span {
        font-size: 1.4rem;
        font-weight: 300;
        line-height: 124%;
        color: #fff;
      }
      img {
        height: 105px;
        width: auto;
      }
    `
  ),
  PanelBody = styled.div(
    () => css`
      display: flex;
      flex-direction: column;
    `
  ),
  Image = styled.img(
    () => css`
      height: 342px;
      position: relative;
      object-fit: contain;
      margin: -200px 0 -40px;
    `
  ),
  Coin = styled(motion.img)<{ $startX: number; $startY: number }>`
    aspect-ratio: 1 / 1;
    position: fixed;
    object-fit: contain;
    z-index: 1001;
    left: ${({ $startX }) => `${$startX}px`}; // Установка начального положения X
    top: ${({ $startY }) => `${$startY}px`}; // Установка начального положения Y
    transition: all 1s ease-in-out;
  `,
  Text = styled.p(
    () => css`
      color: #fff;
      font-size: 1.8rem;
      line-height: 143%;
      letter-spacing: 0.5px;
      margin-bottom: 26px;
    `
  );
