import { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from 'react';

import { ReactComponent as Search } from 'assets/icons/search.svg';
import { ReactComponent as CrossIcon } from 'assets/components/cross.svg';

import * as Styled from './styles';

interface IProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  handleClear(): void;
}

export const SearchInput = forwardRef<HTMLInputElement, IProps>(({ handleClear, ...rest }, ref) => {
  return (
    <Styled.Container>
      <Styled.SearchIcon as={Search} />
      <Styled.Input {...rest} ref={ref} />
      {rest.value && <Styled.ClearIcon as={CrossIcon} onClick={handleClear} />}
    </Styled.Container>
  );
});

export default SearchInput;
