export const REWARDS_BY_REFS = [
  {
    referrals: 1,
    text: '1 Nature Chest for 1 refs',
  },
  {
    referrals: 10,
    text: '10th chest + 5 USDC bonus',
  },
  {
    referrals: 20,
    text: '10th chest + 5 USDC bonus',
  },
  {
    referrals: 50,
    text: '10th chest + 5 USDC bonus',
  },
  {
    referrals: 100,
    text: '10th chest + 5 USDC bonus',
  },
  {
    referrals: 500,
    text: '10th chest + 5 USDC bonus',
  },
  {
    referrals: 1000,
    text: '100th chest with 500 USDC bonus',
  },
];

export const REWARDS_STEPS = [1, 10, 20, 50, 100, 500, 1000];

export const NumWidthByRefs: { [key: string]: number } = {
  _1: 47,
  _10: 48,
  _20: 48,
  _50: 48,
  _100: 57,
  _500: 57,
  _1000: 66,
};

export const USDC_BY_REFS = [
  {
    refs: 10,
    usdc: 5,
  },
  {
    refs: 20,
    usdc: 5,
  },
  {
    refs: 50,
    usdc: 5,
  },
  {
    refs: 100,
    usdc: 5,
  },
  {
    refs: 500,
    usdc: 5,
  },
  {
    refs: 1000,
    usdc: 500,
  },
];
