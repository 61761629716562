import { persistRootKeyPrefix } from 'app/constants';

function clearAllCaches() {
  if ('caches' in window) {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    console.log('All caches cleared due to version change');
  } else {
    console.log('Cache API not supported');
  }
}

export function clearOldPersistedKeys(currentKey: string) {
  const curKey = `persist:${currentKey}`;
  const keysToRemove: string[] = [];
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key && key.startsWith(`persist:${persistRootKeyPrefix}`) && key !== curKey) {
      keysToRemove.push(key);
    }
  }

  if (keysToRemove.length > 0) {
    clearAllCaches();
  }
  keysToRemove.forEach((key) => {
    localStorage.removeItem(key);
  });
}
