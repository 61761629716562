import { THEMES } from 'app/constants';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ $theme: THEMES }>(
    ({ $theme }) => css`
      display: flex;
      align-items: center;
      position: relative;
      height: 76px;
      cursor: pointer;
      &,
      & * {
        user-select: none;
      }
      color: ${$theme === THEMES.DARK ? '#fff' : '#111'};

      & .group-img {
        margin-right: 24px;
        margin-left: 7px;
      }

      &.mobile {
        height: 68px;
        padding: 8px 0 8px 9.5px;
        transition: margin 0.3s ease-in-out;
        ${BG} {
          top: 0;
          height: 100%;
          width: 100%;
        }

        ${Title} {
          font-weight: 400;
        }

        &.open {
          margin-bottom: 14px;
          ${Title} {
            font-weight: 500;
          }
          ${BG} {
            height: 42px;
            width: 100%;
            top: 16px;
          }
        }
      }
    `
  ),
  BG = styled.div(
    () => css`
      position: absolute;
      top: 13px;
      right: 0;
      border-radius: 7px;
      height: 56px;
      width: calc(100% - 30px);
      z-index: 1;
      transition: all 0.3s ease-in-out;
    `
  ),
  Title = styled.h6<{ $theme: THEMES }>(
    ({ $theme }) => css`
      flex: 1;
      z-index: 2;
      font-size: 20px;
      font-weight: 500;
      line-height: 1;
      text-transform: capitalize;
      color: ${$theme === THEMES.DARK ? '#fff' : '#111'};
      letter-spacing: -0.5px;
      margin-bottom: -5px;
      transition: 0.3s;
    `
  ),
  Loader = styled.div(
    () => css`
      width: auto;
      height: 50%;
      margin-right: 20px;
      margin-top: 5px;
    `
  ),
  Chevron = styled.div(
    () => css`
      z-index: 2;
      padding: 5px;
      height: 100%;
      aspect-ratio: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: -5px;

      svg {
        color: inherit;
      }
    `
  ),
  ImageContainer = styled.div(
    () => css`
      position: relative;
      height: 41px;
      width: 41px;
      min-height: 41px;
      min-width: 41px;
      z-index: 2;
      margin-right: 15px;
    `
  ),
  Image = styled.img(
    () => css`
      position: absolute;
      transform: rotate(10deg);
      height: 100%;
      width: 100%;
      min-height: 100%;
      min-width: 100%;
    `
  );
