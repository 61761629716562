import clsx from 'clsx';
import { useGetCourseGroups, useGetCurrentTheme, useGetIsAuth } from 'hooks';

import GroupCard from './group-card/group-card';

import styles from './styles.module.scss';
import { ICourseFilteringGroup } from '../hooks';
import UniversalSlider from 'components/library/universal-slider';
import { useMemo } from 'react';

interface IProps {
  activeGroups: ICourseFilteringGroup[];
  handleGroups: (arg: string) => void;
  className?: string;
}

export default function GroupCards({ activeGroups, handleGroups, className = '' }: IProps) {
  const theme = useGetCurrentTheme();
  const isAuth = useGetIsAuth();
  const { groupsNativeNames, tags } = useGetCourseGroups();

  const cards = useMemo(
    () =>
      groupsNativeNames
        .map((groupName) => {
          const group = tags[groupName];

          if (!group) {
            return null;
          }
          if (!isAuth && groupName === 'favourites') {
            return null;
          }

          const isActive = activeGroups.map((group) => group.native).includes(groupName);
          return <GroupCard key={groupName} isActive={isActive} group={group} onClick={() => handleGroups(group.native)} />;
        })
        .reduce((acc, curr, i, arr) => {
          if (i % 2 === 0) {
            //@ts-ignore
            acc.push([curr, arr[i + 1]]);
          }
          return acc;
        }, [])
        .map((r, i) => (
          <div key={'course-group-picker' + i} className={styles.slide}>
            {r}
          </div>
        )),
    [
      ...groupsNativeNames,
      Object.values(activeGroups)
        .map((i) => i.native)
        .join(''),
    ]
  );

  return (
    <div className={clsx(styles.container, styles[theme], { [className]: !!className })}>
      <UniversalSlider
        containerClassName={styles.categoriesSlider}
        slides={cards.filter(Boolean)}
        slidesToShow={4}
        slideWidth={245}
        slideHeight={148}
        slidesToScroll={2}
        variableWitdh
        arrows="sides"
        gap={16}
        arrowsOffset={40}
      />
    </div>
  );
}
