import { Flex, Modal } from 'shared/ui';
import styles from './styles.module.scss';
import ChainAnimation from './animation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import RoundSVG from './round';
import { EAuthMethods } from 'interface';
import { LINK_AUTH_METHODS } from '../types';
import { BUTTONS_BY_METHOD } from './buttons';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';
import { LinkModalContext, TStage } from './context';
import { IconContainer } from './IconContainer';
import { Logo } from 'shared/utils';
import { useGetSettings } from 'hooks';

type Props = {
  selectedMethod: EAuthMethods | null;
  clearMethod: () => void;
};

const LinkModal = ({ selectedMethod, clearMethod }: Props) => {
  const { displayedName } = useGetSettings();
  const [stage, setStage] = useState<TStage>('initial');
  const handleClose = () => {
    setStage('initial');
    clearMethod();
  };

  useEffect(() => {
    return () => setStage('initial');
  }, []);

  const buttonTitle = useMemo(() => {
    switch (stage) {
      case 'initial':
        return 'Link Account';
      case 'progress':
        return 'Link Account';
      case 'error':
        return 'Try Again';
      case 'meaningfull-error':
        return 'Try Again';
      case 'success':
        return 'Continue';
    }
  }, [stage]);

  const getCurrentTitle = useCallback(
    (methodTitle = '') => {
      switch (stage) {
        case 'initial':
          return `Please link your ${methodTitle} account`;
        case 'progress':
          return 'Linking Your Account...';
        case 'error':
          return 'Connection Error';
        case 'meaningfull-error':
          return 'Error';
        case 'success':
          return 'Success!';
      }
    },
    [stage]
  );

  const getCurrentSubtitle = useCallback(
    (methodTitle = '') => {
      switch (stage) {
        case 'initial':
          return '';
        case 'progress':
          return '';
        case 'error':
          return 'Please try again in a few minutes. If issue persists, contact support';
        case 'meaningfull-error':
          return 'Please try again in a few minutes. If issue persists, contact support';
        case 'success':
          return `${methodTitle} account linked to ${displayedName}`;
      }
    },
    [stage, displayedName]
  );

  if (!selectedMethod) {
    return null;
  }

  const MethodButton = BUTTONS_BY_METHOD[selectedMethod];

  if (!MethodButton) {
    return null;
  }

  const { icon, title: methodTitle } = LINK_AUTH_METHODS.find((el) => el.method === selectedMethod) || {};

  return (
    <Modal
      className={styles.modal}
      containerClassName={styles.modalContainer}
      onClose={handleClose}
      open={!!selectedMethod}
      closeButtonLocation="outside"
    >
      <LinkModalContext.Provider value={{ setStage, stage }}>
        <Flex className={styles.container} flexDirection="column" alignItems="center" justifyContent="space-between">
          <p className={styles.title}>{getCurrentTitle(methodTitle)}</p>
          {!!getCurrentSubtitle(methodTitle) && <p className={styles.subtitle}>{getCurrentSubtitle(methodTitle)}</p>}
          <IconContainer className={styles.box}>
            <IconContainer className={styles.iconBox}>
              <RoundSVG stage={stage} />
              <IconContainer className={styles.iconFrame}>
                <Logo containerClassName={styles.logo} size="small" />
              </IconContainer>
            </IconContainer>
            <ChainAnimation stage={stage} />
            <IconContainer className={styles.iconBox}>
              <RoundSVG stage={stage} />
              <IconContainer className={styles.iconFrame}>{!!icon && icon}</IconContainer>
            </IconContainer>
          </IconContainer>
          {stage === 'progress' ? (
            <MightyLoaderMini />
          ) : (
            <MethodButton handleClose={handleClose} setStage={setStage} stage={stage} title={buttonTitle} />
          )}
        </Flex>
      </LinkModalContext.Provider>
    </Modal>
  );
};

export default LinkModal;
