import { useSelector } from 'react-redux';
import * as S from './styles';
import { getCurrentTheme } from 'store';
import { useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';

import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as OkIcon } from 'assets/icons/checked.svg';

const CopyBlock = ({ refLink }: { refLink: string }) => {
  const theme = useSelector(getCurrentTheme);

  const [isCopied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(refLink);
    setCopied(true);
  };

  useEffect(() => {
    return () => setCopied(true);
  }, []);

  const variants = {
    initial: {
      opacity: 0,
      y: 10,
    },
    animate: {
      opacity: 1,
      y: 0,
    },
    exit: {
      opacity: 0,
      y: -20,
    },
  };

  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <S.CopyBox>
      <S.CopyBlock onClick={handleCopy} $theme={theme}>
        <span>{refLink}</span>
      </S.CopyBlock>
      <S.CopyButton onClick={handleCopy} $theme={theme}>
        <AnimatePresence exitBeforeEnter>
          {isCopied && (
            <motion.div key="copied" style={style} variants={variants} animate={'animate'} initial={'initial'} exit={'exit'}>
              <OkIcon />
            </motion.div>
          )}
          {!isCopied && (
            <motion.div key="uncopied" style={style} variants={variants} animate={'animate'} initial={'initial'} exit={'exit'}>
              <CopyIcon />
            </motion.div>
          )}
        </AnimatePresence>
      </S.CopyButton>
    </S.CopyBox>
  );
};

export default CopyBlock;
