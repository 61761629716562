import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      display: flex;
      align-items: center;
      height: 46px;
      max-height: 46px;
    `
  ),
  Header = styled.div(
    () => css`
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;
      width: 100%;
      margin-bottom: 46px;
      & > * {
        color: white;
      }

      h1 {
        text-align: center;
        font-size: 38px;
        font-weight: 500;
        line-height: 114%;
        letter-spacing: -0.38px;
        margin: 0;
      }
      p {
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 136%;
      }
    `
  );
