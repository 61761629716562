import { useCallback, useEffect, useState } from 'react';
import { useCoursesContext } from '../../context';
import { useDebounce, useGetCourseGroups } from 'hooks';

import Popular from './Popular';
import Groups from '../Groups';
import Group from './Group';
import SearchInput from './SearchInput';
import ResultsList from './ResultsList';
import { MobileModal } from 'shared/ui';

import * as Styled from './styles';

type Props = {
  isOpen: boolean;
  handleClose(): void;
};

const SearchScreen = ({ isOpen = false, handleClose }: Props) => {
  const [input, setInput] = useState('');
  const { handleGroups, activeGroups, selectCourse, courses, displayedCourses, searchForCourses } = useCoursesContext();
  const { groupsNativeNames: groups, tags } = useGetCourseGroups();
  const debounce = useDebounce();

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
    handleInputDeb(e);
  };
  const handleClear = useCallback(() => {
    if (!!input) {
      searchForCourses(null);
    }
    setInput('');
  }, []);

  const handleInputDeb = debounce(searchForCourses, 400);

  useEffect(() => {
    if (!isOpen) {
      handleClear();
    }
  }, [isOpen, handleClear]);

  const handleGroup = (nativeName: string) => handleGroups(tags[nativeName].native);

  return (
    <MobileModal header={'Find your dream course'} isOpen={isOpen} handleClose={handleClose} closeLabel={'Cancel'}>
      <Styled.Input>
        <SearchInput placeholder="Type anything..." value={input} onChange={handleInput} handleClear={handleClear} />
      </Styled.Input>
      {input.length < 1 ? (
        <>
          <Group header="Popular" className="mobile-padding" component={<Popular courses={courses} onClick={selectCourse} />} />
          <Group
            header="Groups"
            className="mobile-padding"
            component={
              <Groups
                groups={groups}
                isCourses
                activeGroups={activeGroups.map((i) => i.label)}
                className="groups"
                handleGroups={handleGroup}
                isSearchIcon={false}
                handleOpen={() => {}}
              />
            }
          />
        </>
      ) : (
        <ResultsList results={displayedCourses} />
      )}
    </MobileModal>
  );
};

export default SearchScreen;
