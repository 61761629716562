import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding-top: 21px;
      background: rgba(29, 29, 39, 0.4);
      backdrop-filter: blur(61.5px);
      display: flex;
      flex-direction: column;
      align-items: center;
    `
  ),
  Device = styled.div(
    () => css`
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .device__screen,
      .device__path {
        position: absolute;
      }

      &.device__ios {
        .device__border {
          width: 277px;
          height: 553px;
        }

        .device__screen {
          width: 241px;
          height: 522px;
        }

        &.screen-1 {
          .device__path {
            left: 105px;
            bottom: 24px;
          }

          ${Hint} {
            left: 103px;
            bottom: 88px;
          }
        }

        &.screen-2 {
          .device__path {
            top: 268px;
            left: 19px;
          }

          ${Hint} {
            top: 328px;
            text-align: center;
          }
        }

        &.screen-3 {
          .device__path {
            top: 48px;
            right: 6px;
          }

          ${Hint} {
            top: 192px;
          }
        }
      }

      &.device__android {
        .device__border {
          width: 270px;
          height: 569px;
        }

        .device__screen {
          width: 231px;
          height: 525px;
          margin-top: 10px;
        }

        &.screen-1 {
          .device__path {
            top: 47px;
            right: 15px;
          }

          ${Hint} {
            top: 83px;
            right: 3px;
          }
        }

        &.screen-2 {
          .device__path {
            top: 369px;
            right: 17px;
          }

          ${Hint} {
            top: 413px;
            right: 10px;
            text-align: center;
          }
        }

        &.screen-3 {
          .device__path {
            top: 289px;
            right: 31px;
          }

          ${Hint} {
            top: 348px;
          }
        }
      }
    `
  ),
  Hint = styled.div(
    () => css`
      border-radius: 8px;
      background: rgba(100, 135, 239, 0.4);
      backdrop-filter: blur(19.5px);
      padding: 5px 10px;

      color: #fff;
      font-size: 18px;
      line-height: 115%;
      letter-spacing: 0.5px;

      display: flex;
      align-items: center;
      gap: 4px;

      position: absolute;
    `
  );
