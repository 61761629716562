import { CourseCardProps } from '../../types';
import { getCurrentTheme } from 'store';
import useGetCourseProgressChapters from 'hooks/useGetCourseProgressChapters';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { CourseCardMenu } from '../../CourseCardMenu';
import MiniTabsProgressBar from 'shared/ui/progress-bar/MiniTabs';
import { LazyImage } from 'shared/ui/lazyImage';

import { ReactComponent as Clock } from 'assets/icons/clock.svg';

import * as Styled from '../styles';
import { useGetItemGetterByKey } from 'hooks';
import { useState } from 'react';

export const CourseCardMiniMobile = ({
  isAnyLoading,
  cover,
  courseData,
  isEditMode,
  isFavourite,
  isInDev,
  isLoading,
  className,
  onClick,
  toggleFav,
  handleImageUpload,
  handleToggleDev,
  handleOpenConfirmationModal,
  handleOpenEditModal,
  handleDuplication,
}: CourseCardProps) => {
  const theme = useSelector(getCurrentTheme);
  const { name, estimatedTime, rewards = [] } = courseData;
  const { completed, total } = useGetCourseProgressChapters({ course: courseData });
  const time = estimatedTime.toString().replace('hours', 'hr').replace('minutes', 'min');
  const getItem = useGetItemGetterByKey();
  const [imgLoaded, setImgLoaded] = useState(false);
  function handleImgLoaded() {
    setImgLoaded(true);
  }
  return (
    <Styled.Container className={clsx(className, theme, 'mobile')}>
      <CourseCardMenu
        handleDuplication={handleDuplication}
        className={'menu'}
        isLoading={isLoading}
        isAnyLoading={isAnyLoading}
        isFavourite={isFavourite}
        isEditMode={isEditMode}
        isInDev={isInDev}
        toggleFav={toggleFav}
        handleToggleDev={handleToggleDev}
        handleOpenConfirmationModal={handleOpenConfirmationModal}
        handleImageUpload={handleImageUpload}
        handleOpenEditModal={handleOpenEditModal}
      />
      <Styled.ImageContainer onClick={onClick} imgLoaded={imgLoaded}>
        <img onLoad={handleImgLoaded} src={cover} alt={`${name} course cover`} />
      </Styled.ImageContainer>
      <Styled.Body onClick={onClick}>
        <Styled.Progress as={MiniTabsProgressBar} tabsFilled={completed} tabsTotal={total} />
        <Styled.Name>{name}</Styled.Name>
        <Styled.Bottom>
          <Styled.Time>
            <Clock />
            {time}
          </Styled.Time>
          <Styled.Rewards>
            {rewards.slice(0, 3).map((rew, index) => {
              const reward = getItem('id', rew.id);

              return <LazyImage key={reward.createdAt.toString() + index} src={reward.imageThumb} alt={reward.name} />;
            })}
          </Styled.Rewards>
        </Styled.Bottom>
      </Styled.Body>
    </Styled.Container>
  );
};
