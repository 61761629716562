import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'app/constants';
import { useEffect } from 'react';

interface IProps {
  isAuth: boolean;
  partnerId?: string;
  redirectPath?: string;
}

export const PartnerRoute = ({ isAuth, partnerId, redirectPath = ROUTES.MAIN }: IProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const currentPartnerId = searchParams.get('partnerID') ?? localStorage.getItem('partnerID');
  const redirectAfterLogin = localStorage.getItem('redirectAfterLogin');

  useEffect(() => {
    // if (isAuth) return;
    if (!searchParams.get('partnerID') && partnerId) {
      searchParams.set('partnerID', partnerId);
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  }, [location, currentPartnerId, partnerId, isAuth]);

  useEffect(() => {
    if (isAuth) {
      // searchParams.set('partnerID', partnerId);
      localStorage.removeItem('redirectAfterLogin');
      navigate(`/space/${currentPartnerId}?${searchParams.toString()}`);
    }
  }, [isAuth]);

  if (isAuth && partnerId && redirectAfterLogin) {
    searchParams.set('partnerID', partnerId);
    localStorage.removeItem('redirectAfterLogin');
    navigate(`/space/${currentPartnerId}?${searchParams.toString()}`);
  }

  if (!currentPartnerId && partnerId) {
    searchParams.set('partnerID', partnerId);
    return <Navigate to={`${location.pathname}?${searchParams.toString()}`} replace />;
  }

  if (!currentPartnerId) {
    return <Navigate to={redirectPath} />;
  }

  return <Outlet />;
};

export const ProtectPartnerRoute = ({ isAuth, partnerId, redirectPath = ROUTES.AUTH }: IProps) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPartnerId = searchParams.get('partnerID') ?? localStorage.getItem('partnerID');
  const navigate = useNavigate();

  if (!currentPartnerId && partnerId) {
    searchParams.set('partnerID', partnerId);
  }

  useEffect(() => {
    if (!searchParams.get('partnerID') && partnerId) {
      searchParams.set('partnerID', partnerId);
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  }, [location, currentPartnerId, partnerId, isAuth]);

  if (!isAuth) {
    localStorage.setItem('redirectAfterLogin', `${location.pathname}${location.search}`);
    return <Navigate to={`${redirectPath}?partnerID=${partnerId}`} />;
  }

  if (!currentPartnerId && partnerId) {
    searchParams.set('partnerID', partnerId);
    return <Navigate to={`${location.pathname}?${searchParams.toString()}`} replace />;
  }

  if (!currentPartnerId) {
    // return <Navigate to={ROUTES.MAIN} />;
  }

  return <Outlet />;
};
