import { useSelector } from 'react-redux';
import { getPageIsLoaded, setPageIsLoaded } from 'store';
import { createPortal } from 'react-dom';
import { useEffect } from 'react';
import { dispatch } from 'hooks';
import SpinLogoLoader from '../SpinLogoLoader';

export const GlobalPageAnimation = () => {
  const pageIsLoaded = useSelector(getPageIsLoaded);

  useEffect(() => {
    let timeout: any;
    if (!pageIsLoaded) {
      timeout = setTimeout(() => {
        return dispatch(setPageIsLoaded(true));
      }, 10000);
    }

    return () => {
      clearInterval(timeout);
    };
  }, [pageIsLoaded]);

  return createPortal(<SpinLogoLoader isFixed isLoading={!pageIsLoaded} />, document.getElementById('loading-portal') as HTMLDivElement);
};
