import { THEMES } from 'app/constants';
import styled from 'styled-components';

export const Container = styled.button<{ active?: boolean; $theme?: THEMES }>`
  display: flex;
  align-items: center;
  font-size: 18px;
  text-align: left;
  text-transform: capitalize;
  color: ${(p) => (p.$theme === THEMES.LIGHT && !p.active ? '#111' : 'white')};
  transition: 0.2s;
  cursor: pointer;
  width: 100%;
  height: 56px;
  min-height: 56px;
  padding: 0px 20px;
  border-radius: 8px;
  background: ${(p) => (p.active ? '#627EEA' : p.$theme === THEMES.LIGHT ? 'rgb(230, 234, 250)' : '#FFFFFF1C')};
  &.gold {
    background: ${(p) => (p.active ? 'rgba(242, 173, 91, 1)' : p.$theme === THEMES.LIGHT ? 'rgb(230, 234, 250)' : '#FFFFFF1C')};
    color: ${(p) => (p.active ? 'var(--basic-light)' : p.$theme === THEMES.LIGHT ? '#111' : 'white')};
  }
`;
