import { useMemo } from 'react';
import * as S from './styles';
import bookIcon from 'assets/icons/book.svg';
import expToken from 'assets/images/exp-token.png';
import { useDisplay } from 'hooks';

const ICONS = [bookIcon, expToken];

type Props = {
  stage: number;
};

const GreeterButtons = ({ stage }: Props) => {
  const { isMobile } = useDisplay();

  const bgStep = isMobile ? 77 : 245;
  const buttonsStep = isMobile ? 121 : 323;

  if (stage > 1) {
    return null;
  }

  return (
    <S.Container>
      <S.BackgroundContainer>
        <S.Background left={0 - stage * bgStep} />
      </S.BackgroundContainer>
      <S.Buttons left={0 - stage * buttonsStep}>
        <S.ButtonContainer />
        {ICONS.map((icon, index) => {
          let isTop = true;

          return (
            <S.ButtonContainer key={'stage-button' + index}>
              <S.Button top={isTop} bottom={!isTop} active={index === stage} subactive={Math.abs(stage - index) === 1}>
                <img src={icon} style={{ width: index === 1 ? '150%' : undefined }} />
              </S.Button>
            </S.ButtonContainer>
          );
        })}
        <S.ButtonContainer />
      </S.Buttons>
    </S.Container>
  );
};

export default GreeterButtons;
