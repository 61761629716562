import styled, { css } from 'styled-components';

export const Container = styled.div<{ width?: string; $isLoading?: boolean }>(
    ({ width = '276px', $isLoading }) => css`
      position: relative;
      width: ${width};
      height: 134px;
      display: flex;
      border-radius: 10px;
      backdrop-filter: var(--standard-blur);
      justify-content: ${$isLoading && 'center'};
    `
  ),
  ImageContainer = styled.div(
    () => css`
      width: 134px;
      min-width: 134px;
      height: 134px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      position: relative;
      border-radius: 10px;

      img {
        object-fit: contain;
        max-height: 100%;
      }
    `
  ),
  Body = styled.div(
    () => css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 16px;
      width: 142px;
      color: var(--font-color-strong);

      &.placeholder {
        width: 100%;
      }
    `
  ),
  CourseName = styled.p<{ fontSize?: string; lineHeight?: string }>(
    ({ fontSize = '18px', lineHeight = '100%' }) => css`
      font-size: ${fontSize};
      font-weight: 500;
      line-height: ${lineHeight};
      text-overflow: ellipsis;
      line-break: anywhere;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      color: inherit;

      &.placeholder {
        line-break: auto;
      }
    `
  ),
  ChooseFromLibrary = styled.span(
    () => css`
      display: flex;
      align-items: flex-end;
      gap: 5px;
      color: var(--basic-blue);
      font-size: 16px;
      line-height: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
    `
  );
