import { QueryClientProvider } from '@tanstack/react-query';
import { createConfig, http, WagmiProvider } from 'wagmi';

import { mainnet } from 'viem/chains';
import { walletConnect, coinbaseWallet, injected } from 'wagmi/connectors';
import type { CreateConnectorFn } from '@wagmi/core';

import { QueryClient } from '@tanstack/react-query';
import { authConnector } from '@web3modal/wagmi';
import { createWeb3Modal } from '@web3modal/wagmi/react';

import { Environment } from 'app/constants';
import { useGetSettings } from 'hooks';

export const useInitEVMAuthModal = () => {
  const { displayedName, logos } = useGetSettings();
  // 0. Setup queryClient
  const queryClient = new QueryClient();

  // 1. Get projectId at https://cloud.walletconnect.com
  const projectId = Environment.WalletConnectProjectId;
  if (!projectId) throw new Error('Project ID is undefined');

  // 2. Create wagmiConfig
  const metadata = {
    name: displayedName,
    description: 'Gamified Educational platform fot incentivised learning, bridging the worlds of education and gaming together.',
    url: window.location.origin, // url must match your domain & subdomain
    icons: [logos.small],
  };
  // Define chains
  const chains = [mainnet] as const;

  // create the connectors
  const connectors: CreateConnectorFn[] = [];
  connectors.push(walletConnect({ projectId, metadata, showQrModal: true }));
  connectors.push(injected({ shimDisconnect: true }));
  connectors.push(
    coinbaseWallet({
      appName: metadata.name,
      appLogoUrl: metadata.icons[0],
    })
  );

  connectors.push(
    authConnector({
      options: { projectId },
      walletFeatures: false,
    })
  );

  const wagmiConfig = createConfig({
    chains, // Use the defined chains here
    transports: {
      [mainnet.id]: http(),
    },
    connectors: connectors,
  });

  createWeb3Modal({ wagmiConfig, projectId });
  return { wagmiConfig, queryClient };
};

export const WagmiWrapper = ({ children }: { children: React.ReactNode }) => {
  const { wagmiConfig, queryClient } = useInitEVMAuthModal();

  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient} children={children} />
    </WagmiProvider>
  );
};
