import { useCallback, useEffect, useState } from 'react';
import './styles.css';
import { useGetCurrentTheme } from 'hooks';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { getPartnerId, useLazyGetPartnerLogoQuery } from 'store';
import { Arc } from './arcs';
import mightyLogo from 'assets/logos/loader-logo.png';

type Props = {
  size?: number;
  isFixed?: boolean;
  isLoading?: boolean;
};

const SpinLogoLoader = ({ size = 200, isFixed, isLoading }: Props) => {
  const theme = useGetCurrentTheme();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const partnerId = useSelector(getPartnerId);
  const [getPartnerLogo, { isLoading: logoLoading }] = useLazyGetPartnerLogoQuery();
  const [partnerLogoUrl, setPartnerLogoUrl] = useState<string | null>(null);
  const [isLogoLoaded, setIsLogoLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsLogoLoaded(true);
  };

  useEffect(() => {
    // setTimeout(() => {
    // }, 2000);
    setIsPlaying(true);

    return () => {
      setIsPlaying(false);
      setIsClosing(false);
      setIsClosed(false);
    };
  }, []);

  useEffect(() => {
    if (!!partnerId) {
      getPartnerLogo(partnerId)
        .unwrap()
        .then((res) => {
          setPartnerLogoUrl(res);
        })
        .catch(console.error);
    }
  }, [partnerId]);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => requestAnimationFrame(() => setIsClosing(true)), 5000);
    }
  }, [isLoading]);

  const handleAnimationEnd = useCallback(() => {
    if (isClosing) {
      requestAnimationFrame(() => setIsClosed(true));
    }
  }, [isClosing]);

  useEffect(() => {
    document.documentElement.style.overflowY = 'hidden';

    return () => {
      document.documentElement.style.overflowY = 'unset';
    };
  }, []);

  if (isClosed) {
    return null;
  }

  console.log({ isLoading, isClosing, isClosed });

  return (
    <motion.div
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      id="logo-loader"
      className={`container ${theme} ${isClosing || isClosed ? 'closing' : null}`}
      style={{ position: isFixed ? 'fixed' : 'absolute' }}
      onAnimationEnd={handleAnimationEnd}
    >
      <div className={`animation center-positioned`} style={{ width: size, height: size, transition: 'opacity 0.3s', opacity: isLogoLoaded ? 1 : 0 }}>
        <div className={`logo-container ${isPlaying ? 'playing' : ''}`}>
          <img src={!!partnerId ? partnerLogoUrl || '' : mightyLogo} onLoad={handleImageLoad} className="partner-logo" />
        </div>
      </div>
      <div
        className={`bg-round center-positioned ${isPlaying ? 'playing' : ''} ${theme}`}
        style={{
          width: size * 1.2,
          height: size * 1.2,
          // borderWidth: size * 0.17,
        }}
      >
        <div className="border-box center-positioned">
          <Arc isPlaying={isPlaying} theme={theme} index={1} />
        </div>
        <div className="border-box center-positioned">
          <Arc isPlaying={isPlaying} theme={theme} index={2} />
        </div>
        <div className="border-box center-positioned">
          <Arc isPlaying={isPlaying} theme={theme} index={3} />
        </div>
      </div>
    </motion.div>
  );
};

export default SpinLogoLoader;
