enum EEnvs {
  GoogleClientId = 'REACT_APP_GOOGLE_CLIENT_ID',
  TelegramBotName = 'REACT_APP_TELEGRAM_BOT_NAME',
  TelegramBotLink = 'REACT_APP_MINIAPP_BOT_LINK',
  TelegramMiniAppName = 'REACT_APP_TELEGRAM_MINIAPP_NAME',
  WalletConnectProjectId = 'REACT_APP_WALLET_CONNECT_PROJECT_ID',
  StaticHostPath = 'REACT_APP_STATIC_HOST',
}

class RequiredEnvNotDefinedError extends Error {}

function parseEnv(name: EEnvs, required = true) {
  const value = process.env[name];
  if (!value && required) {
    throw new RequiredEnvNotDefinedError(name);
  }

  return value ?? null;
}

const TelegramBotName = parseEnv(EEnvs.TelegramBotName);
const TelegramMiniAppName = parseEnv(EEnvs.TelegramMiniAppName);
const GoogleClientId = parseEnv(EEnvs.GoogleClientId);
const TelegramBotLink = parseEnv(EEnvs.TelegramBotLink);
const WalletConnectProjectId = parseEnv(EEnvs.WalletConnectProjectId);
const StaticHostPath = parseEnv(EEnvs.StaticHostPath);

export const Environment = {
  TelegramBotName: TelegramBotName,
  TelegramMiniAppName: TelegramMiniAppName,
  GoogleClientId: GoogleClientId,
  TelegramBotLink: TelegramBotLink,
  WalletConnectProjectId: WalletConnectProjectId,
  StaticHostPath: StaticHostPath,
};
