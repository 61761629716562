import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      display: flex;
      flex-direction: column;
      margin-bottom: 36px;

      &.empty {
        margin-bottom: 29px;
        gap: 0;
      }
      &.mobile {
        margin-bottom: 25px;
        &.empty {
          margin-bottom: 12px;
        }
      }
      transition: margin 0.1s;
    `
  ),
  Courses = styled(motion.div)(
    () => css`
      display: flex;
      flex-wrap: wrap;
      gap: 14px;
      overflow: hidden;

      .inview {
        height: fit-content;
        z-index: 1;
        &:hover {
          z-index: 2;
        }
      }

      &.mobile {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        padding: 0 17px;
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    `
  ),
  Toggle = styled.div(
    () => css`
      transition: margin 0.3s;
      margin-bottom: 24px;
      &.mobile {
        margin: 0 17px;
      }
      &.empty {
        margin-bottom: 0;
      }
    `
  ),
  Loader = styled.div(
    () => css`
      /* width: 180px;
      min-width: 180px;
      height: 180px; */
    `
  );
