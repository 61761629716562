import styled from 'styled-components';
import { motion } from 'framer-motion';
import { IGeneralProps } from './types';
import { UIParent } from './parent';

interface IProps extends IGeneralProps {
  flexDirection?: 'row' | 'column';
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';
  alignItems?: 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'stretch';
  gap?: string;
  isMotion?: boolean;
}

export const Flex = styled(UIParent).attrs<IProps>(({ as = 'div' }) => ({ as }))<IProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  gap: ${({ gap }) => gap || '0'};
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '0'};
`;

export const MotionFlex = motion(Flex);

export const FlexContaner = styled(Flex)`
  &:empty {
    display: none;
  }
`;

export const MotionFlexContaner = motion(FlexContaner);
