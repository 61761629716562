import { ReactNode } from 'react';

import { ReactComponent as Share } from '../../../assets/icons/share.svg';
import { ReactComponent as Dots } from '../../../assets/icons/dots.svg';

import device from '../assets/ios/device.png';
import screen1 from '../assets/ios/screen-1.png';
import screen2 from '../assets/ios/screen-2.png';
import screen3 from '../assets/ios/screen-3.png';

import androidDevice from '../assets/android/device.png';
import androidScreen1 from '../assets/android/screen-1.png';
import androidScreen2 from '../assets/android/screen-2.png';
import androidScreen3 from '../assets/android/screen-3.png';

interface IData {
  device: string;
  screens: string[];
  paths: { width: string; height: string; viewBox: string; d: string }[];
  hints: ReactNode[];
}

export const data: Record<string, IData> = {
  ios: {
    device: device,
    screens: [screen1, screen2, screen3],
    paths: [
      {
        width: '67',
        height: '52',
        viewBox: '0 0 67 52',
        d: 'M57.1894 3.28082C-11.8818 1.53165 -10.8206 49.9107 30.0949 49.9107C75.7867 49.9107 86.0639 2.48438 4.45112 2.48438',
      },
      {
        width: '235',
        height: '57',
        viewBox: '0 0 235 57',
        d: 'M208.759 2.67293C-48.5819 0.713414 -44.6281 54.9104 107.812 54.9104C278.048 54.9104 289.143 -1.64812 45.1885 2.67308',
      },
      {
        width: '63',
        height: '39',
        viewBox: '0 0 63 39',
        d: 'M54.741 2.79356C-10.9359 1.52071 -9.92685 36.7256 28.978 36.7256C72.4245 36.7256 75.2561 -0.0132832 12.9956 2.79365',
      },
    ],
    hints: [
      <>
        Tap <Share width={16} height={16} />
      </>,
      <>
        Click <br />
        “Add to Home Screen”
      </>,
      'Click “Add”',
    ],
  },
  android: {
    device: androidDevice,
    screens: [androidScreen1, androidScreen2, androidScreen3],
    paths: [
      {
        width: '38',
        height: '29',
        viewBox: '0 0 38 29',
        d: 'M31.373 2.41161C-5.00097 1.49047 -4.44213 26.9677 17.1046 26.9677C41.1667 26.9677 46.5788 1.99219 3.60021 1.99219',
      },
      {
        width: '189',
        height: '38',
        viewBox: '0 0 189 38',
        d: 'M167.637 2.09944C-38.4511 0.816654 -35.2848 36.2962 86.7948 36.2962C223.126 36.2962 232.011 -0.729306 36.6435 2.09953',
      },
      {
        width: '63',
        height: '38',
        viewBox: '0 0 63 38',
        d: 'M54.741 1.88731C-10.9359 0.614456 -9.92685 35.8193 28.978 35.8193C72.4245 35.8193 75.2561 -0.919533 12.9956 1.8874',
      },
    ],
    hints: [
      <>
        Tap{' '}
        <div style={{ padding: '1px 4px' }}>
          <Dots width={4} height={15} />
        </div>
      </>,
      <>
        Click <br />
        “Add to Home Screen”
      </>,
      'Click “Add”',
    ],
  },
};
