import { WalletAuthLinkIcon } from 'assets/icons/icons';
import { TemplateButton } from '../authButtons';
import { useEffect } from 'react';
import { useEVMAuth } from 'hooks';

export const WalletAuth = () => {
  const { openModal, handleAuthentification, handleLogin, authData, address, isConnected } = useEVMAuth();

  useEffect(() => {
    if (isConnected && address) {
      handleAuthentification();
    }
  }, [isConnected, address]);

  useEffect(() => {
    if (authData) {
      handleLogin();
    }
  }, [authData]);

  return <TemplateButton icon={<Icon />} title="Connect Wallet" variant="walletAuth" onClick={() => openModal()} />;
};

const Icon = () => (
  <span
    style={{
      color: 'var(--mighty-blue)',
      height: '36px',
      width: '36px',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '30px',
    }}
  >
    <WalletAuthLinkIcon />
  </span>
);
