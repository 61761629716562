import { GRADES, USER_ROLES } from 'app/constants';
import { useGetItems, useGetUserData } from 'hooks';
import { IDatabaseItem } from 'interface';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { ESpaceRoles, ISpace, useGetSpaceRewardsQuery, useLazyGetSpacesQuery } from 'store';

export const useGetUserRelatedSpaceInfo = (space: ISpace | null) => {
  const user = useGetUserData();

  const isMightyAdmin = useMemo(() => {
    if (!user || !user.roles) {
      return false;
    }
    return !!user.roles.includes(USER_ROLES.ADMIN);
  }, [user]);

  const isSpaceAdminOrOwner = useMemo(() => {
    if (!space) {
      return false;
    }
    const spaceUser = !!user && !!space ? space.users.find((el) => el.userId._id === user._id) : null;
    if (spaceUser && spaceUser.roles.includes(ESpaceRoles.OWNER)) {
      return true;
    }
    if (spaceUser && spaceUser.roles.includes(ESpaceRoles.ADMIN)) {
      return true;
    }

    return false;
  }, [space, user]);

  const isAbleToEdit = useMemo(() => isMightyAdmin || isSpaceAdminOrOwner, [isMightyAdmin, isSpaceAdminOrOwner]);

  return { isAbleToEdit };
};

export const useGetSpaceRewards = (spaceId: string, skip?: boolean) => {
  const items = useGetItems();
  const { data: spaceRewards, isLoading: isLoadingRewards } = useGetSpaceRewardsQuery(spaceId, { skip });
  const [rewards, setRewards] = useState<IDatabaseItem[]>([]);
  const commonItems = useMemo(() => items.filter((el) => el.grade === GRADES.COMMON && el.imageThumb && el.type === 'fungible'), [items]);

  const nonInRewardsItems = useMemo(() => {
    if (rewards.length < 6) {
      return commonItems.filter((item) => !rewards.find((rew) => rew.id === item.id));
    }
    return [];
  }, [rewards, commonItems]);

  useEffect(() => {
    if (spaceRewards) {
      const newRewards = spaceRewards.items.map((rew) => items.find((i) => i.id === rew.id)).filter((el) => !!el) as IDatabaseItem[];
      setRewards(newRewards);
    }
    return () => {
      setRewards([]);
    };
  }, [spaceRewards]);

  return { rewards, isLoadingRewards, restCommonItems: nonInRewardsItems, total: [...rewards, ...nonInRewardsItems] };
};

interface IState {
  spaces: ISpace[];
  page: number;
  total: number;
  isInitiated: boolean;
  isEmptyResponse: boolean;
}

export const useGetSpaces = (verified = true, limit = 3) => {
  const [{ spaces, page, isInitiated, total, isEmptyResponse }, updateState] = useReducer(
    (prev: IState, next: Partial<IState>) => ({ ...prev, ...next }),
    {
      spaces: [],
      page: 1,
      isInitiated: false,
      total: 0,
      isEmptyResponse: false,
    }
  );

  const [fetch, { isFetching, isLoading }] = useLazyGetSpacesQuery();
  const initiate = () => updateState({ isInitiated: true });

  const isFinished = (spaces.length >= total && total !== 0) || isEmptyResponse;

  useEffect(() => {
    initiate();
  }, []);

  useEffect(() => {
    if (isInitiated && !isFinished && !isFetching) {
      fetch({ page, verified, limit })
        .unwrap()
        .then((res) => {
          if (res.total === 0) {
            updateState({ isEmptyResponse: true });
            return;
          }
          updateState({
            isInitiated: false,
            spaces: [...spaces, ...res.items],
            total: res.total,
            page: page + 1,
          });
        })
        .catch(console.error)
        .finally(() => {
          updateState({ isInitiated: false });
        });
    }
  }, [isInitiated, isFinished]);

  return { spaces, isFetching, initiate, isLoading, isFinished };
};
