import { THEMES } from 'app/constants';
import styled from 'styled-components';

export const Title = styled.p<{ $theme: THEMES }>`
  font-size: 30px;
  line-height: 34.2px;
  text-align: center;
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#111' : 'white')};
`;

export const Blue = styled.span`
  background: linear-gradient(90deg, #6487ef 0%, #53259b 125.56%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
`;

export const Sub = styled.p<{ $theme: THEMES; $isHorizontal?: boolean }>`
  text-align: center;
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#111' : p.$isHorizontal ? 'whtie' : '#AFB4B8')};
  font-size: 16px;
  line-height: 21.12px;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerVertical = styled.div<{ $theme: THEMES }>`
  width: 100px;
  height: 150px;
  border-radius: 10px;
  background: ${(p) =>
    p.$theme === THEMES.LIGHT ? '#d8e0f9' : 'linear-gradient(76.31deg, rgba(50, 53, 71, 0.472) 5.23%, rgba(40, 42, 57, 0.8) 107.31%)'};
  box-shadow: 103px 187px 86px rgba(28, 29, 38, 0.01), 58px 105px 72px rgba(28, 29, 38, 0.05), 26px 47px 53px rgba(28, 29, 38, 0.09),
    6px 12px 29px rgba(28, 29, 38, 0.1), 0px 0px 0px rgba(28, 29, 38, 0.1);
  backdrop-filter: blur(130px);
  border-radius: 8px;
  width: 350px;
  height: 530px;
  position: relative;
  padding: 78px 32px 29px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > .chest {
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
  & > ${Info} {
    max-width: 270px;
    margin-bottom: 19px;
    & > ${Title} {
      margin-bottom: 16px;
    }
  }
  & > .refs {
    margin-bottom: auto;
  }
  & > .refs-banner-button {
    height: 58px;
  }
`;

export const ContainerHorizontal = styled.div<{ $theme: THEMES }>`
  width: 100%;
  height: 150px;
  padding: 32px 32px 32px 258px;
  display: flex;
  align-items: flex-end;
  background: ${(p) =>
    p.$theme === THEMES.LIGHT ? '#d8e0f9' : 'linear-gradient(76.31deg, rgba(50, 53, 71, 0.472) 5.23%, rgba(40, 42, 57, 0.8) 107.31%)'};
  justify-content: space-between;
  border-radius: 10px;
  & > .chest {
    position: absolute;
    left: 20px;
    top: 38%;
    transform: translateY(-50%);
  }
  & > ${Info} {
    height: 100%;
    justify-content: space-between;
    align-items: flex-start;
    & > ${Title} {
      text-align: left;
      font-size: 28px;
      line-height: 100%;
    }
    & > ${Sub} {
      text-align: left;
      max-width: 410px;
    }
    & br {
      display: none;
    }
  }
`;

export const MobileSub = styled.p`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: auto;
  line-height: 22.32px;
`;

export const MobileTitle = styled.p<{ $theme: THEMES }>`
  display: flex;
  align-items: center;
  gap: 6px;
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#111' : 'white')};
  & > .logo {
    width: 17px;
    height: 17px;
  }
`;

export const MobileContainer = styled.div<{ $theme: THEMES }>`
  display: flex;
  align-items: center;
  height: 100%;

  & > .chest {
    margin: -24px 0px 0px -14px;
    min-width: 150px;
  }
  & ${MobileSub} {
    color: ${(p) => (p.$theme === THEMES.LIGHT ? '#111' : 'white')};
  }
`;
export const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
