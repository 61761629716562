import { appApi, profileApi, shopApi } from 'store';
import { ErrorHandler } from 'shared/utils/ErrorHandler';
import { defer } from 'react-router-dom';

export const mainLoader = (store, dispatch) => async () => {
  const isAuth = store.getState().auth.isAuth;

  const itemsFromLoader = dispatch(shopApi.endpoints?.fetchItems.initiate());
  const settingsFromLoader = dispatch(appApi.endpoints?.getSettings.initiate());

  let meFromLoader;

  if (isAuth) {
    meFromLoader = dispatch(profileApi.endpoints?.getUserData.initiate());
  }

  try {
    const [itemsResponse] = await Promise.all([itemsFromLoader.unwrap()]);
    const obj = {
      itemsFromLoader: itemsResponse,
    };
    return defer(obj);
  } catch (e) {
    console.error(e);
    return ErrorHandler(e);
  } finally {
    if (meFromLoader) {
      meFromLoader.unsubscribe();
    }
    itemsFromLoader.unsubscribe();
    settingsFromLoader.unsubscribe();
  }
};
