import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import * as S from './styles';
import { ICourseFilteringGroup } from '../../hooks';
import { useGroupImage } from '../../group-cards/useGroupImage';
import { MouseEventHandler, useMemo, useRef } from 'react';
import { useAverageColor, useDisplay } from 'hooks';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import { THEMES } from 'app/constants';
import { GroupImage } from '../image';

type Props = {
  group: ICourseFilteringGroup;
  isOpen: boolean;
  isLoading: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
  className?: string;
};

export const Toggle = ({ isOpen, group, onClick, isLoading, className = '' }: Props) => {
  const image = useGroupImage(group.native);
  const theme = useSelector(getCurrentTheme);
  const { isMobile } = useDisplay();
  const imageRef = useRef<HTMLImageElement>(null);
  const baseColor = useAverageColor(imageRef).replace(/(rgba\(\d+,\d+,\d+,).*\)/, '$1 0.1)');

  const baseColorLight = useMemo(() => baseColor.replace('0.1', '0.5'), [baseColor]);

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isLoading) onClick(e);
  };

  return (
    <S.Container $theme={theme} role="button" onClick={handleOnClick} className={clsx({ [className]: !!className, mobile: isMobile, open: isOpen })}>
      <GroupImage group={group} size={isMobile ? 41 : 59} ref={imageRef} />
      <S.BG style={{ background: theme === THEMES.DARK ? baseColor : baseColorLight }} />
      <S.Title $theme={theme}>{group.label}</S.Title>
      <S.Chevron>{isOpen ? <BsChevronUp /> : <BsChevronDown />}</S.Chevron>
    </S.Container>
  );
};
