import { FC, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { wrap } from 'popmotion';
import clsx from 'clsx';

import { data } from './data/data';
import { screenVariants, hintVariants, pathVariants } from './motion/variants';

import { HelpText } from '../../styled';
import * as Styled from './styled';

interface IHelpProps {
  os: string;
  show: boolean;
  onClose: () => void;
}

export const Help: FC<IHelpProps> = ({ os, show, onClose }) => {
  const [currentScreen, setCurrentScreen] = useState(0);
  const screenIndex = wrap(0, 3, currentScreen);
  const currentOS = os.toLowerCase();

  useEffect(() => {
    if (!show || currentScreen >= 2) return;

    const timeoutId = setTimeout(() => setCurrentScreen((prevState) => prevState + 1), 2000);

    return () => clearTimeout(timeoutId);
  }, [currentScreen, show]);

  const handleClose = () => {
    onClose();
    setCurrentScreen(0);
  };

  return (
    <AnimatePresence>
      {show && (
        <Styled.Container as={motion.div} initial={{ y: '-100%', opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: '100%', opacity: 0 }}>
          <Styled.Device className={clsx(`device__${currentOS} screen-${currentScreen + 1}`)}>
            <img src={data[currentOS].device} alt={'device'} className={`device__border`} />
            <AnimatePresence>
              <motion.img
                key={currentScreen}
                src={data[currentOS].screens[screenIndex]}
                alt={'screen'}
                className={'device__screen'}
                variants={screenVariants}
                initial="enter"
                animate="center"
                exit="exit"
              />
            </AnimatePresence>
            <motion.svg
              xmlns="http://www.w3.org/2000/svg"
              width={data[currentOS].paths[currentScreen].width}
              height={data[currentOS].paths[currentScreen].height}
              viewBox={data[currentOS].paths[currentScreen].viewBox}
              fill="none"
              className={'device__path'}
            >
              <motion.path
                key={currentScreen}
                variants={pathVariants}
                initial={'initial'}
                animate={'animate'}
                d={data[currentOS].paths[currentScreen].d}
                stroke="#6487EF"
                strokeWidth="3.40152"
                strokeLinecap="round"
              />
            </motion.svg>
            <Styled.Hint as={motion.div} key={currentScreen} variants={hintVariants} initial={'initial'} animate={'animate'}>
              {data[currentOS].hints[currentScreen]}
            </Styled.Hint>
          </Styled.Device>
          <HelpText onClick={handleClose} $margin={'19px 0 0 0'}>
            Skip
          </HelpText>
        </Styled.Container>
      )}
    </AnimatePresence>
  );
};
