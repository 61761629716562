import { AnimatePresence } from 'framer-motion';
import { Await, Outlet, useLoaderData } from 'react-router-dom';
import { useAppUiContext } from 'context/AppUiContext';
import { useDisplay, useGetIsAuth, useGetPendingFriendRequestsAsToasts, useNavigateUnauthUser } from 'hooks';
import useOverwriteThemeByPathname from 'hooks/useOverwriteThemeByPathname';

import Sidebar from 'components/library/sidebar/Sidebar';
import { ContainerMain } from 'components/mainElements';
import FriendFinderMain from 'components/library/friends-finder/finder';
import { Navbar } from 'components/library/navbar';
import { PageWrapper } from 'components/pages/pageWrapper/PageWrapper';
import { ShopItemsController } from 'components/widgets/ShopItemsController';
import Footer from 'shared/ui/footer/footer';
import MobileNavbar from 'shared/utils/mobile/mobile-navbar/mobile-navbar';
import OnetimeNotifications from 'shared/ui/onetime-notifications';
import { TitleTransitionBar } from 'shared/utils/mobile';
import { PageBackground } from 'components/shared/PageBackground/PageBackground';
import { useHandleMiniAppStartParam, usePreventMiniappCollapse } from 'shared/utils';
import { getPartnerId } from 'store';
import { useSelector } from 'react-redux';

const Main = () => {
  const { isMobile } = useDisplay();
  const { itemsFromLoader }: any = useLoaderData();
  const isAuth = useGetIsAuth();
  const { bodyWithControls } = useAppUiContext();
  const partnerId = useSelector(getPartnerId);

  useGetPendingFriendRequestsAsToasts();
  useOverwriteThemeByPathname();
  useNavigateUnauthUser();
  useHandleMiniAppStartParam();
  usePreventMiniappCollapse();

  return (
    <Await resolve={itemsFromLoader}>
      <PageWrapper>
        <ContainerMain id="App" className={`App ${partnerId == null ? '' : 'partner'}`}>
          <PageBackground />
          <TitleTransitionBar />
          {!isMobile && <Navbar />}
          {!partnerId && <MobileNavbar />}
          {!isMobile && bodyWithControls && <Sidebar />}
          <ShopItemsController />
          {isAuth && <OnetimeNotifications />}
          <AnimatePresence>
            <Outlet />
          </AnimatePresence>
          {!isMobile && <FriendFinderMain />}
          {bodyWithControls && <Footer />}
        </ContainerMain>
      </PageWrapper>
    </Await>
  );
};

export default Main;
