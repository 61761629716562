import { useState } from 'react';
import { IOnetimerComponent } from 'shared/ui/onetime-notifications/types';
import { PageBackground as PBG, Wrapper } from '../interests-picker/styles';
import { Greeting } from './components';
import { InterestsPicker } from '../interests-picker';
import { PageBackground } from 'components/shared/PageBackground/PageBackground';
import { AnimatePresence } from 'framer-motion';
import GreeterButtons from './components/stageAnim';
import { useDisplay } from 'hooks';

export const FirstTimer = ({ onClose }: IOnetimerComponent) => {
  const [isPicker, setIsPicker] = useState<boolean>(true);
  const { isMobile } = useDisplay();

  return (
    <Wrapper>
      {isMobile && <GreeterButtons stage={isPicker ? 0 : 1} />}

      <AnimatePresence exitBeforeEnter>
        {isPicker ? <InterestsPicker isBackButton={false} onClose={onClose} onSubmit={() => setIsPicker(false)} /> : <Greeting onClose={onClose} />}
      </AnimatePresence>
      <PBG as={PageBackground} bg="space" />
      {!isMobile && <GreeterButtons stage={isPicker ? 0 : 1} />}
    </Wrapper>
  );
};
