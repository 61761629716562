import { CHESTS_WEIGHT, EChestRewardRarity } from 'app/constants';
import { useGetShopItems } from 'components/pages/shop/hooks';
import { EChestFungibleType, EGradeNumber, EItemFungibleType, IChest, IDatabaseItemQuantified } from 'interface';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getItems, useGetChestsQuery } from 'store';

export const useGetChests = (chestType?: EChestFungibleType) => {
  const [state, setState] = useState<{ chests: IChest[]; chest?: IChest | null }>({
    chests: [],
    chest: null,
  });
  const { data: chestsRaw, refetch, isLoading } = useGetChestsQuery();
  const items = useSelector(getItems);
  const { items: shopItems } = useGetShopItems({ page: 1, limit: 100 });

  useEffect(() => {
    refetch()
      .unwrap()
      .then(() => {
        if (chestsRaw) {
          const chests: IChest[] = items
            .filter((item) => item.fungibleType && Object.values(EChestFungibleType).includes(item.fungibleType as unknown as EChestFungibleType))
            .filter((item) => shopItems.some((shopItem) => shopItem.item.id === item.id))
            .map((item) => {
              const chestFound = chestsRaw.find((chest) => chest.fungibleType === (item.fungibleType as unknown as EChestFungibleType))!;

              const chestFromShop = shopItems.find(
                (shopItem) => (shopItem.item.fungibleType as unknown as EChestFungibleType) === chestFound.fungibleType
              )!;
              const { mainRewardsTypes, rewardsTypes } = getRewardsTypes(chestFound.fungibleType);

              const rewards: IDatabaseItemQuantified[] = [];
              rewardsTypes.forEach((type) => {
                const item = items.find((i) => i.fungibleType === type);
                if (item) {
                  rewards.push({ ...item, quantity: shopItems.find((shopItem) => shopItem.item.id === item.id)?.quantity ?? 1 });
                }
              });
              const mainRewards: IDatabaseItemQuantified[] = [];
              mainRewardsTypes.forEach((type) => {
                const item = items.find((i) => i.fungibleType === type);
                if (item) {
                  mainRewards.push({ ...item, quantity: shopItems.find((shopItem) => shopItem.item.id === item.id)?.quantity ?? 1 });
                }
              });

              const chest: IChest = {
                ...item,
                details: chestFound.details,
                fungibleType: chestFound.fungibleType,
                quantity: chestFromShop.quantity,
                image: chestFound.image,
                imageLarge: chestFound.imageLarge,
                backgroundImage: chestFound.backgroundImage,
                title: item.name,
                price: chestFromShop.price,
                rewards: rewards,
                mainRewards: mainRewards,
                useOnlyIf: chestFound.useOnlyIf,
                goodsId: chestFromShop.id,
              };
              return chest;
            });
          const chest = chests.find((chest) => chest.fungibleType === chestType) ?? null;
          const chestsSorted = chests.sort(
            (a, b) =>
              EGradeNumber[a.grade.toUpperCase() as keyof typeof EGradeNumber] - EGradeNumber[b.grade.toUpperCase() as keyof typeof EGradeNumber]
          );

          setState({ chests: chestsSorted, chest });
        }
      });
    return () => {
      setState({ chests: [], chest: null });
    };
  }, [chestType, chestsRaw, items, refetch, shopItems]);

  return { ...state, isLoading };
};

const getRewardsTypes = (fungibleType: EChestFungibleType) => {
  const chest = CHESTS_WEIGHT[fungibleType];

  if (!chest) {
    return { rewardsTypes: [], mainRewardsTypes: [] };
  }

  const chestKeys = Object.keys(chest) as EItemFungibleType[];
  const raritiesKeys = Object.keys(EChestRewardRarity) as EChestRewardRarity[];

  const chestSorted = chestKeys.sort((a, b) => {
    const rarityA = chest[a]!;
    const rarityB = chest[b]!;
    const indexA = raritiesKeys.indexOf(rarityA);
    const indexB = raritiesKeys.indexOf(rarityB);
    return indexA - indexB;
  });
  const rewardsTypes: EItemFungibleType[] = chestSorted.slice(4) as EItemFungibleType[];
  const mainRewardsTypes: EItemFungibleType[] = chestSorted.slice(0, 4).reverse() as EItemFungibleType[];

  return { rewardsTypes, mainRewardsTypes };
};
