import { BasicButtonV1, Flex, Modal } from 'shared/ui';
import styles from './styles.module.scss';
import ChainAnimation from './animation';
import { useState } from 'react';
import RoundSVG from './round';
import { EAuthMethods } from 'interface';
import { LINK_AUTH_METHODS } from '../types';
import { ReactComponent as MightyLogo } from 'assets/logos/logo.svg';
import { toast } from 'react-toastify';
import Answer from 'components/library/messages/Answer';
import { useEVMAuth, useUnattachUser } from 'hooks';
import { IconContainer } from './IconContainer';

type Props = {
  selectedMethod: EAuthMethods;
  clearMethod: () => void;
};

const UnlinkModal = ({ selectedMethod, clearMethod }: Props) => {
  const [stage, setStage] = useState<'initial' | 'progress' | 'error' | 'success'>('progress');
  const { handleUnattach: unlink, isLoading } = useUnattachUser({ method: selectedMethod });
  const { handleDisconnect } = useEVMAuth();

  const handleClose = () => {
    setStage('success');
    setTimeout(() => {
      clearMethod();
    }, 1000);
  };

  const handleUnlink = () => {
    setStage('progress');
    if (selectedMethod) {
      unlink()
        .then(() => {
          if (selectedMethod === EAuthMethods.EVM) {
            handleDisconnect();
          }
          handleClose();
        })
        .catch((ex) => {
          toast.error(<Answer label={'Oops'} subtext={ex?.data?.message || 'Failed to unlink'} />);
          setStage('error');
        });
    }
  };

  const { icon, title: methodTitle } = LINK_AUTH_METHODS.find((el) => el.method === selectedMethod) || {};

  return (
    <Modal
      className={styles.modal}
      containerClassName={styles.modalContainer}
      onClose={handleClose}
      open={!!selectedMethod}
      closeButtonLocation="outside"
    >
      <Flex flexDirection="column" alignItems="center" justifyContent="space-between" className={styles.container}>
        <p className={styles.title}>Unlink {methodTitle || ''}?</p>
        <IconContainer className={styles.box}>
          <IconContainer className={styles.iconBox}>
            <RoundSVG stage={stage} />
            <IconContainer className={styles.iconFrame}>
              <MightyLogo className={styles.logo} />
            </IconContainer>
          </IconContainer>
          <ChainAnimation blinking={false} stage={stage} />
          <IconContainer className={styles.iconBox}>
            <RoundSVG stage={stage} />
            <IconContainer className={styles.iconFrame}>{!!icon && icon}</IconContainer>
          </IconContainer>
        </IconContainer>

        <Flex justifyContent="center" className={styles.buttons}>
          <BasicButtonV1 variant="solid" onClick={handleClose} className={`${styles.button} ${styles.cancel}`}>
            Cancel
          </BasicButtonV1>
          <BasicButtonV1
            variant="primary"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={handleUnlink}
            className={`${styles.button} ${styles.unlink}`}
          >
            Unlink
          </BasicButtonV1>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default UnlinkModal;
