import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import { useAverageColor } from 'hooks';
import { AnimatePresence } from 'framer-motion';
import { ICourseFilteringGroup } from '../../hooks';
import { useMemo, useRef } from 'react';
import { GroupImage } from '../../group/image';
import { THEMES } from 'app/constants';

import * as Styled from './styles';

interface IProps {
  group: ICourseFilteringGroup;
  isActive: boolean;
  onClick: () => void;
  className?: string;
}

export default function GroupCard({ group: { label, native }, isActive, onClick, className = '' }: IProps) {
  const theme = useSelector(getCurrentTheme);
  const imageRef = useRef<HTMLImageElement>(null);

  const baseColor = useAverageColor(imageRef);
  const borderColor = baseColor.replace(/(rgba\(\d+,\d+,\d+,).*\)/, '$1 0.7)');
  const color = baseColor ? baseColor.replace(/(rgba\(\d+,\d+,\d+,).*\)/, '$1 0.1)') : 'rgba(100, 100, 100 ,0.1)';

  const colorLight = useMemo(() => color.replace('0.1', '0.3'), [color]);

  return (
    <Styled.Container
      className={clsx(theme, { active: isActive, [className]: !!className })}
      onClick={onClick}
      role="button"
      style={{ background: theme === THEMES.DARK ? color : colorLight }}
    >
      <GroupImage group={{ label, native }} ref={imageRef} animate={false} size={42} />
      <Styled.Title>{label}</Styled.Title>
      <AnimatePresence>
        {isActive && (
          <Styled.Frame
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className={clsx({ active: isActive }, theme)}
            style={{ boxShadow: `rgb(51 0 255 / 31%) -2px -2px 7px 2px inset, 0px 0px 0 2px inset ${borderColor}` }}
          />
        )}
      </AnimatePresence>
    </Styled.Container>
  );
}
