import { lazy, memo, Suspense, useMemo } from 'react';
import { ROUTES, USER_ROLES } from 'app/constants';
import { createBrowserRouter, Navigate, RouteObject, RouterProvider } from 'react-router-dom';
import { getPartnerId, store } from 'store';
import { dispatch, useGetIsAuth, useGetUserData } from 'hooks';
import { suspenseLoader } from './suspenseLoader';

import { ProtectedRoute, SpecificRoleRoute } from 'shared/routes';
import { PublicRoute } from 'shared/routes/PublicRoute';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';
import { PageLoader } from 'shared/utils';

import { AuthPage, LogInPage, ResetPasswordPage, SignUpPage } from './auth';
import { otherUserProfileLoader } from 'components/pages/profile/profile';
import { UserActivationCodeChecker } from 'shared/ui';
import userProfileLoader from '../components/pages/profile/profile/utils/userProfileLoader';
import leaderboardLoader from './leaderboard/utils/leaderboardLoader';
import { meditationLoader } from 'components/pages/play/meditationSessions/utils/meditationLoader';
import mindBattleLoader from 'components/pages/play/mindBattle/utils/mindBattleLoader';
import { WebviewRedirectPage } from './auth/WebviewRedirectPage/webview';
import { AuthPageV2 } from './auth/newAuth';
import { AuthButtons } from './auth/authButtons';
import { SearchMore } from 'components/library/navbar/Search';
import { PartnerRoute, ProtectPartnerRoute } from 'shared/routes/PartnerRoute';
import PreventInvalidRoute from './PreventInvalidRoute/PreventInvalidRoute';
import { AdminOrSpaceAdminRoute } from 'shared/routes/SpecificRoleRoute';
import { mainLoader } from 'loaders';
import Main from '../Main';
import CoursesPage from 'components/pages/study/courses/courses';
import { useSelector } from 'react-redux';

const UserProfile = lazy(() => import(/* webpackChunkName: "UserProfile" */ 'components/pages/profile/profile/user-profile'));
const OtherUserProfile = lazy(() => import(/* webpackChunkName: "OtherUserProfile" */ 'components/pages/profile/profile/other-user-profile'));
const Fullscreen = lazy(() => import(/* webpackChunkName: "Fullscreen" */ 'components/pages/layouts/fullscreen/Fullscreen'));
const Shop = lazy(() => import(/* webpackChunkName: "Shop" */ 'components/pages/shop/shop'));
// const CourseOverview = lazy(() => import(/* webpackChunkName: "CourseOverview" */ '../components/pages/study/courses/structures/CourseOverview'));
const MaterialPage = lazy(() => import(/* webpackChunkName: "MaterialPage" */ '../components/pages/study/courses/material-pages/MaterialPage'));
const Play = lazy(() => import(/* webpackChunkName: "Play" */ 'components/pages/play/Play'));
const Docs = lazy(() => import(/* webpackChunkName: "Docs" */ '../components/pages/info/docs/docs-page'));
const FAQ = lazy(() => import(/* webpackChunkName: "FAQ" */ '../components/pages/info/faq/FAQ'));
const SpacesPage = lazy(() => import(/* webpackChunkName: "SpacesPage" */ 'components/pages/spaces/SpacesPage'));
const FeedPage = lazy(() => import(/* webpackChunkName: "FeedPage" */ 'components/pages/spaces/Feed/FeedPage'));
const SpacePage = lazy(() => import(/* webpackChunkName: "SpacePage" */ 'components/pages/spaces/SpacePage'));
const SpaceCreation = lazy(() => import(/* webpackChunkName: "SpaceCreation" */ 'components/pages/spaces/SpaceCreation/index'));
const CourseRedirectPage = lazy(() => import('components/pages/courseRedirect/index'));
const FullscreenMindBattle = lazy(
  () => import(/* webpackChunkName: "FullscreenMindBattle" */ 'components/pages/play/mindBattle/FullscreenMindBattle')
);
const MeditationSession = lazy(
  () => import(/* webpackChunkName: "MeditationSession" */ 'components/pages/play/meditationSessions/MeditationSession')
);
const MeditationPlayer = lazy(
  () => import(/* webpackChunkName: "MeditationPlayer" */ 'components/pages/play/meditationSessions/MeditationPlayer/MeditationPlayer')
);
const Test = lazy(() => import(/* webpackChunkName: "Test" */ '../components/pages/info/faq/test'));
const CoursesConstructorPage = lazy(() => import(/* webpackChunkName: "CoursesConstructorPage" */ 'pages/coursesConstructor'));
const CoursesConstructorChaptersPage = lazy(
  () => import(/* webpackChunkName: "CoursesConstructorChaptersPage" */ './coursesConstructor/CoursesConstructorChaptersPage')
);
const CoursesConstructorLessonsPage = lazy(
  () => import(/* webpackChunkName: "CoursesConstructorLessonsPage" */ './coursesConstructor/CoursesConstructorLessonsPage')
);
const CoursesConstructorSlidesPage = lazy(
  () => import(/* webpackChunkName: "CoursesConstructorSlidesPage" */ './coursesConstructor/CoursesConstructorSlidesPage')
);
const Error = lazy(() => import(/* webpackChunkName: "ErrorPage" */ 'components/pages/error/Error'));
const Chest = lazy(() => import(/* webpackChunkName: "ChestPage" */ 'components/pages/chest/Chest'));
const GovernPage = lazy(() => import(/* webpackChunkName: "GovernPage" */ './govern'));
// const PremiumPage = lazy(() => import(/* webpackChunkName: "PremiumPage" */ 'components/pages/premium'));
// const PremiumPageTest = lazy(() => import(/* webpackChunkName: "PremiumPageTest" */ 'components/pages/premium_test'));
const LeaderboardPage = lazy(() => import(/* webpackChunkName: "LeaderboardPage" */ 'pages/leaderboard/Leaderboard'));
const LeaderboardPlayPlaceholder = lazy(() =>
  import(/* webpackChunkName: "LeaderboardPlayPlaceholder" */ 'pages/leaderboard/Leaderboard').then((module) => ({
    default: module.LeaderboardPlayPlaceholder,
  }))
);
const ReferralsPage = lazy(() => import(/* webpackChunkName: "ReferralsPage" */ 'components/pages/referrals'));
const RefSetterPage = lazy(() => import(/* webpackChunkName: "RefSetterPage" */ 'components/pages/referrals/SetRefPage'));
const Terms = lazy(() => import(/* webpackChunkName: "Terms" */ './terms'));

const Pages = () => {
  const isAuth = useGetIsAuth();
  //TODO пока надо оставить!
  const user = useGetUserData();
  const partnerId = useSelector(getPartnerId);

  const isUserReady = useMemo(() => Boolean(user?._id) && isAuth, [user?._id, isAuth]);

  const ProtectedRouting = useMemo((): RouteObject[] => {
    if (isUserReady)
      return [
        {
          path: ROUTES.MAIN,
          element: <ProtectedRoute isAuth={isAuth} />,
          children: [
            {
              path: ROUTES.PROFILE,
              element: (
                <Suspense fallback={<PageLoader />}>
                  <UserProfile />
                </Suspense>
              ),
              loader: userProfileLoader(store, dispatch),
            },
            {
              path: ROUTES.OTHER_PROFILE,
              element: (
                <Suspense fallback={<PageLoader />}>
                  <OtherUserProfile />
                </Suspense>
              ),
              loader: otherUserProfileLoader,
            },
            {
              path: ROUTES.FULLSCREEN,
              element: (
                <Suspense fallback={<PageLoader />}>
                  <Fullscreen />
                </Suspense>
              ),
              children: [
                {
                  path: ROUTES.FULLSCREEN_MINDBATTLE,
                  element: (
                    <Suspense fallback={<PageLoader isFixed />}>
                      <FullscreenMindBattle />
                    </Suspense>
                  ),
                  loader: mindBattleLoader,
                },
                {
                  path: ROUTES.MINDBATTLE_1V1,
                  element: (
                    <Suspense fallback={<PageLoader />}>
                      <FullscreenMindBattle />
                    </Suspense>
                  ),
                },
                {
                  path: ROUTES.MEDITATION,
                  element: (
                    <Suspense fallback={<PageLoader isFixed />}>
                      <MeditationSession />
                    </Suspense>
                  ),
                  loader: meditationLoader,
                },
                {
                  path: ROUTES.MEDITATION_PLAYER,
                  element: (
                    <Suspense fallback={<PageLoader />}>
                      <MeditationPlayer />
                    </Suspense>
                  ),
                  loader: suspenseLoader,
                },
                // {
                //   path: ROUTES.BOOKS,
                //   element: (
                //     <Suspense fallback={<PageLoader />}>
                //       <Books />
                //     </Suspense>
                //   ),
                // },
                // {
                //   path: ROUTES.READ_BOOK,
                //   element: (
                //     <Suspense fallback={<PageLoader />}>
                //       <ReadBook />
                //     </Suspense>
                //   ),
                // },
                {
                  path: ROUTES.CHESTS,
                  element: (
                    <Suspense fallback={<PageLoader />}>
                      <Chest />
                    </Suspense>
                  ),
                },
                // {
                //   path: ROUTES.PREMIUM,
                //   element: (
                //     <Suspense fallback={<PageLoader />}>
                //       <PremiumPage />
                //     </Suspense>
                //   ),
                // },
                // {
                //   path: ROUTES.PREMIUM_TEST,
                //   element: (
                //     <Suspense fallback={<PageLoader />}>
                //       <PremiumPageTest />
                //     </Suspense>
                //   ),
                // },
                // {
                //   path: ROUTES.CERTIFICATES,
                //   element: (
                //     <Suspense fallback={<PageLoader />}>
                //       <CertificatesPage />
                //     </Suspense>
                //   ),
                // },
              ],
              errorElement: <Error />,
            },
            {
              path: ROUTES.SPACE_CREATION,
              element: (
                <Suspense>
                  <SpaceCreation />
                </Suspense>
              ),
            },
            {
              path: ROUTES.LEADERBOARD,
              element: (
                <Suspense fallback={<PageLoader isFixed theme="red" />}>
                  <LeaderboardPlayPlaceholder />
                </Suspense>
              ),
              loader: leaderboardLoader,
            },
            {
              path: ROUTES.SPACE_LEADERBOARD,
              element: (
                <Suspense fallback={<PageLoader isFixed theme="red" />}>
                  <LeaderboardPage />
                </Suspense>
              ),
              loader: leaderboardLoader,
            },
            {
              path: ROUTES.FEED,
              element: (
                <Suspense fallback={<></>}>
                  <FeedPage />
                </Suspense>
              ),
            },
            // {
            //   path: ROUTES.COURSES_OVERVIEW_REST,
            //   element: (
            //     <Suspense fallback={<PageLoader />}>
            //       <CourseOverview />
            //     </Suspense>
            //   ),
            // },
            {
              path: ROUTES.INFO,
              element: (
                <Suspense fallback={<PageLoader />}>
                  <Docs />
                </Suspense>
              ),
              loader: suspenseLoader,
            },
            {
              path: ROUTES.SETTINGS,
              element: (
                <Suspense fallback={<PageLoader />}>
                  <FAQ />
                </Suspense>
              ),
              loader: suspenseLoader,
            },
            {
              path: 'test',
              element: (
                <Suspense fallback={<PageLoader />}>
                  <Test />
                </Suspense>
              ),
            },
            {
              path: ROUTES.SEARCH,
              element: (
                <Suspense fallback={<PageLoader />}>
                  <SearchMore />
                </Suspense>
              ),
            },
            {
              path: ROUTES.GOVERN,
              element: (
                <Suspense fallback={<PageLoader />}>
                  <GovernPage />
                </Suspense>
              ),
              loader: suspenseLoader,
            },
            {
              path: ROUTES.REFERRALS,
              element: (
                <Suspense fallback={<PageLoader />}>
                  <ReferralsPage />
                </Suspense>
              ),
            },
          ],
        },
      ];
    return [];
  }, [isUserReady]);

  const PublicRouting = useMemo(
    (): RouteObject[] => [
      {
        element: <PublicRoute isAuth={isAuth} />,
        children: [
          {
            index: true,
            element: <CoursesPage />,
          },
          {
            element: <AuthPageV2 />,
            children: [
              { path: ROUTES.AUTH, element: <AuthButtons />, index: true },
              {
                path: ROUTES.AUTHEMAIL,
                element: <AuthPage />,
                children: [
                  { path: ROUTES.LOGIN, element: <LogInPage />, index: true },
                  { path: ROUTES.SIGN_UP, element: <SignUpPage /> },
                ],
              },
            ],
          },
          {
            path: ROUTES.REFERRAL_LANDING,
            element: (
              <Suspense fallback={<PageLoader />}>
                <RefSetterPage />
              </Suspense>
            ),
          },
          {
            path: ROUTES.SHOP,
            element: (
              <Suspense fallback={<PageLoader />}>
                <Shop />
              </Suspense>
            ),
            loader: suspenseLoader,
          },
          {
            path: ROUTES.PLAY,
            element: (
              <Suspense fallback={<PageLoader />}>
                <Play />
              </Suspense>
            ),
          },
          { path: ROUTES.ACTIVATION_CODE, element: <UserActivationCodeChecker /> },
          {
            path: ROUTES.RESET_PASSWORD,
            element: <ResetPasswordPage />,
          },
          {
            path: ROUTES.SPACES,
            element: (
              <Suspense fallback={<PageLoader />}>
                <SpacesPage />
              </Suspense>
            ),

            loader: suspenseLoader,
          },
          {
            path: ROUTES.SPACE,
            element: (
              <Suspense fallback={<PageLoader />}>
                <SpacePage />
              </Suspense>
            ),
            children: [
              {
                element: <AdminOrSpaceAdminRoute />,
                children: [
                  {
                    path: ROUTES.SPACE_COURSE_EDIT,
                    element: (
                      <Suspense fallback={<PageLoader />}>
                        <SpacePage />
                      </Suspense>
                    ),
                  },
                  {
                    path: ROUTES.SPACE_CHAPTER_EDIT,
                    element: (
                      <Suspense fallback={<PageLoader />}>
                        <SpacePage />
                      </Suspense>
                    ),
                  },
                  {
                    path: ROUTES.SPACE_LESSON_EDIT,
                    element: (
                      <Suspense fallback={<PageLoader />}>
                        <SpacePage />
                      </Suspense>
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: ROUTES.COURSES_LESSON_PAGE,
        element: (
          <Suspense fallback={<PageLoader />}>
            <MaterialPage />
          </Suspense>
        ),
      },
      {
        path: ROUTES.COURSES_OVERVIEW, // navigate to lesson page
        element: (
          <Suspense fallback={<PageLoader />}>
            <CourseRedirectPage />
          </Suspense>
        ),
      },
      {
        path: ROUTES.TERMS,
        element: (
          <Suspense fallback={<PageLoader />}>
            <Terms />
          </Suspense>
        ),
      },
    ],
    [isAuth]
  );

  const SpecificRouting = useMemo(
    (): RouteObject[] => [
      {
        path: ROUTES.COURSES_CONSTRUCTOR,
        element: <SpecificRoleRoute requiredRoles={[USER_ROLES.CREATOR, USER_ROLES.ADMIN]} />,
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<PageLoader />}>
                <CoursesConstructorPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: ROUTES.COURSES_CONSTRUCTOR_CHAPTERS,
        element: <SpecificRoleRoute requiredRoles={[USER_ROLES.CREATOR, USER_ROLES.ADMIN]} />,
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<PageLoader />}>
                <CoursesConstructorChaptersPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: ROUTES.COURSES_CONSTRUCTOR_LESSONS,
        element: <SpecificRoleRoute requiredRoles={[USER_ROLES.CREATOR, USER_ROLES.ADMIN]} />,
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<PageLoader />}>
                <CoursesConstructorLessonsPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: ROUTES.COURSES_EDITOR_EDIT_LESSON,
        element: <SpecificRoleRoute requiredRoles={[USER_ROLES.CREATOR, USER_ROLES.ADMIN]} />,
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<PageLoader />}>
                <CoursesConstructorSlidesPage />
              </Suspense>
            ),
          },
        ],
      },
    ],
    []
  );

  const PartnerPublicRouting = useMemo((): RouteObject[] => {
    return [
      {
        element: <PartnerRoute isAuth={isAuth} partnerId={partnerId ?? undefined} redirectPath="" />,
        children: [
          {
            element: <AuthPageV2 />,
            children: [
              { path: ROUTES.AUTH, element: <AuthButtons />, index: true },
              {
                path: ROUTES.AUTHEMAIL,
                element: <AuthPage />,
                children: [
                  { path: ROUTES.LOGIN, element: <LogInPage />, index: true },
                  { path: ROUTES.SIGN_UP, element: <SignUpPage /> },
                ],
              },
            ],
          },
          {
            path: ROUTES.RESET_PASSWORD,
            element: (
              <Suspense fallback={<PageLoader />}>
                <ResetPasswordPage />
              </Suspense>
            ),
          },
          { path: ROUTES.ACTIVATION_CODE, element: <UserActivationCodeChecker /> },
          {
            path: ROUTES.SPACE_PARTNER_ID,
            element: (
              <Suspense fallback={<PageLoader />}>
                <SpacePage />
              </Suspense>
            ),
          },
        ],
      },
    ];
  }, [isAuth, partnerId]);

  const PartnerProtectedRouting = useMemo((): RouteObject[] => {
    if (isUserReady)
      return [
        {
          element: <ProtectPartnerRoute isAuth={isAuth} partnerId={partnerId ?? undefined} />,
          children: [
            {
              path: ROUTES.COURSES_LESSON_PAGE,
              element: (
                <Suspense fallback={<PageLoader />}>
                  <MaterialPage />
                </Suspense>
              ),
            },
            {
              path: ROUTES.COURSES_OVERVIEW,
              element: (
                <Suspense fallback={<PageLoader />}>
                  <CourseRedirectPage />
                </Suspense>
              ),
            },
          ],
        },
      ];
    return [];
  }, [isUserReady, partnerId]);

  const getRouterConfig = () => {
    if (partnerId) {
      localStorage.setItem('partnerID', partnerId);
      return [
        {
          element: (
            <PreventInvalidRoute>
              <Main />
            </PreventInvalidRoute>
          ),
          loader: mainLoader(store, dispatch),
          children: [...PartnerPublicRouting, ...PartnerProtectedRouting],
          errorElement: <Error />,
        },
        {
          element: <WebviewRedirectPage />,
          path: ROUTES.WEBVIEW_REDIRECT,
        },
        {
          path: '*',
          element: <Navigate to={`/space/${localStorage.getItem('partnerID')}?partnerID=${localStorage.getItem('partnerID')}`} replace />,
        },
        {
          path: ROUTES.ERROR,
          element: (
            <Suspense fallback={<MightyLoaderMini />}>
              <Error />
            </Suspense>
          ),
          errorElement: <Error />,
        },
      ];
    }

    return [
      {
        path: ROUTES.MAIN,
        element: <Main />,
        loader: mainLoader(store, dispatch),
        children: [...ProtectedRouting, ...PublicRouting, ...SpecificRouting],
        errorElement: <Error />,
      },
      {
        element: <WebviewRedirectPage />,
        path: ROUTES.WEBVIEW_REDIRECT,
      },
      {
        path: '*',
        element: <Navigate to={isAuth ? ROUTES.MAIN : ROUTES.AUTH} replace state={isAuth ? {} : { prev: window.location.pathname }} />,
      },
      {
        path: ROUTES.ERROR,
        element: <Error />,
        errorElement: <Error />,
      },
    ];
  };

  return <RouterProvider router={createBrowserRouter(getRouterConfig())} />;
};

export default memo(Pages);
