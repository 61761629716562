import { FC, useRef, useState } from 'react';
import clsx from 'clsx';
import { BsChevronLeft } from 'react-icons/bs';
import { motion } from 'framer-motion';

import ProgressBar from '../../../../study/courses/material-pages/progress/ProgressBar';
import { useDisplay } from 'hooks';
import BasicButton from 'shared/ui/basic-button/button';

import { data } from './data';
import { pathVariants } from 'features/DownloadApp/components/Help/motion/variants';

import styles from './styles.module.scss';

interface IInstructionsProps {
  onClose: () => void;
}

export const Instructions: FC<IInstructionsProps> = ({ onClose }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const { isMobile } = useDisplay();
  const progress = useRef(1);
  const countSteps = 4;
  const handleChangeSlide = (index: number) => {
    if (index > activeSlide && progress.current < countSteps) {
      progress.current += 1;
    }
    setActiveSlide(index);
  };

  return (
    <div className={styles.container}>
      {isMobile && (
        <div className={styles.header}>
          <BsChevronLeft strokeWidth={'1px'} onClick={onClose} />
          <h5>Activation instructions</h5>
        </div>
      )}

      <ProgressBar
        current={activeSlide}
        activeSlide={activeSlide}
        changeSlide={handleChangeSlide}
        progressData={{ progress: progress.current, sectionsNumber: countSteps }}
        slideNames={[{ name: 'Step 1' }, { name: 'Step 2' }, { name: 'Step 3' }, { name: 'Step 4' }]}
        variant={'new'}
      />
      <div className={styles.window}>
        <div className={styles.panel}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <img src={data[activeSlide].image} alt={''} />
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          width={data[activeSlide].path.width}
          height={data[activeSlide].path.height}
          viewBox={`0 0 ${data[activeSlide].path.width} ${data[activeSlide].path.height}`}
          fill="none"
          className={clsx(styles.path, styles[`step-${activeSlide + 1}`])}
        >
          <motion.path
            key={activeSlide}
            variants={pathVariants}
            initial={'initial'}
            animate={'animate'}
            d={data[activeSlide].path.d}
            stroke="#6487EF"
            strokeWidth="3.40152"
            strokeLinecap="round"
          />
        </motion.svg>
      </div>
      {!isMobile && <div className={styles.step}>STEP {activeSlide + 1}</div>}
      <p className={styles.text}>{data[activeSlide].text}</p>
      {isMobile && (
        <div className={styles.wrap}>
          <div className={styles.step}>STEP {activeSlide + 1} OF 4</div>
          <div className={styles.buttonsWrap}>
            <BasicButton label={'Back'} onClick={() => handleChangeSlide(activeSlide - 1)} className={styles.button} isDisabled={activeSlide <= 0} />
            <BasicButton
              label={'Next'}
              onClick={() => handleChangeSlide(activeSlide + 1)}
              className={styles.button}
              isDisabled={activeSlide >= countSteps - 1}
            />
          </div>
        </div>
      )}
    </div>
  );
};
