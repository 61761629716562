import { useDisplay } from 'hooks';
import { FC, PropsWithChildren, useEffect } from 'react';
import { AuthPageViewMobile } from './mobile';
import { AuthPageViewDesktop } from './desktop';

export const AuthPageView: FC<PropsWithChildren> = ({ children }) => {
  const { isMobile } = useDisplay();

  useEffect(() => {
    const topShadow = document.querySelector<HTMLDivElement>('.brow');
    if (topShadow) {
      topShadow.style.display = 'none';
    }
    return () => {
      if (topShadow) {
        topShadow.style.display = 'block';
      }
    };
  }, []);

  if (isMobile) {
    return <AuthPageViewMobile>{children}</AuthPageViewMobile>;
  }
  return <AuthPageViewDesktop>{children}</AuthPageViewDesktop>;
};
