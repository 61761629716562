import { FC, memo, PropsWithChildren, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SDropdown, SDropdownContentWrapper, STrigger } from './styles';
import { AnimatePresence, motion } from 'framer-motion';
import { DropdownMenuItem } from '../../../components/library/dropdownMenu/components/MenuItem';
import clsx from 'clsx';
import ReactDOM from 'react-dom';

type TDropdownVariant = 'primary' | 'secondary';

interface IDropdownProps extends PropsWithChildren {
  triggerNode: ReactNode;
  maxWidth?: number;
  items?: any[];
  className?: string;
  zIndex?: number;
  variant?: TDropdownVariant;
  closeOutside?: boolean;
  itemsPlacement?: any;
  classNameDropdown?: string;
  zeroPadding?: boolean;
}

const rootVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
    scale: 0,
  },
};

const childrenVariants = {
  initial: {
    opacity: 0,
    scale: 0.2,
  },
  animate: {
    scale: 1,
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

export const DropdownMotion: FC<IDropdownProps> = memo(
  ({
    children,
    maxWidth = 400,
    items,
    triggerNode,
    className = '',
    variant = 'primary',
    zIndex = 1000,
    closeOutside = true,
    itemsPlacement,
    classNameDropdown = '',
    zeroPadding = false,
  }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [leftAdjustment, setLeftAdjustment] = useState(0);
    const portalContainer = useMemo(() => document.querySelector('#portal'), []);
    const $dropdownRef = useRef<HTMLDivElement>(null);
    const $triggerRef = useRef<HTMLDivElement>(null);

    const DOMRect = $triggerRef.current?.getBoundingClientRect();

    const topOffset = (DOMRect?.bottom ?? 0) + 10;
    const leftOffset = (DOMRect?.left ?? 0) + window.scrollX;

    const handleCloseDropdown = useCallback(() => {
      setIsOpen(false);
    }, []);

    const handleClickOutside = useCallback((evt: MouseEvent) => {
      if (!closeOutside) return;

      const target = evt.target;
      if (!target) {
        return;
      }

      if (target === $triggerRef.current || $triggerRef.current?.contains(target as Node)) {
        return;
      }

      if (target === $dropdownRef.current || $dropdownRef.current?.contains(target as Node)) {
        return;
      }

      setIsOpen(false);
    }, []);

    useEffect(() => {
      document.addEventListener('wheel', handleCloseDropdown);
      document.addEventListener('scroll', handleCloseDropdown);
      document.addEventListener('click', handleClickOutside);

      return () => {
        document.removeEventListener('wheel', handleCloseDropdown);
        document.removeEventListener('scroll', handleCloseDropdown);
        document.removeEventListener('click', handleClickOutside);
      };
    }, []);

    useEffect(() => {
      const portalContainerWidth = portalContainer?.getBoundingClientRect()?.width ?? 0;
      const menuWidth = $dropdownRef.current?.getBoundingClientRect()?.width ?? 0;
      const toggleLeft = $triggerRef.current?.getBoundingClientRect()?.left ?? 0;

      if (portalContainerWidth < toggleLeft + menuWidth) {
        setLeftAdjustment(toggleLeft - menuWidth ?? 0);
      }
    }, [isOpen, portalContainer]);

    const handleItemClick = (callback?: () => void) => () => {
      callback?.();
      handleCloseDropdown();
    };

    if (!portalContainer) {
      return null;
    }

    return (
      <>
        <STrigger onClick={() => setIsOpen((prev) => !prev)} ref={$triggerRef} className={clsx(className)}>
          {triggerNode}
        </STrigger>
        {ReactDOM.createPortal(
          <AnimatePresence>
            {isOpen && (
              <SDropdown
                as={motion.div}
                ref={$dropdownRef}
                variants={rootVariants}
                initial={'initial'}
                animate={'animate'}
                exit={'exit'}
                style={
                  itemsPlacement
                    ? {
                        position: 'fixed',
                        left: itemsPlacement.left,
                        right: itemsPlacement.right,
                        top: `${topOffset}px`,
                        zIndex,
                      }
                    : {
                        position: 'fixed',
                        left: `${leftOffset - leftAdjustment}px`,
                        top: `${topOffset}px`,
                        zIndex,
                      }
                }
                $maxWidth={maxWidth}
                $items={!!items}
                $variant={variant}
                $noPadding={zeroPadding}
                className={classNameDropdown}
              >
                <SDropdownContentWrapper
                  as={motion.div}
                  variants={childrenVariants}
                  initial={'initial'}
                  animate={'animate'}
                  exit={'exit'}
                  transition={{ delay: 0.2 }}
                >
                  {items ? items.map((item) => <DropdownMenuItem key={item.title} {...item} onClick={handleItemClick(item.onClick)} />) : children}
                </SDropdownContentWrapper>
              </SDropdown>
            )}
          </AnimatePresence>,
          portalContainer
        )}
      </>
    );
  }
);
