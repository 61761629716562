import { useRef } from 'react';
import { useTelegramAuth } from './useTelegramAuth';
import { Flex } from 'shared/ui';
import styled from 'styled-components';
import { TemplateButton } from '../authButtons';
import { ReactComponent as TelegramIcon } from 'assets/icons/telegram.svg';
import { useGetMiniAppInitData } from 'shared/utils';

const Container = styled.div`
  position: absolute;
  height: fit-content;
  width: fit-content;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  & iframe {
    opacity: 0.000001;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(2, 3) !important;
  }
`;

const Box = styled.div`
  width: 100%;
  height: fit-content;
  position: relative;
  overflow: hidden;
  border-radius: 42px;
  & > div {
    margin: 0;
  }
`;

export const TelegramAuthButton = () => {
  const { initData } = useGetMiniAppInitData();

  if (!initData) {
    return null;
  }

  return <TelegramAuthButtonWrapped />;
};

const TelegramAuthButtonWrapped = () => {
  const buttonsContainerRef = useRef<HTMLDivElement>(null);

  const { isReady, isLoading } = useTelegramAuth(buttonsContainerRef);

  const iconBox = (
    <Flex justifyContent="center" alignItems="center" style={{ width: 36, height: 36 }}>
      <TelegramIcon width={28} height={28} />
    </Flex>
  );

  return (
    <>
      <Box>
        <TemplateButton title="Login with Telegram" icon={iconBox} isLoading={!isReady || isLoading} />
        <Container ref={buttonsContainerRef}></Container>
      </Box>
    </>
  );
};
