import { ICourse } from 'interface/courses';
import { createContext, useContext } from 'react';
import { ICourseFilteringGroup } from './hooks';

export type TCoursesPageState = {
  courses: ICourse[];
  displayedCourses: ICourse[];
  activeGroups: ICourseFilteringGroup[];
  handleGroups(nativeName: string): void;
  selectCourse(courseId?: string): () => void;
  searchForCourses(e: React.ChangeEvent<HTMLInputElement> | null): void;
};

export const CoursesContext = createContext<TCoursesPageState>(null as any);

export const useCoursesContext = () => {
  return useContext(CoursesContext);
};
