import { EAuthMethods } from 'interface';
import { ReactComponent as TONIcon } from './assets/ton.svg';
import {
  GoogleIcon,
  WalletAuthLinkIcon,
  // TelegramIcon
} from 'assets/icons/icons';

export interface ILinkAuthMethod {
  title: string;
  icon: any;
  color: string;
  inactiveColor: string;
  method: EAuthMethods;
}

export const LINK_AUTH_METHODS: ILinkAuthMethod[] = [
  {
    title: 'TON',
    icon: <TONIcon />,
    color: '#0098ea',
    inactiveColor: '#0098ea33',
    method: EAuthMethods.TON,
  },
  {
    title: 'Google',
    icon: <GoogleIcon />,
    color: '#af3232',
    inactiveColor: '#D6251633',
    method: EAuthMethods.Google,
  },
  {
    title: 'Connect wallet',
    icon: <WalletAuthLinkIcon />,
    color:
      'linear-gradient(0deg, rgba(56, 154, 252, 0.08), rgba(56, 154, 252, 0.08)),linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))',
    inactiveColor:
      'linear-gradient(0deg, rgba(56, 154, 252, 0.08), rgba(56, 154, 252, 0.08)),linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))',
    method: EAuthMethods.EVM,
  },
  // {
  //   title: 'Connect Telegram',
  //   icon: <TelegramIcon />,
  //   color:
  //     'linear-gradient(0deg, rgba(56, 154, 252, 0.08), rgba(56, 154, 252, 0.08)),linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))',
  //   inactiveColor:
  //     'linear-gradient(0deg, rgba(56, 154, 252, 0.08), rgba(56, 154, 252, 0.08)),linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))',
  //   method: EAuthMethods.Telegram,
  // },
];
