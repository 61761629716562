import styled from 'styled-components';
import line from 'assets/images/line.svg';
import { motion } from 'framer-motion';

export const Container = styled.div`
  height: 120px;
  min-height: 120px;
  width: 100%;
  max-width: 1028px;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
  gap: 280px;
  margin-top: auto;
  margin-bottom: min(90px, 5vh);
  mask-type: alpha;
  mask-image: linear-gradient(90deg, transparent 0%, #000 20%, #000 80%, transparent 100%);
  @media screen and (max-width: 992px) {
    max-width: 351px;
    height: 66px;
    min-height: 66px;
    margin-top: 27px;
    margin-bottom: 27px;
  }
`;
export const Buttons = styled.div<{ left?: number }>`
  transition: 1s;
  height: 86px;
  padding: 0px 191px;
  width: calc(1028px * 6);
  display: flex;
  justify-content: center;
  gap: 323px;
  transform: translateX(${(p) => p.left || 0}px);
  transition: transform 1.5s ease-in-out;
  position: relative;
  z-index: 5;
  @media screen and (max-width: 992px) {
    gap: 121px;
    padding: 0px 55px;
    height: 66px;
  }
`;

export const ButtonContainer = styled(motion.div)`
  width: 86px;
  height: 86px;
  margin-left: -43px;
  margin-right: -43px;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 992px) {
    width: 66px;
    height: 66px;
    margin: 0 -33px;
  }
`;

export const Button = styled.div<{ active?: boolean; subactive?: boolean; top?: boolean; bottom?: boolean }>`
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), rgba(98, 126, 234, 0.36);
  backdrop-filter: blur(13.5px);
  color: white;
  width: 86px;
  height: 86px;
  display: flex;
  padding: 0px 17px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: 1.5s ease-in-out;
  ${(p) => (p.subactive ? 'width: 50px; height: 50px; padding: 13px;' : '')}
  ${(p) => (p.subactive && p.top ? 'transform: translateY(-30px);' : '')}
  ${(p) => (p.subactive && p.bottom ? 'transform: translateY(30px);' : '')}
  ${(p) => !p.active && !p.subactive && 'opacity: 0; transform: scale(0.1);'}
  & > img {
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: 992px) {
    height: 66px;
    width: 66px;
    ${(p) => (p.subactive ? 'width: 38px; height: 38px; padding: 10px;' : '')}
    ${(p) => (p.subactive && p.top ? 'transform: translateY(-10px);' : '')}
    ${(p) => (p.subactive && p.bottom ? 'transform: translateY(10px);' : '')}
  }
`;

export const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mask-image: radial-gradient(50% 50% at 50% 50%, #6b82de 0%, rgba(107, 130, 222, 0) 100%);
`;

export const Background = styled.div<{ left?: number }>`
  transform: translateX(${(p) => p.left || 0}px);
  transition: transform 1.5s ease-in-out;
  background-image: url(${line});
  background-size: auto 70px;
  width: 5000px;
  height: 70px;
  bottom: 25px;
  left: 0%;
  background-position: 0px center;
  background-repeat: no-repeat;
  position: absolute;
  pointer-events: none;
  z-index: -1;
  @media screen and (max-width: 992px) {
    bottom: 50%;
    transform: translateY(50%) translateX(${(p) => p.left || 0}px);
    background-size: auto 24px;
    width: 500%;
  }
`;
