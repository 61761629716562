import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      background: rgba(255, 255, 255, 0.2);
      width: 100%;
      height: 52px;
      display: flex;
      align-items: center;
      padding: 13px 20px;

      &:has(input:focus) {
        background: rgba(255, 255, 255, 0.3);
      }
    `
  ),
  SearchIcon = styled.div(
    () => css`
      flex-shrink: 0;
      margin-right: 8px;
    `
  ),
  Input = styled.input(
    () => css`
      background: none;
      border: none;
      width: 100%;
      height: 100%;
      margin-right: 8px;
      font-size: 16px;
      line-height: 100%;
      color: white;

      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
    `
  ),
  ClearIcon = styled.button(
    () => css`
      background: rgba(255, 255, 255, 0.2);
      border-radius: 100%;
      height: 100%;
      width: auto;
      padding: 5px;
      flex-shrink: 0;
    `
  );
