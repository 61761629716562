import { useEffect, useMemo, useReducer } from 'react';

interface Params {
  sound?: string | any;
  onEnd?: () => any;
}

interface IState {
  playing: boolean;
  currentTime: number;
  percent: number;
  volume: number;
  duration: number;
  speed: number;
}

const useAudio = ({ sound, onEnd }: Params = {}) => {
  const audio = useMemo(() => new Audio(sound), [sound]);
  const [{ playing, currentTime, percent, volume, duration, speed }, updateState] = useReducer(
    (prev: IState, next: Partial<IState>) => ({ ...prev, ...next }),
    {
      playing: false,
      currentTime: audio.currentTime,
      percent: 0,
      volume: 100,
      duration: 0,
      speed: 1,
    }
  );

  const toggle = () => updateState({ playing: !playing });

  const play = (volume: number = 100, speed: number = 1) => {
    if (playing) {
      audio.currentTime = 0;
      audio.play();
    } else {
      updateState({ volume, speed, playing: true });
    }
  };

  useEffect(() => {
    if (playing) {
      handleSetVolume(volume);
      handleSetSpeed(speed);
      audio.play().catch(console.error);
    } else {
      audio.pause();
    }
  }, [playing, audio]);

  useEffect(() => {
    // const interval = setInterval(() => updateState({ currentTime: Math.round(audio.currentTime) }), 1000);

    const handleEnded = () => {
      updateState({ playing: false });
      onEnd && onEnd();
    };

    audio.addEventListener('ended', handleEnded);
    return () => {
      audio.src = '';
      // clearInterval(interval);
      audio.removeEventListener('ended', handleEnded);
    };
  }, [audio, onEnd]);

  useEffect(() => {
    if (audio.duration) {
      updateState({ percent: (currentTime / audio.duration) * 100 });
    }
  }, [currentTime]);

  function normalizeTime(time: number) {
    let min: number | string = Math.floor(time / 60);
    if (min < 10) {
      min = '0' + String(min);
    }
    let sec: number | string = Math.floor(time % 60);
    if (sec < 10) {
      sec = '0' + String(sec);
    }

    return min + ':' + sec;
  }

  function incrementTime() {
    audio.currentTime += 15;
    updateState({ currentTime: audio.currentTime });
  }

  function decrementTime() {
    audio.currentTime -= 15;
    updateState({ currentTime: audio.currentTime });
  }

  function handleSetVolume(number: any) {
    audio.volume = number / 100;
    updateState({ volume: number });
  }

  function handleSetSpeed(number: number) {
    audio.playbackRate = number;
    updateState({ speed: number });
  }

  function handleToggleVolume() {
    if (volume === 0) {
      audio.volume = 1;
      return updateState({ volume: audio.volume * 100 });
    }
    audio.volume = 0;
    return updateState({ volume: audio.volume * 100 });
  }

  function handleSetAudio(number: number) {
    const per = number / 100;
    audio.currentTime = audio.duration * per;

    updateState({ percent: per });
  }

  audio.onloadedmetadata = () => {
    updateState({ duration: audio.duration });
  };

  return {
    playing,
    toggle,
    play,
    currentTime: normalizeTime(currentTime),
    totalTime: normalizeTime(duration),
    percent,
    volume,
    decrementTime,
    incrementTime,
    setVolume: (volume: number) => updateState({ volume }),
    handleSetVolume,
    setPercent: (percent: number) => updateState({ percent }),
    handleToggleVolume,
    handleSetAudio,
  };
};

export default useAudio;
