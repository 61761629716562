import { useNavigate } from 'react-router-dom';
import { useGetSubscribersQuery } from 'store';
import { AnimatePresence } from 'framer-motion';
import { useDisplay, useGetCurrentTheme } from 'hooks';
import { useGetSpaceRewards } from '../hooks';
import { useAveregeColorWText } from './hooks';

import { ISpace } from 'store/spaces/types';
import { LANGS, THEMES } from 'app/constants';

import { ReactComponent as PeopleIcon } from '../assets/people-icon.svg';
import { ReactComponent as VerifiedIcon } from '../assets/verified.svg';

import { SpaceFollowButton } from '../SpaceCreation/components/followButton';

import * as S from './SpaceItem.style';

type Props = {
  large?: boolean;
  space: ISpace;
  forcedMobile?: boolean;
};

const SpaceItem = ({ large, space, forcedMobile }: Props) => {
  const { imageThumb, isVerified, name, tags, description } = space;
  const { data: followers } = useGetSubscribersQuery(space._id, { skip: large });
  const { total: rewards } = useGetSpaceRewards(space._id, !large);
  const { isMobile: isDefaultMobile } = useDisplay();
  const navigate = useNavigate();
  const desc = description.find((el) => el.language === LANGS.EN)?.title ?? description[0].title;
  const theme = useGetCurrentTheme();

  const { color, textColor } = useAveregeColorWText({
    imagePath: imageThumb,
    options: { defaultColor: theme === THEMES.LIGHT ? [17, 17, 17, 0.1] : [255, 255, 255, 0.1] },
  });

  const isMobile = isDefaultMobile || forcedMobile;

  const handleClick = () => {
    navigate('/' + space.name.replaceAll(' ', '_'));
  };

  const variants = {
    exit: { opacity: -1, x: 20 },
    animate: { opacity: 1, x: 0 },
    initial: { opacity: 0, x: 10 },
  };

  const shortedDesc = desc.length > 95 ? desc.substring(0, 92) + '...' : desc;

  const top = (
    <>
      <S.ImageBox $large={large}>
        <img src={imageThumb} alt="thumb" />
      </S.ImageBox>
      <S.NameBox>
        <S.Name $textColor={textColor} forcedMobile={forcedMobile} $large={large}>
          <span>{name}</span> {isVerified && <VerifiedIcon style={{ minWidth: '25px' }} />}
        </S.Name>
        <S.TagList forcedMobile={forcedMobile} tagsColor={textColor}>
          {large
            ? tags.map((t, i) => {
                return i > 1 ? null : (
                  <S.Tag $theme={theme} key={i + t}>
                    <span>{t}</span>
                  </S.Tag>
                );
              })
            : [...tags]
                .sort((a, b) => a.length - b.length)
                .map((t, i) => {
                  if (i === 0) {
                    return (
                      <S.Tag $theme={theme} key={i + t}>
                        <span>{t}</span>
                      </S.Tag>
                    );
                  }
                  if (isMobile && i === 1 && tags[0].length > 4) {
                    return null;
                  } else if (isMobile && i === 1 && t.length <= 4) {
                    return (
                      <S.Tag $theme={theme} key={i + t}>
                        <span>{t}</span>
                      </S.Tag>
                    );
                  }
                  if (i > 1) {
                    return null;
                  }
                  return (
                    <S.Tag $theme={theme} key={i + t}>
                      <span>{t}</span>
                    </S.Tag>
                  );
                })}
        </S.TagList>
      </S.NameBox>
    </>
  );

  return (
    <AnimatePresence>
      <S.Container
        variants={variants}
        animate={'animate'}
        initial={'initial'}
        exit={'exit'}
        color={color}
        $large={large}
        $forcedMobile={forcedMobile}
        onClick={handleClick}
        $theme={theme}
      >
        {large || isMobile ? top : <S.TopBox>{top}</S.TopBox>}
        {!isMobile && (
          <>
            <S.Description $large={large} $textColor={textColor}>
              {shortedDesc}
            </S.Description>
            <S.Bottom>
              {!large ? (
                <S.Followers $color={textColor}>
                  <PeopleIcon />
                  {!!followers ? followers.items.length : 0}
                </S.Followers>
              ) : (
                <>
                  <S.Rewards>{rewards.map((el, i) => (i > 5 ? null : <S.RewardIcon key={el.id + space._id} src={el.imageThumb} />))}</S.Rewards>
                </>
              )}
              <SpaceFollowButton spaceId={space._id} />
            </S.Bottom>
          </>
        )}
      </S.Container>
    </AnimatePresence>
  );
};

export default SpaceItem;
