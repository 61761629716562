import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';

import { DropdownMenuItem } from './components/MenuItem';

import * as Elements from './styles';
import { TDropdownMenuProps } from './types';
import { AnimatePresence, motion } from 'framer-motion';

const DropdownMenu: FC<TDropdownMenuProps> = ({ toggle, items = [], toggleClassName = '', parentRef, letChildrenOverflow }) => {
  const [opened, setOpened] = useState(false);
  const [leftAdjustment, setLeftAdjustment] = useState(0);
  const portalContainer = useMemo(() => document.querySelector('#portal'), []);
  const $toggleRef = useRef<HTMLDivElement>(null);
  const $menuRef = useRef<HTMLDivElement>(null);

  const DOMRect = $toggleRef.current?.getBoundingClientRect();

  const topOffset = (DOMRect?.bottom ?? 0) + 10;
  const leftOffset = (DOMRect?.left ?? 0) + window.scrollX;

  const handleCloseDropdown = useCallback(() => {
    setOpened(false);
  }, []);

  const handleClickOutside = (evt: MouseEvent) => {
    if (!$toggleRef.current?.contains(evt.target as Node) && !$menuRef.current?.contains(evt.target as Node)) {
      setOpened(false);
    }
  };

  useEffect(() => {
    if (parentRef?.current) {
      parentRef.current.addEventListener('click', handleCloseDropdown);
    }

    document.addEventListener('click', handleClickOutside);
    document.addEventListener('keydown', handleKeydown);
    document.addEventListener('wheel', handleCloseDropdown);
    document.addEventListener('scroll', handleCloseDropdown);

    return () => {
      if (parentRef?.current) {
        parentRef.current.removeEventListener('click', handleCloseDropdown);
      }
      document.removeEventListener('wheel', handleCloseDropdown);
      document.removeEventListener('keydown', handleKeydown);
      document.removeEventListener('scroll', handleCloseDropdown);
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleCloseDropdown]);

  useEffect(() => {
    const portalContainerWidth = portalContainer?.getBoundingClientRect()?.width ?? 0;
    const menuWidth = $menuRef.current?.getBoundingClientRect()?.width ?? 0;
    const toggleLeft = $toggleRef.current?.getBoundingClientRect()?.left ?? 0;
    const toggleWidth = $toggleRef.current?.getBoundingClientRect()?.width ?? 0;

    if (portalContainerWidth < toggleLeft + menuWidth) {
      setLeftAdjustment((menuWidth ?? 0) - toggleWidth);
    }
  }, [opened, portalContainer]);

  const handleItemClick = (callback?: () => void) => () => {
    callback?.();
    handleCloseDropdown();
  };

  const handleKeydown = (evt: KeyboardEvent) => {
    if (evt.key === 'Escape') {
      setOpened(false);
    }
  };

  if (!portalContainer) {
    return null;
  }

  return (
    <>
      <Elements.SToggle
        onClick={(e) => {
          e.stopPropagation();
          setOpened((prev) => !prev);
        }}
        ref={$toggleRef}
        className={clsx({ [toggleClassName]: !!toggleClassName })}
      >
        {toggle}
      </Elements.SToggle>
      {ReactDOM.createPortal(
        <AnimatePresence exitBeforeEnter>
          {opened && (
            <Elements.SMenu
              as={motion.div}
              key={'dropdown'}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
              ref={$menuRef}
              style={{
                position: 'fixed',
                left: `${leftOffset - leftAdjustment}px`,
                top: `${topOffset}px`,
                zIndex: 3000,
              }}
            >
              {items.map((item, idx) => (
                <DropdownMenuItem key={item.title} {...item} onClick={handleItemClick(item.onClick)} letOverflow={letChildrenOverflow} />
              ))}
            </Elements.SMenu>
          )}
        </AnimatePresence>,
        portalContainer
      )}
    </>
  );
};

export default DropdownMenu;
