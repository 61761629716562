import { THEMES } from 'app/constants';
import styled from 'styled-components';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

interface IArrowProps {
  $disabled?: boolean;
  hidden?: boolean;
  $theme: THEMES;
  $spaced?: boolean;
  strokeWidth?: number;
  onClick?: any;
  $offset?: number;
}
interface IArrowDiv extends IArrowProps {
  $next?: boolean;
}

export const SliderArrow = styled.div<IArrowDiv>`
  position: absolute;
  top: 50%;
  width: 37px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(p) => (p.$next ? 'right: 0;' : 'left: 0;')}
  transform: translate(${(p) => (p.$offset || 0) * (p.$next ? 1 : -1) + 'px'}, -50%);
  &:hover {
    filter: brightness(1.1);
    opacity: 0.5;
  }
  ${(p) => p.$disabled && 'opacity: .4; pointer-events: none;'}
  transition: all .3s;
  color: ${(p) => (p.$theme === THEMES.DARK ? 'rgb(175, 180, 184)' : 'rgb(28, 29, 38)')} !important;
  opacity: 0.15;
  cursor: pointer;
  ${(p) => p.hidden && 'display: none;'}
  & svg {
    width: 24px;
    height: 24px;
  }
`;

export const SliderArrowPrev = (props: IArrowProps) => (
  <SliderArrow $offset={props.$offset} $theme={props.$theme} $disabled={props.$disabled}>
    <BsChevronLeft strokeWidth={props.strokeWidth} onClick={props.onClick} />
  </SliderArrow>
);
export const SliderArrowNext = (props: IArrowProps) => (
  <SliderArrow $offset={props.$offset} $theme={props.$theme} $disabled={props.$disabled} $next>
    <BsChevronRight strokeWidth={props.strokeWidth} onClick={props.onClick} />
  </SliderArrow>
);
