import { LegacyRef, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Slider from 'react-slick';
import { Dot } from './components/dot';
import * as Bottom from './components/arrows-bottom';
import * as Sides from './components/arrows-sides';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import './styles.scss';
import { CustomSlide } from './components/slide';
import clsx from 'clsx';
import { useDisplay } from 'hooks';

type Props = {
  slides: any[];
  arrows?: 'sides' | 'bottom' | 'none' | 'buttonsSides';
  arrowsOffset?: number;
  variableWitdh?: boolean;
  dots?: boolean;
  dotsOffsetY?: number;
  isWidthOfPage?: boolean;
  slidesToShow?: number;
  slidesToScroll?: number;
  centerMode?: boolean;
  containerWidth?: number;
  slideWidth?: number;
  slideHeight?: number;
  slideSizeOfSlider?: boolean;
  containerDivProps?: any;
  sliderDivProps?: any;
  autoplay?: number;
  infinite?: boolean;
  loop?: boolean;
  spaced?: boolean;
  shadowed?: boolean;
  gap?: number;
  speed?: number;
  decreaseSlideWidthBy?: number;
  containerClassName?: string;
  sliderClassName?: string;
  swipe?: boolean;
  listOverflow?: 'hidden' | 'visible';
  onSlideChange?: (i: number) => void;
  refetchOptions?: {
    refetchFn: () => void;
    isFetching?: boolean;
    totalItems: number;
  };
  shadowsWidth?: number;
  disableShadow?: 'left' | 'right';
  removeSpacing?: 'removeX' | 'removeY';
};

const UniversalSlider = ({
  slides,
  arrows = 'none',
  arrowsOffset,
  dots,
  dotsOffsetY,
  variableWitdh,
  slidesToScroll,
  slidesToShow,
  centerMode,
  containerWidth,
  slideWidth,
  slideHeight,
  sliderDivProps,
  containerDivProps,
  autoplay,
  infinite,
  loop = false,
  spaced,
  removeSpacing,
  shadowed,
  gap,
  speed = 700,
  slideSizeOfSlider,
  decreaseSlideWidthBy,
  sliderClassName,
  containerClassName,
  isWidthOfPage,
  listOverflow = 'hidden',
  swipe = true,
  onSlideChange,
  shadowsWidth = 50,
  disableShadow,
  refetchOptions,
}: Props) => {
  const theme = useSelector(getCurrentTheme);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);
  const containerRef = useRef(null);
  const [dynamicBoxWidth, setDBW] = useState(0);
  const { viewportWidth } = useDisplay();

  useEffect(() => {
    if (containerRef.current) {
      if (containerWidth) {
        setDBW(containerWidth);
      } else {
        const dynamicWidth = (containerRef.current as HTMLElement).getBoundingClientRect().width;
        setDBW(dynamicWidth);
      }
    }
  }, [containerRef.current, viewportWidth]);

  const memoedSlides = useMemo(() => {
    return slides.map((el, index) => (
      <CustomSlide
        key={'slider-item' + index}
        $gap={gap}
        $width={isWidthOfPage ? viewportWidth : slideSizeOfSlider ? containerWidth ?? dynamicBoxWidth ?? slideWidth : slideWidth || undefined}
        $height={slideHeight}
        $decreaseWidth={decreaseSlideWidthBy}
        $hideOverflow={isWidthOfPage}
      >
        {el}
      </CustomSlide>
    ));
  }, [slides, dynamicBoxWidth, decreaseSlideWidthBy, viewportWidth, isWidthOfPage, slideSizeOfSlider, containerWidth, slideWidth, slideHeight]);

  const isFinalSlide = useMemo(
    () => slides.length - currentSlide - 1 < (slidesToShow || slidesToScroll || 1),
    [slides.length, currentSlide, slidesToScroll]
  );
  const showArrows = useMemo(() => slides.length > (slidesToScroll || slidesToScroll || 1) && !!arrows, [slides.length]);

  useEffect(() => {
    if (refetchOptions) {
      const { refetchFn, totalItems, isFetching } = refetchOptions;
      if (isFinalSlide && memoedSlides.length < totalItems && !isFetching) {
        refetchFn();
      }
    }
  }, [refetchOptions, isFinalSlide]);

  const goTo = useCallback(
    (i: number) => {
      if (!!sliderRef.current) {
        //@ts-ignore
        sliderRef.current.slickGoTo(i);
      }
    },
    [sliderRef]
  );

  const handleNext = useCallback(() => {
    if (!!sliderRef.current) {
      if (!isFinalSlide || infinite) {
        //@ts-ignore
        sliderRef.current.slickNext();
      } else if (!!loop) {
        goTo(0);
      }
    }
  }, [isFinalSlide, sliderRef, loop]);

  const handlePrev = useCallback(() => {
    if (!!sliderRef.current) {
      if (currentSlide != 0 || infinite) {
        //@ts-ignore
        sliderRef.current.slickPrev();
      } else if (!!loop) {
        goTo(slides.length - 1);
      }
    }
  }, [currentSlide, sliderRef, loop, isFinalSlide]);

  useEffect(() => {
    if (onSlideChange) {
      onSlideChange(currentSlide);
    }
  }, [currentSlide, onSlideChange]);

  const arrowsByType = {
    bottom: (
      <Bottom.SliderArrows>
        <Bottom.SliderArrow $disabled={!infinite && !loop && currentSlide === 0} $theme={theme} onClick={handlePrev} />
        <Bottom.SliderArrow $disabled={!infinite && !loop && isFinalSlide} $theme={theme} onClick={handleNext} next />
      </Bottom.SliderArrows>
    ),
    sides: (
      <>
        <Sides.SliderArrowPrev
          $offset={arrowsOffset}
          strokeWidth={1.5}
          $spaced={!!spaced}
          $disabled={!infinite && !loop && currentSlide === 0}
          $theme={theme}
          onClick={handlePrev}
        />
        <Sides.SliderArrowNext
          $offset={arrowsOffset}
          strokeWidth={1.5}
          $spaced={!!spaced}
          $disabled={!infinite && !loop && isFinalSlide}
          $theme={theme}
          onClick={handleNext}
        />
      </>
    ),
    buttonsSides: (
      <>
        <Bottom.SliderButtonSide $offset={arrowsOffset} $disabled={!infinite && !loop && currentSlide === 0} $theme={theme} onClick={handlePrev} />
        <Bottom.SliderButtonSide $offset={arrowsOffset} $disabled={!infinite && !loop && isFinalSlide} $theme={theme} onClick={handleNext} next />
      </>
    ),
    none: <></>,
  };

  return (
    <div className={clsx('universal-slider', containerClassName)} {...containerDivProps} ref={containerRef}>
      <div
        className={clsx(
          'slider-container',
          sliderClassName,
          centerMode,
          { shadowed },
          { spaced: spaced || shadowed },
          removeSpacing,
          `list-overflow-${listOverflow}`
        )}
        style={{ width: containerWidth ? `${containerWidth}px` : `100%`, height: slideHeight || 'auto' }}
        {...sliderDivProps}
      >
        <Slider
          ref={sliderRef as LegacyRef<Slider> | undefined}
          adaptiveHeight
          infinite={infinite}
          variableWidth={variableWitdh}
          arrows={false}
          centerMode={centerMode}
          slidesToScroll={slidesToScroll}
          slidesToShow={slidesToShow}
          dots={dots}
          autoplay={!!autoplay}
          autoplaySpeed={autoplay}
          speed={speed}
          swipe={swipe}
          swipeToSlide={swipe}
          customPaging={(i: number) => {
            return <Dot key={'slider-dot-' + i} transformY={dotsOffsetY} $theme={theme} active={i === currentSlide} onClick={() => goTo(i)} />;
          }}
          afterChange={(curr) => setCurrentSlide(curr)}
        >
          {memoedSlides}
        </Slider>
        {!!shadowed && (
          <>
            {disableShadow != 'left' && <div style={{ width: shadowsWidth }} className="shadow left" />}
            {disableShadow != 'right' && <div style={{ width: shadowsWidth }} className="shadow right" />}
          </>
        )}
      </div>
      {showArrows && arrowsByType[arrows]}
    </div>
  );
};

export default memo(UniversalSlider);
