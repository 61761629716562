import { ErrorHandler } from 'shared/utils/ErrorHandler';
import { store } from 'store';
import { leaderboardApi } from 'store/leaderboard';

const leaderboardLoader = () => {
  const leaderBoardFromLoader = store.dispatch(leaderboardApi.endpoints.getLeaderboardUsers.initiate());

  try {
    const responseLeaderboard = leaderBoardFromLoader.unwrap().catch(ErrorHandler);

    return {
      leaderBoardFromLoader: responseLeaderboard,
    };
  } catch (e) {
    throw new Error('An error occurred while loading courses.');
  } finally {
    leaderBoardFromLoader.unsubscribe();
  }
};

export default leaderboardLoader;
