import clsx from 'clsx';
import { ELinks, EMenuScreens, ROUTES, THEMES } from 'app/constants';
import { useSelector } from 'react-redux';
import { getAppSettings, getCurrentTheme } from 'store';
import { useDisplay } from 'hooks';

import { Link, useLocation } from 'react-router-dom';
// import { ReactComponent as Twitter } from 'assets/icons/socials/twitter.svg';
// import { ReactComponent as Youtube } from 'assets/icons/socials/youtube.svg';
// import { ReactComponent as Medium } from 'assets/icons/socials/medium.svg';
import { ReactComponent as Discord } from 'assets/icons/socials/discord-circle.svg';
import { ReactComponent as TwitterLight } from 'assets/icons/socials/twitter--light.svg';
import { ReactComponent as YoutubeLight } from 'assets/icons/socials/youtube--light.svg';
import { ReactComponent as MediumLight } from 'assets/icons/socials/medium--light.svg';
import { ReactComponent as Telegram } from 'assets/icons/socials/telegram-circle.svg';
import Instagram from 'assets/icons/socials/insta.png';
import { Logo } from 'shared/utils';

import styles from './styles.module.scss';

const icons: any = {
  twitter: {
    [THEMES.DARK]: <TwitterLight />,
    // [THEMES.DARK]: <Twitter />,
    [THEMES.LIGHT]: <TwitterLight />,
  },
  discord: {
    [THEMES.LIGHT]: <Discord />,
    [THEMES.DARK]: <Discord />,
  },
  youtube: {
    [THEMES.DARK]: <YoutubeLight />,
    // [THEMES.DARK]: <Youtube />,
    [THEMES.LIGHT]: <YoutubeLight />,
  },
  medium: {
    [THEMES.DARK]: <MediumLight />,
    // [THEMES.DARK]: <Medium />,
    [THEMES.LIGHT]: <MediumLight />,
  },
  telegram: {
    [THEMES.DARK]: <Telegram />,
    [THEMES.LIGHT]: <Telegram />,
  },
  instagram: {
    [THEMES.DARK]: <img src={Instagram}></img>,
    [THEMES.LIGHT]: <img src={Instagram}></img>,
  },
};

export default function Footer() {
  const { contactLink } = useSelector(getAppSettings);
  const theme = useSelector(getCurrentTheme);
  const { pathname, search } = useLocation();
  const { isMobile } = useDisplay();

  const re = new RegExp(`^${ROUTES.OTHER_PROFILE}.*`);
  const routes = [ROUTES.MAIN, ROUTES.SHOP, ROUTES.MAIN, ROUTES.PLAY, ROUTES.INFO];
  const screens = [EMenuScreens.INVENTORY, EMenuScreens.DESIGN, EMenuScreens.SETTINGS];

  // @ts-ignore
  if (!screens.includes(search.split('=')[1]) && (routes.includes(pathname) || re.test(pathname))) {
    return (
      <div className={clsx(styles.container, styles[theme])} style={{ height: isMobile ? 711 : 315 }}>
        <Logo containerClassName={styles.logo} withLabel height={38} size="xsmall" />
        <p className={styles.description}>
          The educated differ from the uneducated as much as the living from the dead. <br></br>©Aristotle
        </p>
        <p className={styles.copyright}>Copyright © 2024</p>
        <div className={styles.links}>
          <div className={styles.linkslist}>
            <span className={styles.contact}>
              Contact: <span className={styles.contactLink}>{contactLink}</span>
            </span>
            {/*<Link to="/">Team</Link>*/}
            <Link to="/docs">Docs & Info</Link>
            <a href={ELinks.LANDING} target="_blank" rel="noreferrer">
              Landing
            </a>
          </div>
          <div className={styles.linkslist}>
            {/*<Link to="/">Mighty Learn</Link>*/}
            {/*<Link to="/">Mighty Marketplace</Link>*/}
            {/*<Link to="/">Mighty Mobile App</Link>*/}
          </div>
        </div>
        <div className={styles.socials}>
          <a href={ELinks.TWITTER} target="_blank" rel="noreferrer">
            {icons.twitter[theme]}
          </a>
          <a href={ELinks.YOUTUBE} target="_blank" rel="noreferrer">
            {icons.youtube[theme]}
          </a>
          <a href={ELinks.MEDIUM} target="_blank" rel="noreferrer">
            {icons.medium[theme]}
          </a>
          <a href={ELinks.DISCORD} target="_blank" rel="noreferrer">
            {icons.discord[theme]}
          </a>
          <a href={ELinks.TELEGRAM} target="_blank" rel="noreferrer">
            {icons.telegram[theme]}
          </a>
          <a href={ELinks.INSTAGRAM} target="_blank" rel="noreferrer">
            {icons.instagram[theme]}
          </a>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
