import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'app/constants';
import { SendCodeModal, SetNewPasswordModal, CheckCodeModal } from 'features/auth/resetPassword';
import { SuccessAuthModal } from 'features/auth';
// import { WalletModal } from 'features/auth/ui';
import { ICheckCodeForm, IResetPasswordBody, ISendCodeForm, ISetNewPasswordForm } from 'interface';
import { dispatch } from 'hooks';
import {
  setIsShowSuccessAuthModal,
  useResetPasswordCheckCodeMutation,
  useResetPasswordSendCodeMutation,
  useResetPasswordSetNewPasswordMutation,
} from 'store';
import { useTranslation } from 'react-i18next';
import { useGlobalPageAnimation } from '../../../shared/utils/GlobalPageAnimation/useGlobalPageAnimation';
import { toast } from 'react-toastify';
import Answer from 'components/library/messages/Answer';

enum RESET_PASSWORD_STEP {
  send_code,
  check_code,
  set_new_pass,
  success,
}

export const ResetPasswordPage = () => {
  const { t } = useTranslation('auth');
  // const { login: loginMetaMask } = useAuthMetaMask();
  const navigate = useNavigate();
  // const [showWalletModal, setShowWalletModal] = useState<boolean>(false);
  const [resetPasswordState, setResetPasswordState] = useState<IResetPasswordBody>({});

  const [step, setStep] = useState<RESET_PASSWORD_STEP>(RESET_PASSWORD_STEP.send_code);

  const [sendCode, { error: sendCodeError, isLoading: sendCodeIsLoading }] = useResetPasswordSendCodeMutation();
  const [checkCode, { error: checkCodeError, isLoading: checkCodeIsLoading }] = useResetPasswordCheckCodeMutation();
  const [setNewPassword, { error: setNewPasswordError, isLoading: setNewPasswordIsLoading }] = useResetPasswordSetNewPasswordMutation();

  useGlobalPageAnimation({ dependency: [false] });

  const onCloseModalHandler = () => {
    navigate(ROUTES.LOGIN);
    setResetPasswordState({});
    dispatch(setIsShowSuccessAuthModal(false));
  };
  // const openWalletModal = () => {
  // setShowWalletModal(true);
  // };
  // const closeWalletModal = () => {
  //   setShowWalletModal(false);
  // };
  // const metaMaskHandler = async () => {
  //   await loginMetaMask();
  // };
  // const walletHandler = () => {
  //   console.log('walletHandler action');
  // };
  const onSubmitSendCodeHandler = async (data: ISendCodeForm) =>
    sendCode(data)
      .unwrap()
      .then(() => {
        setResetPasswordState({ ...resetPasswordState, email: data.email });
        setStep(RESET_PASSWORD_STEP.check_code);
      })
      .catch((ex) => {
        console.error(ex);
      });

  const getCodeAgainHandler = async () => {
    if (resetPasswordState.email) {
      await onSubmitSendCodeHandler({ email: resetPasswordState.email });
    }
  };

  const onSubmitCheckCodeHandler = async (data: ICheckCodeForm) =>
    checkCode({ ...data, email: resetPasswordState.email })
      .unwrap()
      .then(() => {
        setResetPasswordState({ ...resetPasswordState, resetCode: data.resetCode });
        setStep(RESET_PASSWORD_STEP.set_new_pass);
      })
      .catch((ex) => {
        console.error(ex);
      });

  const onSubmitSetNewPasswordHandler = async (data: ISetNewPasswordForm) =>
    setNewPassword({ password: data.password, ...resetPasswordState })
      .unwrap()
      .then(() => {
        setResetPasswordState({});
        setStep(RESET_PASSWORD_STEP.success);
      })
      .catch((ex) => {
        console.error(ex);
        toast.error(<Answer type="incorrect" label={ex?.data?.message ?? 'Something went wrong...:/'} />);
      });

  // if (showWalletModal) {
  //   return <WalletModal open={showWalletModal} onClose={closeWalletModal} metaMaskHandler={metaMaskHandler} walletHandler={walletHandler} />;
  // }
  return (
    <>
      <SendCodeModal
        //TODO change error type
        error={(sendCodeError as any)?.data?.message}
        open={step === RESET_PASSWORD_STEP.send_code}
        openWalletModal={() => {}}
        onCloseModalHandler={onCloseModalHandler}
        isLoading={sendCodeIsLoading}
        onSubmitHandler={onSubmitSendCodeHandler}
      />
      <CheckCodeModal
        //TODO change error type
        error={(checkCodeError as any)?.data?.message}
        getCodeAgainHandler={getCodeAgainHandler}
        open={step === RESET_PASSWORD_STEP.check_code}
        onCloseModalHandler={onCloseModalHandler}
        isLoading={checkCodeIsLoading}
        onSubmitHandler={onSubmitCheckCodeHandler}
      />
      <SetNewPasswordModal
        //TODO change error type
        error={(setNewPasswordError as any)?.data?.message}
        open={step === RESET_PASSWORD_STEP.set_new_pass}
        onCloseModalHandler={onCloseModalHandler}
        isLoading={setNewPasswordIsLoading}
        onSubmitHandler={onSubmitSetNewPasswordHandler}
      />
      <SuccessAuthModal
        open={step === RESET_PASSWORD_STEP.success}
        onClose={onCloseModalHandler}
        title={t('resetPassword.success.title')}
        subTitle={t('resetPassword.success.subTitle')}
      />
    </>
  );
};
