import styled, { css } from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import { GRADES } from 'app/constants';

export const Wrapper = styled.div(
    () => css`
      display: flex;
      align-items: center;
      gap: 30px;
      @media screen and (max-width: 992px) {
        justify-content: center;
      }
    `
  ),
  Card = styled.div<{ $current: boolean; $previous: boolean; $colorBg: string; $variant: 'banner' | 'panel' }>(
    ({ $current, $previous, $colorBg, $variant }) => css`
      position: relative;
      width: ${$current ? '105px' : '66px'};
      height: ${$current ? '119px' : '74px'};
      color: var(--font-color-strong);
      padding-top: 10px;
      padding-right: ${$current ? '14px' : '10px'};
      background: linear-gradient(225deg, ${hexToRgba($colorBg, 0.6)} 5.14%, rgba(241, 79, 99, 0) 90.44%);
      border-radius: 6px;
      &.light {
        border: 1px solid ${hexToRgba($colorBg, 0.6)};
        background: linear-gradient(225deg, ${hexToRgba($colorBg, 0.6)} 5.14%, rgba(255, 255, 255, 1) 90.44%);

        &.${GRADES.UNCOMMON} {
          background: linear-gradient(225deg, rgba(32, 255, 205, 0.5) 5.14%, rgba(255, 255, 255, 1) 90.44%);
        }
      }
      filter: ${$previous ? 'grayscale(1)' : ''};

      ${TextWrap} {
        max-width: ${$current ? '45px' : '30px'};
      }

      ${Image} {
        width: ${$current ? '120px' : '65px'};
        left: ${$current ? '-45px' : '-20px'};
        bottom: ${$current ? '-35px' : '-16px'};
        z-index: ${$current ? '1' : '0'};
      }

      ${Date} {
        font-size: ${$current ? '16px' : '12px'};
      }

      ${Quantity} {
        font-size: ${$current ? '26px' : '18px'};
      }

      ${$variant === 'panel' &&
      css`
        ${Today} {
          position: absolute;
          top: -25px;
          left: 0;
          width: 100%;
          text-align: center;
        }
      `}

      .daily-check {
        aspect-ratio: 1 / 1;
        width: 200px;
        height: 200px;
        transform: translate(-50%, -50%);
        position: absolute;
        top: 105px;
        left: 90px;
        z-index: 2;
        border-radius: 5px;
        transition: all 0.3s ease-in-out;
      }
      .success-check {
        aspect-ratio: 1 / 1;
        width: 350px;
        height: 350px;
        position: absolute;
        left: -50px;
        top: 0;
      }

      ${Undercolor} {
        &:after {
          background: ${$colorBg};
        }
      }
    `
  ),
  Image = styled.img(
    () => css`
      position: absolute;
    `
  ),
  CheckContainer = styled.div(
    () => css`
      position: absolute;
      width: fit-content;
      height: fit-content;
      z-index: 10;
    `
  ),
  TextWrap = styled.div(
    () => css`
      margin-left: auto;
    `
  ),
  Today = styled.p(
    () => css`
      color: inherit;
      font-size: 16px;
      font-weight: 500;
      line-height: 100%;
      margin-bottom: 4px;
    `
  ),
  Date = styled.p(
    () => css`
      color: inherit;
      font-weight: 400;
      line-height: 100%;
      white-space: nowrap;
    `
  ),
  Quantity = styled.p(
    () => css`
      color: inherit;
      font-weight: 500;
      line-height: 100%;
    `
  ),
  Name = styled.div<{ $color: string }>(
    ({ $color }) => css`
      color: ${$color};
      position: absolute;
      z-index: 3;
      transform: translateY(244px) translateX(-132px);
      text-align: center;
      width: 368px;

      .name {
        color: inherit;
        font-size: 28px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.28px;
        margin-bottom: 4px;
      }

      .grade {
        color: inherit;
        font-size: 16px;
        line-height: 100%;
        letter-spacing: -0.16px;
      }
    `
  ),
  Undercolor = styled.div(
    () => css`
      position: absolute;
      top: -30px;
      left: -130px;
      pointer-events: none;

      svg {
        filter: blur(10px);
      }
    `
  );
