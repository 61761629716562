import { THEMES } from 'app/constants';

type Props = {
  isPlaying?: boolean;
  theme?: THEMES;
  index?: number;
};

export const Arc = ({ theme, isPlaying, index = 1 }: Props) => {
  return (
    <svg width={'100%'} height={'100%'} viewBox="0 0 200 200" className={`arc${index} center-positioned ${isPlaying ? 'playing' : ''}`}>
      <circle
        className="circle"
        cx="100"
        cy="100"
        r="96"
        stroke={theme === THEMES.DARK ? '#ffffff33' : '#6487EF33'}
        fill="none"
        strokeWidth="8"
        strokeDasharray="620"
        strokeDashoffset="620"
        strokeLinecap="round"
        transform="rotate(-90, 100, 100)"
      ></circle>
      <circle
        className="circle"
        cx="100"
        cy="100"
        r="96"
        stroke={theme === THEMES.DARK ? '#ffffff' : '#6487EF'}
        fill="none"
        strokeWidth="4"
        strokeDasharray="620"
        strokeDashoffset="620"
        strokeLinecap="round"
        transform="rotate(-90, 100, 100)"
      ></circle>
    </svg>
  );
};
