import clsx from 'clsx';
import { CourseCardProps } from '../types';

// import { ReactComponent as DotsIcon } from 'assets/icons/dots-vertical.svg';
// import { PencilIcon, TrashIcon } from 'assets/icons/icons';
// import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';

import { AddFavouriteButton } from '../AddFavouriteButton';
import { TooltippedToggle } from 'shared/ui/toggle';
// import IconButton from 'components/library/iconButton';
// import DropdownMenu from 'components/library/dropdownMenu';
// import CoverLoader from './CoverLoader';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';

import * as Styled from './styles';
import { shallowEqual, useSelector } from 'react-redux';
import { getUserData } from 'store';
import { USER_ROLES } from 'app/constants';
// import SpaceCourseEdit from './SpaceCourseEdit';
// import { useDisplay } from 'hooks';

type TProps = { courseId?: string } & Pick<
  CourseCardProps,
  | 'className'
  | 'isAnyLoading'
  | 'isFavourite'
  | 'isEditMode'
  | 'isInDev'
  | 'isLoading'
  | 'toggleFav'
  | 'handleImageUpload'
  | 'handleToggleDev'
  | 'handleOpenConfirmationModal'
  | 'handleOpenEditModal'
  | 'handleDuplication'
  | 'isInSpace'
  | 'openEditorInSpace'
  | 'isSpaceAdmin'
>;

export const CourseCardMenu = ({
  className,
  isAnyLoading,
  isFavourite,
  isEditMode,
  isInDev,
  isLoading,
  toggleFav = () => {},
  handleToggleDev,
}: TProps) => {
  const { roles } = useSelector(getUserData, shallowEqual);

  const isAdmin = roles?.includes(USER_ROLES.ADMIN);

  return (
    <Styled.Container className={className}>
      {isAnyLoading ? (
        <Styled.Loader as={MightyLoaderMini} />
      ) : (
        <>
          <AddFavouriteButton onClick={toggleFav} disabled={isLoading} isFavourite={isFavourite} className={clsx('even menuItem')} />
          {isEditMode && (
            <>
              {isAdmin && (
                <TooltippedToggle
                  header="Course public status"
                  description="This toggles if this course is published for completion, and if so - will be displayed at courses page."
                  className={clsx('dev menuItem')}
                  checked={!isInDev}
                  onChange={handleToggleDev}
                />
              )}
            </>
          )}
        </>
      )}
    </Styled.Container>
  );
};
