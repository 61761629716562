import styled, { css } from 'styled-components';
import bgImage from 'assets/backgrounds/welcome-bg.webp';
import { motion } from 'framer-motion';

export const Container = styled.div(
    () => css`
      background-image: url(${bgImage});
      background-position: top -190px center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      position: fixed;
      top: 0;
    `
  ),
  Wrap = styled(motion.div)(
    () => css`
      border-radius: 40px 40px 0 0;
      border-top: 2px solid #36373f;
      background: rgba(19, 26, 41, 0.8);
      backdrop-filter: blur(100px);
      width: 100%;
      position: relative;

      .download-app__background {
        position: absolute;
        top: -2px;
        height: 101%;
        width: 100%;
        border-radius: 40px 40px 0 0;
      }
    `
  ),
  Content = styled.div<{ $isDetected: boolean }>(
    ({ $isDetected }) => css`
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-right: 20px;
      padding-left: 20px;
      padding-bottom: 34px;
      color: #fff;

      .download-app__title {
        color: inherit;
        font-size: 35px;
        font-weight: 500;
        line-height: 115%;
        letter-spacing: 0.5px;
        text-align: center;
        margin-bottom: 8px;
      }

      .download-app__text {
        color: inherit;
        font-size: 18px;
        line-height: ${$isDetected ? '160%' : '115%'};
        letter-spacing: 0.5px;
        text-align: center;
        margin-bottom: 28px;

        .share {
          margin: 0 -3px -3px;
        }

        .plus {
          margin-bottom: -2px;
        }

        span {
          color: var(--mighty-blue);
          font-weight: 700;
        }

        svg {
          color: var(--mighty-blue);
        }
      }
    `
  ),
  MightyIcon = styled.div(
    () => css`
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: -65px 0 24px 0;

      .logo-container {
        position: absolute;
        width: 105px;
        img {
          width: 100%;
        }
      }
    `
  ),
  HelpText = styled.span<{ $margin?: string }>(
    ({ $margin }) => css`
      color: #ffffff;
      font-size: 18px;
      line-height: 115%;
      letter-spacing: 0.5px;
      text-decoration-line: underline;
      opacity: 0.4;
      margin: ${$margin ? $margin : '0'};
    `
  );
