import { FC, memo, useEffect, useState } from 'react';
import { getItemByKey } from 'utils';
import { AnimatePresence } from 'framer-motion';
import { shallowEqual, useSelector } from 'react-redux';
import { getItems, getIsMainMenu, getUserData, setIsOpenSidePanel, getIsOpenSidePanel, setShopItem, getShopItem, getCurrentTheme } from 'store';
import { dispatch, useDisplay } from 'hooks';
import useBuyItem from '../../pages/shop/hooks/useBuyItem';
import clsx from 'clsx';

import Sidepanel from 'shared/ui/sidepanel/sidepanel';
import ShopItemInfo from 'components/pages/shop/shop-item-info/item-info';
import { MobileModal } from 'shared/utils/mobile';

import styles from 'components/pages/shop/styles.module.scss';

const ShopItemsControllerUI: FC = () => {
  const {
    buyItem,
    data: { isLoading: isBuying },
  } = useBuyItem();
  const { item: currentItem, type, itemId } = useSelector(getShopItem, shallowEqual);
  const { isMobile } = useDisplay();
  const { _id } = useSelector(getUserData, shallowEqual);
  const isMain = useSelector(getIsMainMenu, shallowEqual);
  const items = useSelector(getItems, shallowEqual);
  const theme = useSelector(getCurrentTheme, shallowEqual);
  const { shopPanel } = useSelector(getIsOpenSidePanel, shallowEqual);
  const [isOpen, setIsOpen] = useState(Boolean(shopPanel));

  useEffect(() => {
    const show = !!itemId || !!currentItem?.item.name || isOpen;
    setIsOpen(show);
    dispatch(setIsOpenSidePanel({ shopPanel: show }));
  }, [itemId, currentItem]);

  useEffect(() => {
    if (itemId === 0 || itemId === null) {
      handleCloseSidepanel();
    }
  }, [itemId]);

  if (!_id) return null;

  const isShopItemCaptured = typeof itemId === 'number' && Boolean(getItemByKey('id', itemId, items));
  const isNFTCaptured = !itemId && currentItem?.item.name;

  function handleCloseSidepanel() {
    setIsOpen(false);
    dispatch(setShopItem({ item: null, itemId: null }));
    dispatch(setIsOpenSidePanel({ shopPanel: false }));
  }

  const shopItemInfoComponent = isShopItemCaptured ? (
    <ShopItemInfo
      key={'shop-sideitem-N' + itemId}
      handleBuy={buyItem}
      isLoading={!currentItem || isBuying}
      itemId={itemId}
      type={type}
      itemProvided={currentItem}
      handleCloseSidePanel={handleCloseSidepanel}
    />
  ) : (
    <></>
  );

  return isMobile ? (
    <MobileModal isOpen={isOpen} onClose={handleCloseSidepanel} classNameContainer={`${styles[theme]} ${!currentItem ? styles.loading : ''}`}>
      {shopItemInfoComponent}
    </MobileModal>
  ) : (
    <AnimatePresence exitBeforeEnter>
      <Sidepanel
        key={'shop-sidepanel-N' + itemId}
        initial={{ opacity: 0, x: 400 }}
        animate={{ opacity: 1, x: -32 }}
        exit={{ opacity: 0, x: 400 }}
        transition={{ type: 'just' }}
        isShown={isOpen}
        onClose={handleCloseSidepanel}
        classNames={clsx(styles.shoppanel, {
          [styles.shoppanelHidden]: !isOpen,
          [styles.main]: isMain,
          [styles.NFTCaptured]: isNFTCaptured,
        })}
      >
        {shopItemInfoComponent}
      </Sidepanel>
    </AnimatePresence>
  );
};

export const ShopItemsController = memo(ShopItemsControllerUI);
