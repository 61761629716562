import { TButtonsProps } from './types';
import PreaparedButton from './styledButton';
import style from './style.module.scss';
import { useEVMAuth } from 'hooks';
import { useEffect } from 'react';

const EVMLinkButton = ({ title, handleClose, setStage, stage }: TButtonsProps) => {
  const { handleAttach, isAttaching, handleAuthentification, openModal, authData, isConnected, address } = useEVMAuth();

  const attach = async () => {
    setStage('progress');
    handleAttach().then(() => {
      handleClose();
      setStage('success');
      setTimeout(() => {
        setStage('initial');
      }, 1000);
    });
  };

  useEffect(() => {
    if (isConnected && address) {
      handleAuthentification();
    }
  }, [isConnected, address]);

  useEffect(() => {
    if (authData) {
      attach();
    }
  }, [authData]);

  const handleModal = () => {
    setStage('initial');
    openModal();
  };

  return (
    <div className={style.wrapper}>
      <PreaparedButton onClick={handleModal} isDisabled={isAttaching || stage === 'error'} title={title} />{' '}
    </div>
  );
};

export default EVMLinkButton;
