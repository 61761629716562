import { FC, PropsWithChildren } from 'react';
import clsx from 'clsx';

import { Logo } from 'shared/utils';

import { ReactComponent as Background } from 'features/DownloadApp/assets/background.svg';
import { ReactComponent as IconGradient } from 'features/DownloadApp/assets/mighty-icon--gradient.svg';
import style from '../desktop/styles.module.scss';
import * as Styled from './styled';
import { BackButton } from 'components/library/BackButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getPartnerId } from 'store';
import { ROUTES } from 'app/constants';

export const AuthPageViewMobile: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const { key, state } = useLocation();
  const partnerId = useSelector(getPartnerId);

  const handleBackButtonClick = () => {
    if (partnerId) {
      navigate(`/space/${partnerId}?partnerID=${partnerId}`);
      return;
    }
    if (state && state.toMain) {
      navigate(ROUTES.MAIN);
      return;
    }
    if (key !== 'default') {
      navigate(-1);
      return;
    }
    navigate('/');
  };

  return (
    <Styled.Container>
      <BackButton className={style.authBackButton} onClick={handleBackButtonClick} text={''} />
      <Styled.Wrap initial={{ y: 400 }} animate={{ y: 0 }} transition={{ type: 'spring', bounce: 0 }}>
        <Styled.Content $isDetected={false}>
          <Styled.MightyIcon>
            <IconGradient />
            <Logo containerClassName={clsx('logo-container')} />
          </Styled.MightyIcon>
          <h2 className={'download-app__title'}>Welcome to Mighty</h2>
          <p className={'download-app__text'}>Learn | Play | Win rewards</p>
          {children}
        </Styled.Content>
        <Background className={'download-app__background'} />
      </Styled.Wrap>
    </Styled.Container>
  );
};
