import { useDisplay } from 'hooks';
import * as Styled from './styles';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import clsx from 'clsx';
import { useLocation, useParams } from 'react-router-dom';

export const TitleTransitionBar = () => {
  const { isMobile } = useDisplay();
  const theme = useSelector(getCurrentTheme);
  const location = useLocation();
  const { spaceName } = useParams();

  const excludedPaths = ['/', '/shop', '/courses', '/play', '/spaces'];

  const isExcluded = excludedPaths.includes(location.pathname);

  if (!isMobile || isExcluded || !!spaceName) {
    return null;
  }

  return <Styled.Container className={clsx('brow', theme)} />;
};
