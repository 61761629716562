import { ICourseFilteringGroup } from 'components/pages/study/courses/hooks';
import { useGetIsAuth } from './useGetStoreGeneralData';
import { useEffect, useMemo } from 'react';
import { useGetUserData } from './useGetUserData';
import { useGetCourseGroups } from './useGetCourseGroups';

export const useGetDefaultCourseGroups = () => {
  const isAuth = useGetIsAuth();
  const { interests } = useGetUserData();
  const { tags } = useGetCourseGroups();

  const defaultGroups = useMemo(() => {
    if (!isAuth) {
      return [];
    }
    const lsGroups = localStorage.getItem('course-groups') || '[]';
    const parsedGroups = JSON.parse(lsGroups);
    if (Array.isArray(parsedGroups) && parsedGroups?.length) {
      return parsedGroups as ICourseFilteringGroup[];
    } else {
      return interests?.map((i) => tags[i]) || [];
    }
  }, [isAuth, interests, tags]);

  return defaultGroups;
};

export const useSaveSelectedCourseGroups = (groups: ICourseFilteringGroup[]) => {
  useEffect(() => {
    localStorage.setItem('course-groups', JSON.stringify(groups));
  }, [JSON.stringify(groups)]);
};
