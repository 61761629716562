import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useClientDetection } from '../../../utils/useClientDetection';
import { DownloadApp } from '../DownloadApp';
import { LOCAL_STORAGE_KEYS } from 'app/constants';

export const DownloadAppProvider: FC<PropsWithChildren> = ({ children }) => {
  const { isInstalledApp, isTelegram, isPartnerApp, os } = useClientDetection();

  const [cooldown, setCooldown] = useState<string | null>('');

  useEffect(() => {
    let probableCooldown = localStorage.getItem(LOCAL_STORAGE_KEYS.INSTALL_APP_CD);
    if (!!probableCooldown) {
      probableCooldown = JSON.parse(probableCooldown);
      setCooldown(probableCooldown);
      return;
    } else {
      setCooldown(null);
    }
  }, []);

  useEffect(() => {
    if (cooldown !== null) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.INSTALL_APP_CD, cooldown);
    }
  }, [cooldown]);

  if (cooldown === '') {
    return <>{children}</>;
  }

  if (!cooldown || new Date(cooldown).getTime() < new Date(Date.now()).getTime()) {
    if (!isTelegram && !isPartnerApp && !isInstalledApp && (os === 'iOS' || os === 'Android')) {
      return <DownloadApp setCooldown={setCooldown} />;
    }
  }

  return <>{children}</>;
};
