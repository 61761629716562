import { useLazyGetAllCoursesQuery } from 'store';
import { useGetIsAuth, useGetUserData } from 'hooks';
import { USER_ROLES } from 'app/constants';

interface IUseGetCourses {
  _id?: string | string[];
  groups?: string;
  page: number;
  limit: number;
}

export const useGetCourses = (isInDevelopment?: boolean) => {
  const isAuth = useGetIsAuth();
  const [getCourses, { isLoading, isSuccess, isFetching }] = useLazyGetAllCoursesQuery();
  const { _id, roles } = useGetUserData();

  const fetchCourses = async (query: IUseGetCourses & Record<string, any>) => {
    let additionalQuery: Record<string, any> = {};
    if (isAuth) {
      if (roles && !roles.includes(USER_ROLES.ADMIN) && isInDevelopment) {
        additionalQuery.authors = _id;
      }
      additionalQuery.isInDevelopment = Boolean(isInDevelopment);
    }
    return getCourses({ ...additionalQuery, ...query }).unwrap();
  };

  return { isLoading: isFetching || isLoading, fetchCourses, isSuccess };
};
