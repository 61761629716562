import { useTonConnectUI } from '@tonconnect/ui-react';
import { useLogOutMutation } from 'store';
import { useEVMAuth } from './useEVMAuth';

export const useLogout = () => {
  const [tonConnectUI] = useTonConnectUI();
  const [logOut] = useLogOutMutation();
  const { handleDisconnect } = useEVMAuth();

  const logOutHandler = async () => {
    await logOut()
      .unwrap()
      .then(() => {
        if (tonConnectUI.connected) {
          tonConnectUI.disconnect();
        }
        handleDisconnect();
      })
      .catch(console.error);
  };
  return logOutHandler;
};
