import { ROUTES } from 'app/constants';
import { FC, PropsWithChildren, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const PreventInvalidRoute: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const validRoutes = [
      { path: ROUTES.AUTH, regex: new RegExp(`^${ROUTES.AUTH}$`) },
      { path: ROUTES.AUTHEMAIL, regex: new RegExp(`^${ROUTES.AUTHEMAIL}$`) },
      { path: ROUTES.LOGIN, regex: new RegExp(`^${ROUTES.LOGIN}$`) },
      { path: ROUTES.SIGN_UP, regex: new RegExp(`^${ROUTES.SIGN_UP}$`) },
      { path: ROUTES.RESET_PASSWORD, regex: new RegExp(`^${ROUTES.RESET_PASSWORD}$`) },
      { path: ROUTES.ACTIVATION_CODE, regex: new RegExp(`^${ROUTES.ACTIVATION_CODE}`) },
      {
        path: ROUTES.SPACE_PARTNER_ID,
        regex: new RegExp('^/space/[^/]+$'),
      },
      {
        path: ROUTES.COURSES_OVERVIEW,
        regex: new RegExp('^/courses/[^/]+$'),
      },
      {
        path: ROUTES.COURSES_LESSON_PAGE,
        regex: new RegExp('^/courses/[^/]+/[^/]+/[^/]+$'),
      },
    ];

    const isValidRoute = validRoutes.some((route) => route.regex.test(location.pathname));

    if (!isValidRoute) {
      navigate(-1);
    }
  }, [location, navigate]);

  return <>{children}</>;
};

export default PreventInvalidRoute;
