import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDisplay, useGetSettings } from 'hooks';
import { getPartnerId } from 'store';
import { LOCAL_STORAGE_KEYS, ROUTES } from 'app/constants';
import { motion } from 'framer-motion';

import GoogleLoginButton from 'features/auth/googleAuth';
import { FaChevronRight } from 'react-icons/fa';
import { EmailIcon } from 'assets/icons/icons';
import { RewardBannerInfo } from './rewardBanner';
import { Banner } from './banner/banner';
import { Button } from 'shared/ui/button/null/styles';
import { TermsPrivacyModal, useTerms } from 'features/auth/ui';
import {
  TONAuthButton,
  WalletAuth,
  // TelegramAuthButton
} from './buttons';

import styles from './style.module.scss';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';

export const AuthButtons = () => {
  const { page, handleChoose, handleClose } = useTerms();

  const navigate = useNavigate();
  const partnerId = useSelector(getPartnerId);
  const path = partnerId ? `${ROUTES.LOGIN}?partnerID=${partnerId}` : ROUTES.LOGIN;
  const { state } = useLocation();
  const { isMobile } = useDisplay();
  const { discordLink } = useGetSettings();

  const isBannersObjInLocalStorage = Boolean(localStorage.getItem(LOCAL_STORAGE_KEYS.BANNERS));

  return (
    <>
      <TermsPrivacyModal onClose={handleClose} page={page} />
      <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ type: 'spring', bounce: 0.4 }} className={styles.wrapper}>
        {!isMobile && (isBannersObjInLocalStorage ? <Banner /> : <RewardBannerInfo />)}
        <div className={styles.buttons}>
          <TemplateButton
            title={'Login with Email'}
            icon={<EmailIcon />}
            variant={'email'}
            onClick={() => navigate(path, { state: { prev: state?.prev } })}
          />
          <GoogleLoginButton />
          <TONAuthButton />
          <WalletAuth />
          {/* <TelegramAuthButton /> */}
        </div>
        <div className={styles.wrapLinks}>
          <a className={styles.link} href={discordLink} target="_blank" rel="noreferrer">
            Help
          </a>
          <Button className={styles.link} onClick={() => handleChoose('policy')}>
            Privacy
          </Button>
          <Button className={styles.link} onClick={() => handleChoose('terms')}>
            Terms
          </Button>
        </div>
      </motion.div>
    </>
  );
};

type TemplateButtonProps = {
  variant?: string;
  onClick?: () => void | Promise<void>;
  isChevron?: boolean;
  isLoading?: boolean;
  icon?: React.ReactNode;
  title?: string;
};

export const TemplateButton = ({ variant = '', onClick, isChevron = true, isLoading = false, icon, title }: TemplateButtonProps) => {
  return (
    <div className={clsx(styles.button, styles[variant])} onClick={onClick}>
      <div className={styles.wrap}>
        {icon}
        <p>{title}</p>
      </div>
      {isChevron && !isLoading && <FaChevronRight color="rgba(var(--basic-dark--rgb),0.6)" />}
      {isLoading && <MightyLoaderMini style={{ width: 36, height: 36 }} />}
    </div>
  );
};
