import { useEffect, useState } from 'react';
import * as Styled from './styles';
import { setCurrentTheme, useLazyGetMyOnetimerNotificationsQuery, useReadOnetimerMutation, useReadOnetimersMutation } from 'store';
import { ONETIMERS_TYPES } from 'interface';
import CommentRewards from './comment-rewards';
import { OnetimeNotificationTypes } from './types';
import { FirstTimerModal } from '../modal';
import { PremiumCongratulation } from './premium';
import { ReferralsModal } from 'components/pages/referrals';
import { THEMES } from 'app/constants';
import { useGetCurrentTheme } from 'hooks';
import { useDispatch } from 'react-redux';

const displayComponents = {
  [ONETIMERS_TYPES.USERHANDCHECKEDANSWERREWARDED]: CommentRewards,
  [ONETIMERS_TYPES.USERCOMMENTREWARDED]: CommentRewards,
  [ONETIMERS_TYPES.FIRSTTIMERGREETED]: FirstTimerModal,
  [ONETIMERS_TYPES.PREMIUM]: PremiumCongratulation,
  [ONETIMERS_TYPES.REFERRALREWARD]: ReferralsModal,
};

const OnetimeNotifications = () => {
  const [onetimers, setOnetimers] = useState<OnetimeNotificationTypes[]>([]);
  const [getOnetimers] = useLazyGetMyOnetimerNotificationsQuery();
  const [readOnetimer] = useReadOnetimerMutation();
  const [readAllOnetimers] = useReadOnetimersMutation();
  const theme = useGetCurrentTheme();
  const dispatch = useDispatch();
  const active = onetimers.length - 1;

  useEffect(() => {
    getOnetimers().unwrap().then(setOnetimers);
    return () => {
      setOnetimers([]);
    };
  }, [getOnetimers]);

  const handleClose = (index: number) => () => {
    const newArr = [...onetimers];
    const backup = [...onetimers];
    const deleted = newArr[index];
    newArr.splice(index, 1);

    readOnetimer(deleted._id)
      .unwrap()
      .then(() => setOnetimers(newArr))
      .catch(() => setOnetimers(backup));
  };

  const handleSkip = () =>
    readAllOnetimers()
      .unwrap()
      .then(() => setOnetimers([]))
      .catch();

  useEffect(() => {
    if (onetimers.some((el) => el.type === ONETIMERS_TYPES.FIRSTTIMERGREETED) && theme === THEMES.LIGHT) {
      dispatch(setCurrentTheme(THEMES.DARK));
    }
  }, [onetimers]);

  if (onetimers.length < 1) {
    return null;
  }

  return (
    <Styled.Container>
      {onetimers.map((item, index) => {
        const Component = displayComponents[item.type] ?? null;
        const isActive = active === index && Boolean(Component);
        return isActive ? <Component key={item.type + index} {...item} onClose={handleClose(index)} onSkip={handleSkip} /> : null;
      })}
    </Styled.Container>
  );
};

export default OnetimeNotifications;
