import { toast } from 'react-toastify';
import { useBuyItemMutation, useRefetchHistoryQuery } from 'store';

import Answer from 'components/library/messages/Answer';
import Message from 'components/library/messages/Message/Message';

import useAudio from 'hooks/useAudio';
import { SOUNDS } from 'app/constants';

const useBuyItem = () => {
  const [buy, data] = useBuyItemMutation();
  const { refetch } = useRefetchHistoryQuery({ limit: 0 });
  const { play } = useAudio({ sound: SOUNDS.BUY_ITEM.sound });

  const buyItem = async (goodsId: number, id: number, amount: number) => {
    const purchaseToast = toast.loading(<Message itemId={id} amount={amount} status="pending" type="purchase" />);
    await buy({ id: goodsId, amount })
      .unwrap()
      .then(() => {
        toast.update(purchaseToast, {
          render: <Message itemId={id} amount={amount} status="complete" type="purchase" />,
          isLoading: false,
          autoClose: 10000,
          type: 'success',
          closeOnClick: true,
        });
        play(SOUNDS.BUY_ITEM.volume);
        refetch();
      })
      .catch((res) => {
        toast.update(purchaseToast, {
          render: <Answer type="incorrect" label={res?.data?.message ?? 'Something went wrong...'} />,
          isLoading: false,
          autoClose: 5000,
          type: 'error',
          closeOnClick: true,
        });
      });
  };

  return { buyItem, data };
};

export default useBuyItem;
