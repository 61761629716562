import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      display: flex;
      flex-direction: column;
    `
  ),
  Item = styled.div(
    () => css`
      border-top: var(--basic-border);
      padding: 12px 0 12px 5px;

      &:active,
      &:focus {
        background: rgba(255, 255, 255, 0.1);
      }

      &:last-child {
        border-bottom: var(--basic-border);
      }
    `
  );
