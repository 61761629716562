import styled, { css } from 'styled-components';
import { GRADES, THEMES } from 'app/constants';
import { EItemFungiblePremiumType } from 'interface';

export const Container = styled.div(
    ({ theme }) => css`
      height: 100%;
      position: relative;
      aspect-ratio: 1 / 1;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      border-radius: 100%;

      &.${GRADES.LEGENDARY} {
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgb(255, 191, 102) 0%,
          ${theme === THEMES.LIGHT ? 'rgb(225, 225, 225)' : 'rgb(50, 50, 50)'} calc(85%),
          transparent calc(90%)
        );
      }
      &.${GRADES.UNCOMMON} {
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgb(41, 171, 136) 0%,
          ${theme === THEMES.LIGHT ? 'rgb(225, 225, 225)' : 'rgb(50, 50, 50)'} calc(85%),
          transparent calc(90%)
        );
      }
      &.${GRADES.RARE} {
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgb(149, 216, 245) 0%,
          ${theme === THEMES.LIGHT ? 'rgb(225, 225, 225)' : 'rgb(50, 50, 50)'} calc(85%),
          transparent calc(90%)
        );
      }
      &.${GRADES.EPIC} {
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgb(202, 71, 206) 0%,
          ${theme === THEMES.LIGHT ? 'rgb(225, 225, 225)' : 'rgb(50, 50, 50)'} calc(85%),
          transparent calc(90%)
        );
      }
      &.${GRADES.MYTHICAL} {
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgb(241, 61, 90) 0%,
          ${theme === THEMES.LIGHT ? 'rgb(225, 225, 225)' : 'rgb(50, 50, 50)'} calc(85%),
          transparent calc(90%)
        );
      }
      &.clickable {
        cursor: pointer;
      }
      .countdown {
        position: absolute;
        top: 0;
        left: 0;
      }
    `
  ),
  ItemImage = styled.img`
    height: 100%;
    width: auto;
    padding: 5px;
    &.${EItemFungiblePremiumType.PremiumOneDay},
      &.${EItemFungiblePremiumType.PremiumOneWeek},
      &.${EItemFungiblePremiumType.PremiumOneMonth},
      &.${EItemFungiblePremiumType.PremiumOneYear} {
      padding: 15%;
    }
  `;
