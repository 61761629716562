import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 244px;
      max-width: 244px;
      height: 296px;
      backdrop-filter: blur(51px);
      border-radius: 8px;
      cursor: pointer;

      .menu {
        z-index: 2;
      }

      &.mobile {
        min-width: 180px;
        width: 180px;
        max-width: 180px;
        height: 224px;

        ${ImageContainer} {
          width: 180px;
          min-width: 180px;
          height: 90px;
          border-radius: 10px;

          img {
            width: 100%;
            max-width: 100%;
            max-height: auto;
            object-fit: cover;
          }
        }
        ${Body} {
          padding: 11px 15px 13px;
        }
        ${Name} {
          height: 54px;
          font-size: 16px;
        }
        ${Progress} {
          margin-bottom: 10px;
          height: 3px;
        }
        ${Time} {
          svg {
            height: 22px;
          }
        }
      }

      @media screen and (max-width: 476px) {
        width: 100%;
      }
    `
  ),
  ImageContainer = styled.div<{ imgLoaded?: boolean }>(
    ({ imgLoaded }) => css`
      width: 244px;
      min-width: 244px;
      height: 142px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      position: relative;
      border-radius: 8px;
      flex-shrink: 0;

      & > img {
        object-fit: contain;
        max-height: 100%;
        opacity: ${imgLoaded ? 1 : 0};
        transition: 0.3s;
      }

      & .card-image-loader {
        opacity: ${imgLoaded ? 0 : 1};
        pointer-events: none;
        transition: 0.3s;
      }
    `
  ),
  Skeleton = styled.div(
    () => css`
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: inherit;

      background: linear-gradient(-45deg, rgba(100, 135, 239, 0.1) 40%, rgba(100, 135, 239, 0.2) 50%, rgba(100, 135, 239, 0.1) 60%);
      background-size: 300%;
      background-position-x: 200%;

      animation: shimmer 2s infinite linear forwards;

      @keyframes shimmer {
        to {
          background-position-x: -100%;
        }
      }
    `
  ),
  Body = styled.div(
    () => css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 17px 20px 26px;
      width: 100%;
      max-width: 100%;
      height: 100%;
    `
  ),
  Progress = styled.div(
    () => css`
      width: 100%;
      height: 4.5px;
    `
  ),
  Name = styled.p(
    () => css`
      font-size: 20px;
      font-weight: 700;
      line-height: 114%;
      margin-bottom: 10px;
      width: 100%;
      height: 46px;
      overflow: hidden;
      color: var(--font-color-strong);
    `
  ),
  Bottom = styled.div(
    () => css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 30px;
      position: relative;

      & > img {
        margin-right: -12px;
        height: 100%;
        width: auto;
        scale: 1.25;
      }
      & > img:last-of-type {
        margin-right: 0;
      }
    `
  ),
  Rewards = styled.div(
    () => css`
      display: flex;
      align-items: center;
      height: 30px;

      & > img {
        margin-right: -20px;
        height: auto;
        width: 40px;
      }
      & > img:last-of-type {
        margin-right: 0;
      }
    `
  ),
  Time = styled.span(
    () => css`
      display: inline-flex;
      align-items: center;
      gap: 2px;
      font-size: 14px;
      line-height: 100%;
      color: var(--font-color-strong);

      svg {
        height: 24px;
      }
    `
  );
