import { useNavigate, useSearchParams } from 'react-router-dom';
import { TTermsPolicy } from './types';
import { ROUTES } from 'app/constants';

const useTerms = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') as TTermsPolicy | null;
  const navigate = useNavigate();

  const handleChoose = (page: TTermsPolicy) => {
    if (!page) {
      searchParams.delete('page');
      setSearchParams(searchParams);
    } else {
      setSearchParams({ page });
    }
  };

  const handleClose = () => {
    navigate(ROUTES.AUTH, { state: { toMain: true } });
  };

  return { page, handleChoose, handleClose };
};
export default useTerms;
