// src/hooks/usePartnerParams.ts
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { dispatch } from './useDispatch';
import { setPartnerData } from 'store';

const usePartnerParams = () => {
  const location = window.location.search;

  useEffect(() => {
    const searchParams = new URLSearchParams(location);
    const partnerID = searchParams.get('partnerID');
    const partnerToken = searchParams.get('partnerToken');
    const theme: any = searchParams.get('theme') || 'light';

    if (partnerID || partnerToken || theme) {
      dispatch(
        setPartnerData({
          partnerID: partnerID || null,
          partnerToken: partnerToken || null,
          theme: theme,
        })
      );
    }
  }, [dispatch, location.search]);
};

export default usePartnerParams;
